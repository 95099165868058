import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { ForgotpasswordComponent } from './forgotpassword/forgotpassword.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';

import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { AuthenticationComponent } from './authentication/authentication.component';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { Router } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { ResetpassauthComponent } from './resetpassauth/resetpassauth.component';

import { MatTabsModule } from '@angular/material/tabs';
import { CalculatorModule } from '././pages/calculator/calculator.module';

import { NgxFullCalendarModule } from 'ngx-fullcalendar';
import { ShowvideoComponent } from './showvideo/showvideo.component';
import { AliasnameComponent } from './showvideo/aliasname/aliasname.component';
import { ProtectpasswordComponent } from './showvideo/protectpassword/protectpassword.component';
import { OnetimepassComponent } from './showvideo/onetimepass/onetimepass.component';
import { TnakyouComponent } from './showvideo/tnakyou/tnakyou.component';
import { StudentimageComponent } from './showvideo/studentimage/studentimage.component';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { AutholdComponent } from './authold/authold.component';
import { ForgotpassoldComponent } from './forgotpassold/forgotpassold.component';
import { ProtectpasstempComponent } from './showvideo/protectpasstemp/protectpasstemp.component';
import { WelcomeComponent } from './showvideo/welcome/welcome.component';

import { ProjectorComponent } from './projector/projector.component';
import { StudentliveComponent } from './studentlive/studentlive.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CompetitionComponent } from './showvideo/competition/competition.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { EnrolledcourseComponent } from './enrolledcourse/enrolledcourse.component';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { ConnectionServiceModule } from 'ngx-connection-service';
import { NoconnectionModule } from './noconnection/noconnection.module';
import { NgxEmojiPickerModule } from 'ngx-emoji-picker';
import { MembershipComponent } from './membership/membership.component';
import { NgxStripeModule } from 'ngx-stripe';
import { OnetimepaymentComponent } from './onetimepayment/onetimepayment.component';
import { SelfpacedvideoComponent } from './showvideo/selfpacedvideo/selfpacedvideo.component';
import { LearningvideoComponent } from './showvideo/learningvideo/learningvideo.component';
import { CustomscheduleComponent } from './showvideo/customschedule/customschedule.component';
import { TimezonesetComponent } from './showvideo/timezoneset/timezoneset.component';
import { MomentTimezonePickerModule } from 'moment-timezone-picker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PassexamComponent } from './pages/survey/passexam/passexam.component';
import { FeelaboutclassComponent } from './pages/survey/feelaboutclass/feelaboutclass.component';
import { PrepareexamComponent } from './pages/survey/prepareexam/prepareexam.component';
import { EffectivepartComponent } from './pages/survey/effectivepart/effectivepart.component';
import { ImproveclassComponent } from './pages/survey/improveclass/improveclass.component';
import { AdditionalfeedbackComponent } from './pages/survey/additionalfeedback/additionalfeedback.component';
import { FeedbackjohnComponent } from './pages/survey/feedbackjohn/feedbackjohn.component';
import { TrainingclassComponent } from './pages/survey/trainingclass/trainingclass.component';
import { FriendcolleagueComponent } from './pages/survey/friendcolleague/friendcolleague.component';
import { SurveythankyouComponent } from './pages/survey/surveythankyou/surveythankyou.component';
import { DomainresultComponent } from './pages/survey/domainresult/domainresult.component';
import { FeedbackrobComponent } from './pages/survey/feedbackrob/feedbackrob.component';
import { CookieService } from 'ngx-cookie-service';
import { EventrecordingsComponent } from './pages/eventrecordings/eventrecordings.component';
import { CertificateModule } from './certificate/certificate.module';
import { AuthypasswordComponent } from './pages/authypassword/authypassword.component';
import { VerifyauthyComponent } from './pages/verifyauthy/verifyauthy.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { RecoveryemailsuccessComponent } from './recoveryemailsuccess/recoveryemailsuccess.component';
import { ForcechangepasswordcComponent } from './pages/forcechangepasswordc/forcechangepasswordc.component';
import { PaySuccessComponent } from './showvideo/pay-success/pay-success.component';
import { StudentreportComponent } from './pages/studentreport/studentreport.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { createErrorHandler, TraceModule, TraceService } from '@sentry/angular';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    ForgotpasswordComponent,
    SidenavComponent,
    AuthenticationComponent,
    ResetpassauthComponent,
    ShowvideoComponent,
    AliasnameComponent,
    ProtectpasswordComponent,
    OnetimepassComponent,
    TnakyouComponent,
    StudentimageComponent,
    AutholdComponent,
    ForgotpassoldComponent,
    ProtectpasstempComponent,
    WelcomeComponent,
    ProjectorComponent,
    StudentliveComponent,
    CompetitionComponent,
    EnrolledcourseComponent,
    MembershipComponent,
    OnetimepaymentComponent,
    SelfpacedvideoComponent,
    LearningvideoComponent,
    CustomscheduleComponent,
    TimezonesetComponent,
    PassexamComponent,
    FeelaboutclassComponent,
    PrepareexamComponent,
    EffectivepartComponent,
    ImproveclassComponent,
    AdditionalfeedbackComponent,
    FeedbackjohnComponent,
    TrainingclassComponent,
    FriendcolleagueComponent,
    SurveythankyouComponent,
    DomainresultComponent,
    FeedbackrobComponent,
    EventrecordingsComponent,
    AuthypasswordComponent,
    VerifyauthyComponent,
    RecoveryemailsuccessComponent,
    ForcechangepasswordcComponent,
    PaySuccessComponent,
    StudentreportComponent,
  ],
  imports: [
    TraceModule,
    MatPaginatorModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    InfiniteScrollModule,
    MatToolbarModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatButtonModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    HttpModule,
    HttpClientModule,
    MatTabsModule,
    CalculatorModule,
    NgxQRCodeModule,
    NgxFullCalendarModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    ConnectionServiceModule,
    NoconnectionModule,
    NgxEmojiPickerModule,
    MomentTimezonePickerModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      closeButton: true,
    }),
    NgxStripeModule.forRoot(
      'pk_test_51JoLjKAI7lJSHRGDq36QjhAYjS2o12XRADiYyMTiJWruBTXkbixPSsKEGnZlDcMLqOablSNZCa28Ij3X4L1M20M300qQQRj0tQ',
    ),
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
    CertificateModule,
    NgOtpInputModule,
  ],
  providers: [
    CookieService,
    HttpClient,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
