import { Component, HostListener, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthserviceService } from '../../authservice.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../services.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import * as Chime from 'aws-sdk/clients/chime';
import * as momentZone from 'moment-timezone';
import { CourseserviceService } from 'src/app/services/course/courseservice.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedDataService } from 'src/app/services/SharedData/shared-data.service';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  MobileSearch: boolean = false;
  StartDashboardPopup: boolean = false;
  StartStudyGuidePopup: boolean = false;
  StartKnowAssPopup: boolean = false;
  StartCourseSchedulePopup: boolean = false;
  StartPTestPopup: boolean = false;
  StartChatPopup: boolean = false;
  StartSupportPopup: boolean = false;
  StartCatExamPopup: boolean = false;
  StartStudyResourcePopup: boolean = false;
  StartCoursesPopup: boolean = false;
  StartSearchPopup: boolean = false;
  StartNotificationPopup: boolean = false;
  StartMenuPopup: boolean = false;
  checked: any;
  limitRecord: number = 0;
  notificationPopup: boolean = true;
  ClientHeight: any;
  isShowing: boolean = false;
  nextLiveCall: any;
  before15Date: Date;
  toggleFunction(e: MouseEvent) {
    this.notificationPopup = !this.notificationPopup;
    e.stopPropagation();
  }
  @HostListener('document:click') hideOnClick() {
    this.notificationPopup = true;
  }
  Dashboard: boolean = false;
  Account: boolean = false;
  course: boolean = false;
  hidesidenav: boolean = false;
  public data: any;
  name: any;
  imgurl: any;
  Search: any;
  dashHover: boolean = false;
  flashHover: boolean = false;
  knowledgeHover: boolean = false;
  scheduleHover: boolean = false;
  catexamHover: boolean = false;
  testHover: boolean = false;
  chatHover: boolean = false;
  liveSupportHover: boolean = false;
  mindmapHover: boolean = false;
  resources: boolean = false;
  coursesHover: boolean = false;
  guideHover: boolean = false;
  videoHover: boolean = false;
  gamificationHover: boolean = false;
  noNoti: boolean = true;
  timezone: any = JSON.parse(<string>localStorage.getItem('userdetails'))
    ? JSON.parse(<string>localStorage.getItem('userdetails')).user_time_zone
      ? JSON.parse(
          JSON.parse(<string>localStorage.getItem('userdetails'))
            .user_time_zone,
        ).nameValue
      : this.auth.formatZone(momentZone.tz.guess(true)).nameValue
    : this.auth.formatZone(momentZone.tz.guess(true)).nameValue;
  tempvar: boolean = false;
  Notifications = [];
  DashTop: any;
  StudyTop: any;
  KATop: any;
  CSTop: any;
  PTTop: any;
  CATTop: any;
  ChatTop: any;
  CoursesTop: any;
  SupportTop: any;
  resetResult: boolean = false;

  chime = new Chime({ region: 'us-east-1' });
  constructor(
    private route: ActivatedRoute,
    public service: ServicesService,
    public spinner: NgxSpinnerService,
    public router: Router,
    public auth: AuthserviceService,
    public notificationService: NotificationService,
    public CourseserviceService: CourseserviceService,
    public sanitizer: DomSanitizer,
    public sharedDataService: SharedDataService,
  ) {}

  async ngOnInit() {
    sessionStorage.setItem('clearInterval', 'true');
    var data = localStorage.getItem('userdetails');
    if (data != undefined || data != null || data != '') {
      this.data = JSON.parse(<string>data);
      this.name = this.data.aliasname ? this.data.aliasname : this.data.name;
      this.imgurl = this.data.avatar_url;
    }
    setTimeout(() => {
      if (
        this.service.instructionHint == true &&
        this.data.show_resources == 0
      ) {
        this.StartDashboardPopup = true;
        this.service.access.CATaccess = true;
        this.service.access.Knowaccess = true;
        this.service.access.Practiceaccess = true;
        this.service.access.livechat_access = true;
      }
      this.DashTop = document.getElementById('dashboardTop').offsetTop + 84;
      this.StudyTop = document.getElementById('studyTop').offsetTop + 84;
      this.CSTop = document.getElementById('csTop').offsetTop + 84;
      this.CoursesTop = document.getElementById('CoursesTop').offsetTop - 57;
      this.ChatTop = document.getElementById('chatTop').offsetTop - 19;
      this.SupportTop = document.getElementById('SupportTop').offsetTop - 16;
    }, 100);

    await this.getNextLiveCall();
    await this.getPopupNotification();
  }
  sidebarMenuClick() {
    this.getNextLiveCall();
  }
  toggleMenu() {
    this.isShowing = !this.isShowing;
  }
  dashhover(event) {
    this.dashHover = true;
    this.ClientHeight = event.clientY;
    let a = window.innerHeight - event.clientY;
    var ClientHeight1 = this.ClientHeight - 150;
    if (a < 5000) {
      if (ClientHeight1 < 0) {
        this.ClientHeight = 0;
      }
      if (ClientHeight1 > 0) {
        this.ClientHeight = this.ClientHeight - 150;
      }
    }
  }
  studyhover(event) {
    this.flashHover = true;
    this.ClientHeight = event.clientY;
    let a = window.innerHeight - event.clientY;
    var ClientHeight1 = this.ClientHeight - 150;
    if (a < 5000) {
      if (ClientHeight1 < 0) {
        this.ClientHeight = 0;
      }
      if (ClientHeight1 > 0) {
        this.ClientHeight = this.ClientHeight - 150;
      }
    }
  }
  KAhover(event) {
    this.knowledgeHover = true;
    this.ClientHeight = event.clientY;
    let a = window.innerHeight - event.clientY;
    var ClientHeight1 = this.ClientHeight - 150;
    if (a < 5000) {
      if (ClientHeight1 < 0) {
        this.ClientHeight = 0;
      }
      if (ClientHeight1 > 0) {
        this.ClientHeight = this.ClientHeight - 150;
      }
    }
  }
  coursehover(event) {
    this.scheduleHover = true;
    this.ClientHeight = event.clientY;
    let a = window.innerHeight - event.clientY;
    var ClientHeight1 = this.ClientHeight - 150;
    if (a < 5000) {
      if (ClientHeight1 < 0) {
        this.ClientHeight = 0;
      }
      if (ClientHeight1 > 0) {
        this.ClientHeight = this.ClientHeight - 150;
      }
    }
  }
  PThover(event) {
    this.catexamHover = true;
    this.ClientHeight = event.clientY;
    let a = window.innerHeight - event.clientY;
    var ClientHeight1 = this.ClientHeight - 150;
    if (a < 5000) {
      if (ClientHeight1 < 0) {
        this.ClientHeight = 0;
      }
      if (ClientHeight1 > 0) {
        this.ClientHeight = this.ClientHeight - 150;
      }
    }
  }
  Cathover(event) {
    this.testHover = true;
    this.ClientHeight = event.clientY;
    let a = window.innerHeight - event.clientY;
    var ClientHeight1 = this.ClientHeight - 150;
    if (a < 2000) {
      if (ClientHeight1 < 0) {
        this.ClientHeight = 0;
      }
      if (ClientHeight1 > 0) {
        this.ClientHeight = this.ClientHeight - 150;
      }
    }
  }
  chathover(event) {
    this.chatHover = true;
    this.ClientHeight = event.clientY;
    let a = window.innerHeight - event.clientY;
    var ClientHeight1 = this.ClientHeight - 150;
    if (a < 5000) {
      if (ClientHeight1 < 0) {
        this.ClientHeight = 0;
      }
      if (ClientHeight1 > 0) {
        this.ClientHeight = this.ClientHeight - 200;
      }
    }
  }
  supportHover(event) {
    this.liveSupportHover = true;
    this.ClientHeight = event.clientY;
    let a = window.innerHeight - event.clientY;
    var ClientHeight1 = this.ClientHeight - 150;
    if (a < 5000) {
      if (ClientHeight1 < 0) {
        this.ClientHeight = 0;
      }
      if (ClientHeight1 > 0) {
        this.ClientHeight = this.ClientHeight - 200;
      }
    }
  }
  studyresourcehover(event) {
    this.resources = true;
    this.ClientHeight = event.clientY;
    let a = window.innerHeight - event.clientY;
    var ClientHeight1 = this.ClientHeight - 150;
    if (a < 5000) {
      if (ClientHeight1 < 0) {
        this.ClientHeight = 0;
      }
      if (ClientHeight1 > 0) {
        this.ClientHeight = this.ClientHeight - 200;
      }
    }
  }

  async getPopupNotification() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        let user_id = localStorage.getItem('CisspUserId');
        let cohort_id = localStorage.getItem('cohort_id');
        let fire_time = this.auth.convertStringUtcTimeZone(
          this.auth.ConvertFormatDbDateTime(new Date(), this.timezone),
          this.timezone,
        );
        this.spinner.show();
        await this.notificationService
          .getPopupNotification(user_id, cohort_id, fire_time, token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              this.Notifications = list.data;
              this.Notifications.forEach((element) => {
                element['Ago'] = momentZone(element.fire_time)
                  .fromNow()
                  .toUpperCase();
                if (element.test_type != 'MESSAGE') {
                  if (element.noti_type != 'MESSAGE') {
                    element.message =
                      element.message +
                      this.auth.ConvertDateDDFormat(
                        element.meeting_date,
                        this.timezone,
                        'DD-MM-YYYY',
                      ) +
                      ' at ' +
                      this.auth.ConvertDateDDFormat(
                        element.meeting_date,
                        this.timezone,
                        'hh:mm A',
                      );
                    if (element.test_type == 'meeting') {
                      const currentDate = new Date().getTime();
                      let meetingEndDate;
                      if (typeof element.meeting_time_zone !== 'undefined') {
                        meetingEndDate =
                          element.meeting_date.toString().substr(0, 11) +
                          element.end_time +
                          ':00' +
                          JSON.parse(element.meeting_time_zone).timeValue;
                      }
                      const givenEndDate = new Date(meetingEndDate).getTime();
                      if (
                        element.message.indexOf('https') > 0 &&
                        currentDate > givenEndDate
                      ) {
                        if (
                          element.message.includes('will start from <a href=')
                        ) {
                          element['message'] = element.message.replace(
                            'will start from <a href=',
                            'will start from <a onclick="return false;" style="cursor:not-allowed; opacity: 0.5;" href=',
                          );
                        }
                      }
                    }
                  }
                }
                if (element.test_type == 'meeting') {
                  element['message'] = this.sanitizer.bypassSecurityTrustHtml(
                    element.message,
                  );
                }
              });
              if (this.Notifications.length > 0) {
                this.noNoti = true;
              } else {
                this.noNoti = false;
              }

              this.spinner.hide();
            } else {
              this.spinner.hide();
            }
          });
      }
    });
  }

  async markAllRead() {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        let user_id = localStorage.getItem('CisspUserId');
        let cohort_id = localStorage.getItem('cohort_id');
        let fire_time = this.auth.ConvertDateDDFormat(
          new Date(),
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          'YYYY-MM-DD HH:mm:SS',
        );
        this.spinner.show();
        await this.notificationService
          .markReadPopupNotification(user_id, cohort_id, fire_time, token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              await this.getPopupNotification();
              this.router.navigate(['/sidenav/notification']);
              this.spinner.hide();
            } else {
              this.spinner.hide();
            }
          });
      }
    });
  }
  async logout() {
    var data = JSON.parse(window.localStorage.getItem('userdetails'));
    await this.service.setOfflineUser(data.email).then(async (res) => {
      await this.auth.logOut();
      await this.spinner.hide();
      this.sharedDataService.clearSharedData();
      window.localStorage.clear();
      window.sessionStorage.clear();
      await this.router.navigate(['/login']);
    });
  }

  gotoSearch() {
    if (this.router.url != '/sidenav/search') {
      this.router.navigate(['/sidenav/search']);
    }
  }
  async SkipClick() {
    this.spinner.show();
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        let user_id = localStorage.getItem('CisspUserId');
        if (this.checked == true) {
          await this.CourseserviceService.updateResourceShow(
            user_id,
            token,
          ).then(async (data) => {
            let userdetails = JSON.parse(
              window.localStorage.getItem('userdetails'),
            );
            if (userdetails.show_resources == 0) {
              userdetails.show_resources = 1;
            }
            localStorage.setItem('userdetails', JSON.stringify(userdetails));
          });
        } else {
          let userdetails = JSON.parse(
            window.localStorage.getItem('userdetails'),
          );
          if (userdetails.show_resources == 0) {
            userdetails.show_resources = 1;
          }
          localStorage.setItem('userdetails', JSON.stringify(userdetails));
        }
      }
    });
    this.spinner.hide();

    var accessItem = JSON.parse(window.localStorage.getItem('accessitem'));
    this.service.access.CATaccess = accessItem.CAT_acces == 1 ? true : false;
    this.service.access.Knowaccess = accessItem.KA_access == 1 ? true : false;
    this.service.access.Practiceaccess =
      accessItem.PT_access == 1 ? true : false;
    this.service.access.livechat_access =
      accessItem.livechat_access == 1 ? true : false;

    this.StartDashboardPopup = false;
    this.StartStudyGuidePopup = false;
    this.StartKnowAssPopup = false;
    this.StartCourseSchedulePopup = false;
    this.StartPTestPopup = false;
    this.StartChatPopup = false;
    this.StartSupportPopup = false;
    this.StartCatExamPopup = false;
    this.StartStudyResourcePopup = false;
    this.StartCoursesPopup = false;
    this.StartSearchPopup = false;
    this.StartNotificationPopup = false;
    this.StartMenuPopup = false;
    var scrollcontainer = document.getElementById(
      'Chatinfinite',
    ) as HTMLElement;
    scrollcontainer.scrollTop = 200;
  }
  NextClick(item) {
    this.DashTop = document.getElementById('dashboardTop').offsetTop + 84;
    this.StudyTop = document.getElementById('studyTop').offsetTop + 84;
    this.CSTop = document.getElementById('csTop').offsetTop + 84;
    this.CoursesTop = document.getElementById('CoursesTop').offsetTop - 57;
    this.ChatTop = document.getElementById('chatTop').offsetTop - 19;
    this.SupportTop = document.getElementById('SupportTop').offsetTop - 16;

    if (item == 'Dashboard') {
      this.StartDashboardPopup = false;
      this.StartCourseSchedulePopup = true;
    }
    if (item == 'CourseSchedule') {
      this.StartDashboardPopup = false;
      this.StartCourseSchedulePopup = false;
      this.StartStudyGuidePopup = true;
    }
    if (item == 'StudyGuide') {
      this.StartDashboardPopup = false;
      this.StartCourseSchedulePopup = false;
      this.StartStudyGuidePopup = false;
      this.StartSupportPopup = true;
    }
    if (item == 'Support') {
      this.StartDashboardPopup = false;
      this.StartCourseSchedulePopup = false;
      this.StartStudyGuidePopup = false;
      this.StartStudyResourcePopup = false;
      this.StartSupportPopup = false;
      this.StartChatPopup = true;
    }
    if (item == 'Chat') {
      this.StartDashboardPopup = false;
      this.StartCourseSchedulePopup = false;
      this.StartStudyGuidePopup = false;
      this.StartStudyResourcePopup = false;
      this.StartSupportPopup = false;
      this.StartChatPopup = false;
      this.StartCoursesPopup = true;
    }

    if (item == 'Courses') {
      this.StartDashboardPopup = false;
      this.StartCourseSchedulePopup = false;
      this.StartStudyGuidePopup = false;
      this.StartStudyResourcePopup = false;
      this.StartSupportPopup = false;
      this.StartChatPopup = false;
      this.StartCoursesPopup = false;
      this.StartSearchPopup = true;
    }

    if (item == 'Search') {
      this.StartDashboardPopup = false;
      this.StartCourseSchedulePopup = false;
      this.StartStudyGuidePopup = false;
      this.StartStudyResourcePopup = false;
      this.StartSupportPopup = false;
      this.StartChatPopup = false;
      this.StartCoursesPopup = false;
      this.StartSearchPopup = false;
      this.StartNotificationPopup = true;
    }
    if (item == 'Notification') {
      this.StartDashboardPopup = false;
      this.StartCourseSchedulePopup = false;
      this.StartStudyGuidePopup = false;
      this.StartStudyResourcePopup = false;
      this.StartSupportPopup = false;
      this.StartChatPopup = false;
      this.StartCoursesPopup = false;
      this.StartSearchPopup = false;
      this.StartNotificationPopup = false;
      this.StartMenuPopup = true;
    }
    if (item == 'Menu') {
      this.StartDashboardPopup = false;
      this.StartCourseSchedulePopup = false;
      this.StartStudyGuidePopup = false;
      this.StartStudyResourcePopup = false;
      this.StartSupportPopup = false;
      this.StartChatPopup = false;
      this.StartCoursesPopup = false;
      this.StartSearchPopup = false;
      this.StartNotificationPopup = false;
      this.StartMenuPopup = false;
    }
  }
  BackClick(item) {
    this.DashTop = document.getElementById('dashboardTop').offsetTop + 84;
    this.StudyTop = document.getElementById('studyTop').offsetTop + 84;
    this.CSTop = document.getElementById('csTop').offsetTop + 84;
    this.CoursesTop = document.getElementById('CoursesTop').offsetTop - 57;
    this.ChatTop = document.getElementById('chatTop').offsetTop - 19;
    this.SupportTop = document.getElementById('SupportTop').offsetTop - 16;
    if (item == 'StudyGuide') {
      this.StartDashboardPopup = false;
      this.StartCourseSchedulePopup = true;
      this.StartStudyGuidePopup = false;
    }
    if (item == 'CourseSchedule') {
      this.StartCourseSchedulePopup = false;
      this.StartDashboardPopup = true;
    }
    if (item == 'Chat') {
      this.StartDashboardPopup = false;
      this.StartCourseSchedulePopup = false;
      this.StartStudyGuidePopup = false;
      this.StartStudyResourcePopup = false;
      this.StartSupportPopup = true;
      this.StartChatPopup = false;
    }
    if (item == 'Courses') {
      this.StartDashboardPopup = false;
      this.StartCourseSchedulePopup = false;
      this.StartStudyGuidePopup = false;
      this.StartStudyResourcePopup = false;
      this.StartSupportPopup = false;
      this.StartChatPopup = true;
      this.StartCoursesPopup = false;
    }
    if (item == 'Support') {
      this.StartDashboardPopup = false;
      this.StartCourseSchedulePopup = false;
      this.StartStudyGuidePopup = true;
      this.StartSupportPopup = false;
    }
    if (item == 'Search') {
      this.StartDashboardPopup = false;
      this.StartCourseSchedulePopup = false;
      this.StartStudyGuidePopup = false;
      this.StartStudyResourcePopup = false;
      this.StartSupportPopup = false;
      this.StartChatPopup = false;
      this.StartCoursesPopup = true;
      this.StartSearchPopup = false;
    }
    if (item == 'Notification') {
      this.StartDashboardPopup = false;
      this.StartCourseSchedulePopup = false;
      this.StartStudyGuidePopup = false;
      this.StartStudyResourcePopup = false;
      this.StartSupportPopup = false;
      this.StartChatPopup = false;
      this.StartCoursesPopup = false;
      this.StartSearchPopup = true;
      this.StartNotificationPopup = false;
    }
  }

  showReset() {
    this.resetResult = true;
  }
  closeResult() {
    this.resetResult = false;
  }

  OpenVideoMindmapPopup(vid) {
    if (vid == 'video') {
      this.router.navigate([`/videolesson`]);
    }
    if (vid == 'mindmap') {
      this.router.navigate([`/mindmaps`]);
    }
    if (vid == 'KA') {
      this.closeResult();
      this.router.navigate([`sidenav/knowledge`]);
    }
    if (vid == 'PT') {
      this.closeResult();
      this.router.navigate([`sidenav/practicetest`]);
    }
  }
  async getNextLiveCall() {
    this.spinner.show();
    this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        await this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        this.spinner.show();
        var cohort_id = localStorage.getItem('cohort_id');
        var user_id = localStorage.getItem('CisspUserId');
        await this.service
          .getNextLiveCall(cohort_id, user_id, token)
          .then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              let schedule_data = list.data;
              for (let i = 0; i < schedule_data.length; i++) {
                schedule_data[i]['end_date'] =
                  schedule_data[i].due_date.toString().substr(0, 11) +
                  schedule_data[i].end_time +
                  schedule_data[i].due_date
                    .toString()
                    .substr(16, schedule_data[i].due_date.length);
                let timeZone = schedule_data[i].due_date.substr(
                  schedule_data[i].due_date.length - 6,
                  6,
                );
                let due_date: Date;
                let end_date: Date;
                let overdue_date;
                let currTimeZone = this.auth.currentTimeZone();
                due_date = new Date(
                  new Date(schedule_data[i].due_date).toLocaleString('en-US', {
                    timeZone: this.timezone,
                  }),
                );
                end_date = new Date(
                  new Date(schedule_data[i].end_date).toLocaleString('en-US', {
                    timeZone: this.timezone,
                  }),
                );
                let due =
                  schedule_data[i].due_date.split('T')[0] +
                  'T' +
                  schedule_data[i].end_time +
                  ':' +
                  schedule_data[i].due_date.split(':')[2] +
                  schedule_data[i].due_date.split(':')[3];
                overdue_date = new Date(
                  new Date(due).toLocaleString('en-US', {
                    timeZone: this.timezone,
                  }),
                );

                let curr_date = new Date(
                  new Date().toLocaleString('en-US', {
                    timeZone: this.timezone,
                  }),
                );
                if (overdue_date.getTime() >= curr_date.getTime()) {
                  let before15Date = new Date(
                    new Date(due_date).setMinutes(
                      new Date(due_date).getMinutes() - 15,
                    ),
                  );
                  this.before15Date = before15Date;
                  let timeUntilTarget =
                    before15Date.getTime() - curr_date.getTime() + 10000;
                  let me = this;

                  if (timeUntilTarget > 0) {
                    setTimeout(async () => {
                      await me.auth
                        .getAuthenticatedUser()
                        .getSession(async (err, session) => {
                          if (err) {
                            me.spinner.hide();
                            me.router.navigate(['/login']);
                          } else {
                            var token = session.getIdToken().getJwtToken();
                            let user_id = localStorage.getItem('CisspUserId');
                            let cohort_id = localStorage.getItem('cohort_id');
                            me.spinner.show();
                            await me.notificationService
                              .getPopupNotification(
                                user_id,
                                cohort_id,
                                before15Date.toString(),
                                token,
                              )
                              .then(async (data) => {
                                if (data[0].status == 200) {
                                  var list = JSON.parse(<string>data[0].json);
                                  me.Notifications = list.data;
                                  me.Notifications.forEach((element) => {
                                    element['Ago'] = momentZone(
                                      element.fire_time,
                                    )
                                      .fromNow()
                                      .toUpperCase();
                                    if (element.test_type != 'MESSAGE') {
                                      if (element.noti_type != 'MESSAGE') {
                                        element.message =
                                          element.message +
                                          me.auth.ConvertDateDDFormat(
                                            element.meeting_date,
                                            me.timezone,
                                            'DD-MM-YYYY',
                                          ) +
                                          ' at ' +
                                          me.auth.ConvertDateDDFormat(
                                            element.meeting_date,
                                            me.timezone,
                                            'hh:mm A',
                                          );
                                      }
                                    }
                                    if (element.test_type == 'meeting') {
                                      element['message'] =
                                        me.sanitizer.bypassSecurityTrustHtml(
                                          element.message,
                                        );
                                    }
                                  });
                                  me.Notifications = me.Notifications.filter(
                                    (x) => x.test_type == 'meeting',
                                  );
                                  if (me.Notifications.length > 0) {
                                    me.noNoti = true;
                                  } else {
                                    me.noNoti = false;
                                  }
                                  me.spinner.hide();
                                } else {
                                  me.spinner.hide();
                                }
                              });
                          }
                        });
                    }, timeUntilTarget);
                  }
                  break;
                }
              }
            }
          });
        this.spinner.hide();
      }
    });
  }
}
