import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../services.service';
import { AuthserviceService } from '../authservice.service';
import { LoginflowService } from '../data/loginflow.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CourseserviceService } from '../services/course/courseservice.service';
import { CookieService } from 'ngx-cookie-service';
import { CryptoService } from '../services/crypto/crypto.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isMaintenanceModeOn = true;
  list1: any;
  email: any;
  password: any;
  public LoginForm: FormGroup;
  courselist = [];
  constructor(
    public LoginflowService: LoginflowService,
    public service: ServicesService,
    public auth: AuthserviceService,
    public spinner: NgxSpinnerService,
    public fb: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    public CourseserviceService: CourseserviceService,
    private cookieService: CookieService,
    private crypto: CryptoService,
  ) {
    this.service.headershow = false;
    this.service.SearchValue = true;
  }

  ngOnInit() {
    // if (
    //   window.localStorage.getItem('preventBack') != null &&
    //   window.localStorage.getItem('preventBack') != undefined
    // ) {
    //   let email = window.localStorage.getItem('preventBack');
    //   this.router.navigate([`${email}`]);
    // }
    this.LoginForm = this.fb.group({
      email: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(
            '^[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*((\\.[A-Za-z]{2,6})$)',
          ),
        ]),
      ],
      password: ['', Validators.required],
    });
    // if (window.localStorage.getItem('CisspUserId') != null) {
    //   var uri = this.route.snapshot.queryParams['returnUrl'] || null;
    //   if (uri) {
    //     this.router.navigateByUrl(uri);
    //   } else {
    //     this.router.navigate(['sidenav/dashboard']);
    //   }
    // }
  }
  // async login() {
  //   if (this.LoginForm.valid) {
  //     try {
  //       await this.spinner.show();
  //       await this.LoginflowService.versions_getUserByEmail(this.email).then(
  //         async (data) => {
  //           let userEmail = '';
  //           if (data[0].status == 200) {
  //             var usersData = JSON.parse(<string>data[0].json);
  //             if (usersData.data.length > 0) {
  //               if (usersData.data[0].roll_id != 3) {
  //                 this.service.ShowError('Incorrect username or password.');
  //                 this.spinner.hide();
  //                 return;
  //               }
  //             }
  //
  //             if (usersData.data.length > 0) {
  //               userEmail = usersData.data[0].email;
  //               let expire = false;
  //               if (usersData.data[0].expire_password == 1) {
  //                 await this.auth
  //                   .GetUserRequest(this.email)
  //                   .then(async (data) => {
  //                     if (data[0].StatusCode == 0) {
  //                       expire = true;
  //                     } else {
  //                       if (
  //                         data[0].Data.UserStatus &&
  //                         data[0].Data.UserStatus == 'CONFIRMED'
  //                       ) {
  //                         var updateOnboardingUser = {
  //                           email: this.email,
  //                           expire_password: '0',
  //                         };
  //                         await this.LoginflowService.updateUserByEmail(
  //                           updateOnboardingUser,
  //                         ).then(async (userRes) => {
  //                           if (userRes[0].status == 200) {
  //                             var listuserRes = JSON.parse(
  //                               <string>userRes[0].json,
  //                             );
  //                           }
  //                         });
  //                       } else {
  //                         expire = true;
  //                       }
  //                     }
  //                   });
  //               }
  //               if (expire) {
  //                 var userdata = {
  //                   email: usersData.data[0].email,
  //                 };
  //                 var datas = this.crypto.enc(JSON.stringify(userdata));
  //                 this.router.navigate([`/pages/forcechangepassword/${datas}`]);
  //                 return;
  //               }
  //             } else userEmail = this.email;
  //             await this.auth.signIn(userEmail, this.password).then(
  //               async (data) => {
  //                 if (data[0].StatusCode == 1) {
  //                   await this.auth
  //                     .getAuthenticatedUser()
  //                     .getSession(async (err, session) => {
  //                       if (err) {
  //                         this.spinner.hide();
  //                         this.router.navigate(['/login']);
  //                       } else {
  //                         var token = session.getIdToken().getJwtToken();
  //                         if (data[0].StatusCode == 1) {
  //                           await this.LoginflowService.UsersFilter(
  //                             this.email,
  //                             token,
  //                           ).then(async (user) => {
  //                             if (user[0].status == 200) {
  //                               var list = JSON.parse(<string>user[0].json);
  //                               this.list1 = list;
  //                               if (list.data.allow_access == 1) {
  //                                 this.LoginflowService.loginlog(
  //                                   list.data.id,
  //                                   '',
  //                                   '',
  //                                   '',
  //                                 );
  //                                 localStorage.setItem(
  //                                   'CisspUserId',
  //                                   JSON.stringify(list.data.id),
  //                                 );
  //                                 localStorage.setItem(
  //                                   'userdetails',
  //                                   JSON.stringify(list.data),
  //                                 );
  //                                 localStorage.setItem(
  //                                   'cohort_id',
  //                                   list.data.onboard_cohort
  //                                     ? list.data.onboard_cohort
  //                                     : list.data.course_id,
  //                                 );
  //                                 var userdata = {
  //                                   email: this.email,
  //                                   password: this.password,
  //                                   cohort_id: list.data.onboard_cohort
  //                                     ? list.data.onboard_cohort
  //                                     : list.data.course_id,
  //                                 };
  //                                 var passwordsession = btoa(
  //                                   JSON.stringify(this.password),
  //                                 );
  //                                 window.localStorage.setItem(
  //                                   'domaindata',
  //                                   passwordsession,
  //                                 );
  //
  //                                 var email = btoa(JSON.stringify(userdata));
  //                                 email = encodeURIComponent(email);
  //                                 var uri =
  //                                   this.route.snapshot.queryParams[
  //                                     'returnUrl'
  //                                   ] || null;
  //
  //                                 if (uri) {
  //                                   localStorage.setItem(
  //                                     'CisspUserId',
  //                                     JSON.stringify(list.data.id),
  //                                   );
  //                                   localStorage.setItem(
  //                                     'userdetails',
  //                                     JSON.stringify(list.data),
  //                                   );
  //                                   window.localStorage.setItem(
  //                                     'logintrueStudent',
  //                                     'true',
  //                                   );
  //                                   this.cookieService.set(
  //                                     'cd399e84-2103-11ec-9621-0242ac130002',
  //                                     'a9fff10f-d50b-4e35-af0d-3ec922d1b53e',
  //                                   );
  //                                   await this.listCourses(list.data.id, uri);
  //                                 } else {
  //                                   if (list.data.welcome_flow == 2) {
  //                                     this.router.navigate([`/alias/${email}`]);
  //                                   } else if (list.data.welcome_flow == 3) {
  //                                     this.router.navigate([
  //                                       `/timezone/${email}`,
  //                                     ]);
  //                                   } else if (list.data.welcome_flow == 4) {
  //                                     this.router.navigate([
  //                                       `/protectpass/${email}`,
  //                                     ]);
  //                                   } else if (list.data.welcome_flow == 5) {
  //                                     this.router.navigate([
  //                                       `/onetimepsas/${email}`,
  //                                     ]);
  //                                   } else if (list.data.welcome_flow == 6) {
  //                                     this.router.navigate([
  //                                       `/userimage/${email}`,
  //                                     ]);
  //                                   } else if (list.data.welcome_flow == 7) {
  //                                     this.router.navigate([
  //                                       `/selfpacedvideo/${email}`,
  //                                     ]);
  //                                   } else if (list.data.welcome_flow == 8) {
  //                                     this.router.navigate([
  //                                       `/customschedule/${email}`,
  //                                     ]);
  //                                   } else if (list.data.welcome_flow == 9) {
  //                                     this.router.navigate([
  //                                       `/competition/${email}`,
  //                                     ]);
  //                                   } else if (list.data.welcome_flow == 10) {
  //                                     this.router.navigate([
  //                                       `/learning/${email}`,
  //                                     ]);
  //                                   } else if (list.data.welcome_flow == 11) {
  //                                     this.router.navigate([
  //                                       `/thankyou/${email}`,
  //                                     ]);
  //                                   } else {
  //                                     window.localStorage.setItem(
  //                                       'logintrueStudent',
  //                                       'true',
  //                                     );
  //                                     this.cookieService.set(
  //                                       'cd399e84-2103-11ec-9621-0242ac130002',
  //                                       'a9fff10f-d50b-4e35-af0d-3ec922d1b53e',
  //                                     );
  //                                     this.service.ShowSuccess(data[0].message);
  //                                     if (list.data.course_id == null) {
  //                                       this.router.navigate([
  //                                         'enrolledcourse',
  //                                       ]);
  //                                     } else {
  //                                       if (list.data.show_resources == 1) {
  //                                         this.service.instructionHint = false;
  //                                       } else {
  //                                         this.service.instructionHint = true;
  //                                       }
  //                                       await this.listCoursesNew(list.data.id);
  //                                     }
  //                                     await this.spinner.hide();
  //                                   }
  //                                 }
  //                               } else {
  //                                 this.spinner.hide();
  //                                 this.service.ShowError(
  //                                   'You are not allowed to access this portal. Kindly contact customer support.',
  //                                 );
  //                                 await this.addActivityTracker(
  //                                   0,
  //                                   this.email,
  //                                   0,
  //                                   'Login Failed',
  //                                   'Login failed - Not allowed to access this portal',
  //                                 );
  //                               }
  //                             } else {
  //                               await this.spinner.hide();
  //
  //                               this.service.ShowError(
  //                                 'Encountered server error!',
  //                               );
  //                             }
  //                           });
  //                         } else {
  //                           if (data[0].message == 'User is not confirmed.') {
  //                             await this.spinner.hide();
  //                             var email = btoa(JSON.stringify(this.email));
  //                             email = encodeURIComponent(email);
  //                             this.router.navigate([
  //                               `/authentication/${email}`,
  //                             ]);
  //                           } else if (
  //                             data[0].message == 'User does not exist.'
  //                           ) {
  //                             await this.spinner.hide();
  //                             await this.service.ShowError(
  //                               'You haveU+2019t set up your account yet. Please check for a welcome email from system&#64;destcert.com. Contained within the email is a link to the onboarding process.',
  //                             );
  //                             await this.addActivityTracker(
  //                               0,
  //                               this.email,
  //                               0,
  //                               'Login Failed',
  //                               'Login failed - Email does not exist',
  //                             );
  //                           } else {
  //                             this.service.ShowError(data[0].message);
  //                             await this.addActivityTracker(
  //                               0,
  //                               this.email,
  //                               0,
  //                               'Login Failed',
  //                               'Login failed - ' + data[0].message,
  //                             );
  //                             await this.spinner.hide();
  //                           }
  //                         }
  //                       }
  //                     });
  //                 } else {
  //                   this.spinner.hide();
  //                   if (data[0].message == 'Password attempts exceeded') {
  //                     data[0].message =
  //                       data[0].message + ', please try after some time.';
  //                   }
  //                   if (data[0].message == 'User does not exist.') {
  //                     this.service.ShowError(
  //                       'You haveU+2019t set up your account yet. Please check for a welcome email from system&#64;destcert.com. Contained within the email is a link to the onboarding process.',
  //                     );
  //                   } else {
  //                     this.service.ShowError(data[0].message);
  //                   }
  //                   await this.addActivityTracker(
  //                     0,
  //                     this.email,
  //                     0,
  //                     'Login Failed',
  //                     'Login failed - ' + data[0].message,
  //                   );
  //                 }
  //               },
  //               (err) => {
  //                 this.spinner.hide();
  //
  //                 this.service.ShowError('Encountered server error!');
  //               },
  //             );
  //           }
  //         },
  //       );
  //     } catch {
  //       this.spinner.hide();
  //
  //       this.service.ShowError('Encountered server error!');
  //     }
  //   } else {
  //     if (this.email == '' || this.email == null || this.email == undefined) {
  //       this.service.ShowError('Email is required');
  //     } else if (
  //       this.password == '' ||
  //       this.password == null ||
  //       this.password == undefined
  //     ) {
  //       this.service.ShowError('Password is required');
  //     }
  //   }
  // }
  //
  // retuey() {
  //   window.location.reload();
  // }
  // get f() {
  //   return this.LoginForm.controls;
  // }
  // async listCourses(userid, url) {
  //   this.courselist = [];
  //   try {
  //     await this.auth
  //       .getAuthenticatedUser()
  //       .getSession(async (err, session) => {
  //         if (err) {
  //           await this.spinner.hide();
  //           this.router.navigate(['/login']);
  //         } else {
  //           var token = session.getIdToken().getJwtToken();
  //           await this.CourseserviceService.knowassquecource(
  //             userid,
  //             token,
  //           ).then(async (data) => {
  //             if (data[0].status == 200) {
  //               var list = JSON.parse(<string>data[0].json);
  //               this.courselist = list.data;
  //               if (this.courselist.length > 1) {
  //                 this.router.navigate(['enrolledcourse'], {
  //                   queryParams: { returnUrl: url },
  //                 });
  //               } else {
  //                 await this.CertificationClick(this.courselist[0], url);
  //               }
  //             }
  //             await this.spinner.hide();
  //           });
  //         }
  //       });
  //   } catch (e) {
  //     await this.spinner.hide();
  //     this.service.ShowError(e.meassage);
  //   }
  // }
  // async CertificationClick(item, url) {
  //   this.service.courseid = item.certi_id;
  //   await this.getResult(item.certi_id, item.cohort_id);
  //   localStorage.setItem('CisspCourseId', item.certi_id);
  //   localStorage.setItem('cohort_id', item.cohort_id);
  //   localStorage.setItem('overall', item.percentage);
  //   localStorage.setItem('method', item.method_id);
  //   localStorage.setItem(
  //     'course_type',
  //     item.course_type ? item.course_type : '',
  //   );
  //   window.localStorage.setItem('accessitem', JSON.stringify(item.access));
  //   await this.router.navigateByUrl(url);
  // }
  // async getResult(cid, cohort_id) {
  //   await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
  //     if (err) {
  //       this.spinner.hide();
  //       this.router.navigate(['/login']);
  //     } else {
  //       var token = session.getIdToken().getJwtToken();
  //       let user_id = localStorage.getItem('CisspUserId');
  //       this.spinner.show();
  //       await this.CourseserviceService.getDashboardPercentage(
  //         user_id,
  //         cid,
  //         cohort_id,
  //         token,
  //       ).then(async (data) => {
  //         if (data[0].status == 200) {
  //           var list = JSON.parse(<string>data[0].json);
  //           let domainsData = list.data;
  //
  //           let sum = 0;
  //           let i = 0;
  //           let w = [9, 11, 12, 12, 12, 12, 12, 12];
  //           domainsData.forEach((element) => {
  //             element.weight = w[i];
  //             i++;
  //             sum += element.totalPro;
  //           });
  //           localStorage.setItem('domainsData', JSON.stringify(domainsData));
  //           this.spinner.hide();
  //         } else {
  //           this.spinner.hide();
  //         }
  //       });
  //     }
  //   });
  // }
  //
  // async listCoursesNew(userid) {
  //   this.courselist = [];
  //   try {
  //     await this.auth
  //       .getAuthenticatedUser()
  //       .getSession(async (err, session) => {
  //         if (err) {
  //           await this.spinner.hide();
  //           this.router.navigate(['/login']);
  //         } else {
  //           var token = session.getIdToken().getJwtToken();
  //           await this.CourseserviceService.knowassquecource(
  //             userid,
  //             token,
  //           ).then(async (data) => {
  //             if (data[0].status == 200) {
  //               var list = JSON.parse(<string>data[0].json);
  //               this.courselist = list.data;
  //               let courselistNew = [];
  //               this.courselist.forEach((element) => {
  //                 if (element.cohort_id == this.list1.data.course_id) {
  //                   courselistNew.push(element);
  //                 }
  //               });
  //               if (courselistNew.length > 0)
  //                 await this.CertificationClickNew(courselistNew[0]);
  //               else this.router.navigate(['enrolledcourse']);
  //               await this.spinner.hide();
  //             } else if (data[0].status == 502) {
  //               this.listCoursesNew(userid);
  //               await this.spinner.hide();
  //             } else {
  //               await this.spinner.hide();
  //             }
  //           });
  //         }
  //       });
  //   } catch (e) {
  //     await this.spinner.hide();
  //     this.service.ShowError(e.meassage);
  //   }
  // }
  // async CertificationClickNew(item) {
  //   this.service.courseid = item.certi_id;
  //   await this.getResult(item.certi_id, item.cohort_id);
  //   localStorage.setItem('CisspCourseId', item.certi_id);
  //   localStorage.setItem('cohort_id', item.cohort_id);
  //   localStorage.setItem('overall', item.percentage);
  //   localStorage.setItem('method', item.method_id);
  //   localStorage.setItem(
  //     'course_type',
  //     item.course_type ? item.course_type : '',
  //   );
  //   window.localStorage.setItem('accessitem', JSON.stringify(item.access));
  //   await this.router.navigate(['sidenav/dashboard']);
  // }
  // async addActivityTracker(user_id, email, cohort_id, type, message) {
  //   await this.service.addActivityTracker(
  //     user_id,
  //     email,
  //     cohort_id,
  //     type,
  //     message,
  //   );
  // }
}
