<div class="stydy-page" *ngIf="!PaymentSuccess">
  <div class="study-guide">
    <div class="study-img">
      <div class="study-back">
        <a><span class="material-icons"> arrow_back </span>Back to Plans</a>
      </div>
      <div class="stydy-img1">
        <img src="../../assets/price-bg.png" />
      </div>
    </div>
    <div class="study-title">
      <h2>Full CISSP Course</h2>
      <h4>{{ CohortName }}</h4>
    </div>
    <div class="stydy-content">
      <p class="colorclass">CISSP MasterClass Recording</p>
      <p class="colorclass">Weekly Live Q&A Sessions</p>
      <p>Dashboard</p>
      <p>Personalized Study Schedule</p>
      <p>Personalized Review Guide</p>
      <p>Knowledge Assessments</p>
      <p>Flashcard App</p>
      <p>Written Study Material</p>
      <p>References</p>
      <p>Practice Test</p>
      <p>Community Chat</p>
      <p>MindMap / Review Videos</p>
      <p>ISC(2) CISSP Officials Study Guide 9th Edition</p>
      <p>ISC(2) CISSP Officials Practice Question 3rd Edition</p>
      <p>Student Workbook</p>
      <p>CISSP Flashcards</p>
    </div>
  </div>
  <div class="study-pay">
    <div class="stydy-pay-title">
      <h3>Pay with card</h3>
      <form [formGroup]="PayForm" class="my-form">
        <div class="field-wrapper">
          <div class="filed-inner">
            <input
              type="text"
              formControlName="CardHolderName"
              name="cardholder"
              [(ngModel)]="CardHolderName"
              placeholder="Enter the Name on Card"
              class="txt"
              [ngClass]="{
                err:
                  PayForm.controls['CardHolderName'].hasError('required') &&
                  (PayForm.controls['CardHolderName'].dirty ||
                    PayForm.controls['CardHolderName'].touched)
              }"
            />
          </div>
        </div>
        <div class="field-wrapper">
          <div class="filed-inner">
            <input
              type="text"
              (change)="checkEmailExist()"
              formControlName="Email"
              name="Email"
              placeholder="Enter Email"
              [(ngModel)]="Email"
              class="txt"
              [ngClass]="{
                err:
                  (PayForm.controls['Email'].hasError('required') ||
                    PayForm.controls['Email'].hasError('pattern')) &&
                  (PayForm.controls['Email'].dirty ||
                    PayForm.controls['Email'].touched)
              }"
            />
          </div>
        </div>
        <div class="field-wrapper" *ngIf="isCreatePassword">
          <div class="filed-inner">
            <label style="color: #b8b5d4"
              >Good to see you! Please enter the password of your account</label
            >
            <input
              type="password"
              formControlName="CreatePassword"
              name="CreatePassword"
              placeholder="{{ ChangePasswordPlaceHolder }}"
              [(ngModel)]="CreatePassword"
              class="txt"
              [ngClass]="{
                err:
                  (PayForm.controls['CreatePassword'].hasError('required') ||
                    PayForm.controls['CreatePassword'].hasError('pattern')) &&
                  (PayForm.controls['CreatePassword'].dirty ||
                    PayForm.controls['CreatePassword'].touched)
              }"
            />
          </div>
        </div>
        <div class="field-wrapper" *ngIf="isEnterPassword">
          <div class="filed-inner">
            <label style="color: #b8b5d4"
              >Good to see you! Please enter the password of your account</label
            >
            <input
              type="password"
              formControlName="EnterPassword"
              name="EnterPassword"
              placeholder="{{ ChangePasswordPlaceHolder }}"
              (change)="PasswordCheck()"
              [(ngModel)]="EnterPassword"
              class="txt"
              [ngClass]="{
                err:
                  (PayForm.controls['EnterPassword'].hasError('required') ||
                    PayForm.controls['EnterPassword'].hasError('pattern')) &&
                  (PayForm.controls['EnterPassword'].dirty ||
                    PayForm.controls['EnterPassword'].touched)
              }"
            />
            <p style="color: red" *ngIf="isPasswordError">
              Uhh-ohh, that password is incorrect!
            </p>
          </div>
        </div>
        <div class="field-wrapper">
          <div class="filed-inner">
            <input
              type="text"
              formControlName="Address"
              name="Address"
              placeholder="Address"
              [(ngModel)]="Address"
              class="txt"
              [ngClass]="{
                err:
                  (PayForm.controls['Address'].hasError('required') ||
                    PayForm.controls['Address'].hasError('pattern')) &&
                  (PayForm.controls['Address'].dirty ||
                    PayForm.controls['Address'].touched)
              }"
            />
            <p
              style="color: #ee3e76"
              *ngIf="
                PayForm.controls['Address'].hasError('required') &&
                (PayForm.controls['Address'].dirty ||
                  PayForm.controls['Address'].touched)
              "
            >
              *Address is required for shipping the books
            </p>
          </div>
        </div>
        <div class="field-wrapper">
          <div class="filed-inner">
            <input
              type="text"
              formControlName="Country"
              name="Country"
              placeholder="Country"
              [(ngModel)]="Country"
              class="txt"
              [ngClass]="{
                err:
                  (PayForm.controls['Country'].hasError('required') ||
                    PayForm.controls['Country'].hasError('pattern')) &&
                  (PayForm.controls['Country'].dirty ||
                    PayForm.controls['Country'].touched)
              }"
            />
          </div>
        </div>
        <div class="d-flex">
          <div class="field-wrapper">
            <div class="filed-inner">
              <input
                type="text"
                formControlName="City"
                name="City"
                placeholder="City"
                [(ngModel)]="City"
                class="txt"
                [ngClass]="{
                  err:
                    (PayForm.controls['City'].hasError('required') ||
                      PayForm.controls['City'].hasError('pattern')) &&
                    (PayForm.controls['City'].dirty ||
                      PayForm.controls['City'].touched)
                }"
              />
            </div>
          </div>
          <div class="field-wrapper">
            <div class="filed-inner">
              <input
                type="text"
                formControlName="State"
                name="State"
                placeholder="State"
                [(ngModel)]="State"
                class="txt"
                [ngClass]="{
                  err:
                    (PayForm.controls['State'].hasError('required') ||
                      PayForm.controls['State'].hasError('pattern')) &&
                    (PayForm.controls['State'].dirty ||
                      PayForm.controls['State'].touched)
                }"
              />
            </div>
          </div>
          <div class="field-wrapper">
            <div class="filed-inner">
              <input
                type="text"
                formControlName="Zip"
                name="Zip"
                placeholder="Zip"
                [(ngModel)]="Zip"
                class="txt"
                [ngClass]="{
                  err:
                    (PayForm.controls['Zip'].hasError('required') ||
                      PayForm.controls['Zip'].hasError('pattern')) &&
                    (PayForm.controls['Zip'].dirty ||
                      PayForm.controls['Zip'].touched)
                }"
              />
            </div>
          </div>
        </div>
      </form>
      <div class="field-wrapper">
        <div class="filed-inner txt">
          <div id="card-element">
            <ngx-stripe-card
              [options]="cardOptions"
              [elementsOptions]="elementsOptions"
            ></ngx-stripe-card>
          </div>
        </div>
      </div>
      <div class="field-wrapper">
        <div class="filed-inner">
          <button
            class="btncissp"
            [disabled]="!PayForm.valid"
            (click)="payOneTime()"
          >
            {{ ButtonText }}
          </button>
        </div>
      </div>
    </div>
    <div class="paycards">
      <img src="../../assets/images/paycards.png" />
    </div>
  </div>
</div>
<div *ngIf="PaymentSuccess">
  <div class="payment-success">
    <div class="popup">
      <div class="processed">
        <span>
          <svg
            width="102"
            height="102"
            viewBox="0 0 102 102"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="51" cy="51" r="50" stroke="#F473B9" />
            <path
              d="M66.901 57.8554V57.8554C68.5103 57.8554 69.8164 59.1615 69.8164 60.7709C69.8164 62.3821 68.5103 63.6863 66.901 63.6863H64.9573C66.5667 63.6863 67.8728 64.9924 67.8728 66.6018C67.8728 68.213 66.5667 68.5454 64.9573 68.5454H52.3237C46.7824 68.5454 45.521 67.5736 38.7183 66.6018V50.0809C43.4802 50.0809 51.3519 41.3345 51.3519 33.56C51.3519 30.4871 55.6046 29.3423 57.1828 34.9574C58.1546 38.4191 55.2392 45.2218 55.2392 45.2218H70.7882C72.3976 45.2218 73.7037 46.5279 73.7037 48.1372C73.7037 49.7485 72.3976 52.0245 70.7882 52.0245H68.8446C70.4539 52.0245 71.7601 53.3306 71.7601 54.94C71.7601 56.5512 70.4539 57.8554 68.8446 57.8554H66.901"
              stroke="#F473B9"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M36.9339 70.489H30.7843C29.7988 70.489 29 69.6902 29 68.7048V48.9497C29 47.9642 29.7988 47.1654 30.7843 47.1654H36.9339C37.9193 47.1654 38.7182 47.9642 38.7182 48.9497V68.7048C38.7182 69.6902 37.9193 70.489 36.9339 70.489Z"
              stroke="#F473B9"
              stroke-width="1.3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <label>Payment Processed Successfully!</label>
      </div>
      <div class="bottom">
        <div class="journey">
          <p>Your journey begins!</p>
          <p>We're excited to be your guide</p>
        </div>
        <button class="btn">
          <a [href]="WelcomeLink" target="_blank">Let's Go!</a>
        </button>
        <p class="email">We have emailed you a receipt</p>
      </div>
    </div>
  </div>
</div>
<div id="popup1" class="overlay" *ngIf="msgBoxShow">
  <div class="loginmain">
    <div class="login-form">
      <div>
        <img class="logo" src="../../assets/cissplogo.png" alt="CISSP" />
      </div>
      <h3><span>Welcome back!</span> Please login to your account.</h3>
      <form class="form" validate [formGroup]="LoginForm" (key.enter)="login()">
        <div class="input-form mb-20">
          <input
            type="email"
            name="email"
            formControlName="email"
            [(ngModel)]="email"
            class="input"
            placeholder="Enter Email Address"
            id="email"
          />
          <div
            *ngIf="
              !LoginForm.get('email').invalid &&
              (LoginForm.get('email').dirty || LoginForm.get('email').touched)
            "
          >
            <span class="material-icons check"> check_circle_outline </span>
          </div>

          <span class="material-icons pageback"> mail_outline </span>
        </div>
        <div class="input-form mb-20">
          <input
            type="password"
            name="password"
            formControlName="password"
            [(ngModel)]="password"
            class="input"
            placeholder="Enter Password"
          />
          <span class="material-icons pageback"> lock </span>
        </div>
        <button type="submit" (click)="login()" class="submit">Login</button>
        <button type="submit" (click)="Cancel()" class="submit">Cancel</button>
      </form>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
