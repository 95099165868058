import { Component, OnInit } from '@angular/core';
import { ConfirmedValidator } from '../../validation';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthserviceService } from '../../authservice.service';
import { ServicesService } from '../../services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LoginflowService } from '../../data/loginflow.service';
@Component({
  selector: 'app-protectpassword',
  templateUrl: './protectpassword.component.html',
  styleUrls: ['./protectpassword.component.scss'],
})
export class ProtectpasswordComponent implements OnInit {
  public LoginForm: FormGroup;
  password: any;
  Confirmpass: any;
  PassLength: boolean = false;
  strangth = 0;
  IsSmall: boolean = false;
  IsUpper: boolean = false;
  Number: boolean = false;
  IsSpecial: boolean = false;
  Checkpassword: boolean = false;
  flashcardPassword: any;
  PassWidth = 0;
  PassMsg = 'Poor';
  oldpassword: any;
  data: any;
  changepasstype = 'password';
  showCriteria: boolean = false;
  allow_access: number = 0;
  flaseCarduser: boolean = false;
  PracticeAppUser: boolean = false;
  signup_platform: string = '';
  db_user_id: any;
  is_flashcard_user: any;
  constructor(
    public LoginflowService: LoginflowService,
    public ActiveRoute: ActivatedRoute,
    public fb: FormBuilder,
    public auth: AuthserviceService,
    public service: ServicesService,
    public spinner: NgxSpinnerService,
    public router: Router,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(atob(decodeURIComponent(params['data'])));
        this.allow_access = this.data.allow_access ? 1 : this.data.allow_access;
        this.spinner.show();
        await this.LoginflowService.versions_getUserByEmail(
          this.data.email,
        ).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            var userdata = {
              email: this.data.email,
              password: localStorage.getItem('domaindata')
                ? JSON.parse(atob(localStorage.getItem('domaindata')))
                : this.data.password,
              allow_access: this.allow_access,
              cohort_id: this.data.cohort_id,
            };
            var email = btoa(JSON.stringify(userdata));
            email = encodeURIComponent(email);
            this.is_flashcard_user = list.data[0].is_flashcard_user;
            this.signup_platform = list.data[0].signup_platform;
            let wel_flow =
              list.data[0].welcome_flow != undefined ||
              list.data[0].welcome_flow != null
                ? parseInt(list.data[0].welcome_flow)
                : 0;
            if (wel_flow > 4) {
              if (wel_flow == 2) {
                this.router.navigate([`/alias/${email}`]);
                return;
              } else if (wel_flow == 3) {
                this.router.navigate([`/timezone/${email}`]);
                return;
              } else if (wel_flow == 4) {
                this.router.navigate([`/protectpass/${email}`]);
                return;
              } else if (wel_flow == 5) {
                this.router.navigate([`/onetimepsas/${email}`]);
                return;
              } else if (wel_flow == 6) {
                this.router.navigate([`/userimage/${email}`]);
                return;
              } else if (wel_flow == 7) {
                this.router.navigate([`/selfpacedvideo/${email}`]);
                return;
              } else if (wel_flow == 8) {
                this.router.navigate([`/customschedule/${email}`]);
                return;
              } else if (wel_flow == 9) {
                this.router.navigate([`/competition/${email}`]);
                return;
              } else if (wel_flow == 10) {
                this.router.navigate([`/learning/${email}`]);
                return;
              } else if (wel_flow == 11) {
                this.router.navigate([`/thankyou/${email}`]);
                return;
              }
            }
          }
        });
        this.spinner.hide();
        if (this.data.allow_access != 0 && !this.data.allow_access) {
          await this.LoginflowService.versions_getUserByEmail(
            this.data.email,
          ).then(async (data) => {
            if (data[0].status == 200) {
              var list = JSON.parse(<string>data[0].json);
              this.allow_access = list.data[0].allow_access
                ? list.data[0].allow_access
                : 0;
              this.is_flashcard_user = list.data[0].is_flashcard_user
                ? list.data[0].is_flashcard_user
                : 0;
              this.db_user_id = list.data[0].id;
            }
          });
          this.spinner.hide();
        }
        debugger;
        await this.auth.GetUserRequest(this.data.email).then(async (data) => {
          if (data[0].StatusCode == 0) {
            this.flaseCarduser = false;
          } else {
            debugger;
            if (this.allow_access == 0 && this.is_flashcard_user == 1) {
              this.deleteUserFromCognito();
              if (this.signup_platform == 'Pratice Android') {
                this.PracticeAppUser = true;
              }
              this.flaseCarduser = true;
            }
          }
          this.addUserInCognito();
        });
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
    this.password = '';
    this.Confirmpass = '';
  }
  showpass(item) {
    this.changepasstype = item;
  }
  async ngOnInit() {
    this.LoginForm = this.fb.group(
      {
        password: ['', Validators.required],
        Confirmpass: ['', Validators.required],
        oldpassword: ['', Validators.required],
      },
      {
        validator: ConfirmedValidator('password', 'Confirmpass'),
      },
    );
  }
  async addUserInCognito() {
    await this.auth.CreateUser(this.data.email).then(async (data) => {
      if (data[0].StatusCode == 1) {
        let user = this.data.email;
        let msg = data[0].message;
        let cohort = 'Verification OTP';
        let email_type = 'Verification OTP';
        await this.addFailedEmail(user, cohort, msg, email_type, 1);
        await this.addActivityTracker(
          this.data.id,
          user,
          0,
          'Verification OTP',
          'Verification OTP - onboarding verification otp sent successfully',
        );
        await this.spinner.hide();
      } else {
        let user = this.data.email;
        let msg = data[0].message;
        let cohort = 'Verification OTP';
        let email_type = 'Verification OTP';
        await this.addFailedEmail(user, cohort, msg, email_type, 0);
        await this.addActivityTracker(
          this.data.id,
          user,
          0,
          'Verification OTP',
          'Verification OTP failed - ' + msg,
        );
        await this.spinner.hide();
      }
    });
  }
  async deleteUserFromCognito() {
    await this.auth.deleteUser(this.data.email).then(async (data) => {});
  }
  get f() {
    return this.LoginForm.controls;
  }
  confirmcheck() {
    if (
      this.password &&
      this.Confirmpass &&
      this.password == this.Confirmpass
    ) {
      this.Checkpassword = true;
    } else {
      this.Checkpassword = false;
    }
  }
  PasswordCheck() {
    if (this.password.length > 0) {
      this.showCriteria = true;
      this.PassWidth = 0;
      this.IsSmall = false;
      this.IsUpper = false;
      this.Number = false;
      this.IsSpecial = false;
      this.PassLength = false;
      this.Checkpassword = false;
      this.PassMsg = 'Poor';
      var cnt = 0;
      if (/[a-z]/.test(this.password)) {
        this.IsSmall = true;
      }
      if (this.password.length >= 8) {
        this.PassLength = true;
      }
      if (
        this.password &&
        this.Confirmpass &&
        this.password == this.Confirmpass
      ) {
        this.Checkpassword = true;
      } else {
        this.Checkpassword = false;
      }

      if (/[A-Z]/.test(this.password)) {
        this.IsUpper = true;
      }

      if (/[0-9]/.test(this.password)) {
        this.Number = true;
      }
      if (/[!@#$%^&*()_+]/.test(this.password)) {
        this.IsSpecial = true;
      }

      if (this.PassLength) {
        this.PassWidth += 20;
      }
      if (this.IsSmall) {
        this.PassWidth += 20;
      }
      if (this.IsUpper) {
        this.PassWidth += 20;
      }
      if (this.Number) {
        this.PassWidth += 20;
      }
      if (this.IsSpecial) {
        this.PassWidth += 20;
      }

      if (this.PassWidth == 20 || this.PassWidth == 40) {
        this.PassMsg = 'Poor';
      }
      if (this.PassWidth == 60 || this.PassWidth == 80) {
        this.PassMsg = 'Good';
      }
      if (this.PassWidth == 100) {
        this.PassMsg = 'Strong';
      }
    } else {
      this.showCriteria = false;
    }
  }

  async resetpassword() {
    try {
      if (this.LoginForm.valid) {
        if (this.PassWidth === 100) {
          await this.spinner.show();
          var email;
          var data = window.localStorage.getItem('userdetails');
          if (data != undefined && data != null && data != '' && data) {
            var list = JSON.parse(<string>data);
            email = list.email;
          } else {
            email = this.data.email;
          }
          await this.auth
            .TempsignInprotect(email, this.oldpassword, this.password)
            .then(async (resetdata) => {
              if (resetdata[0].StatusCode == 1) {
                var passwordsession = btoa(JSON.stringify(this.password));
                window.localStorage.setItem('domaindata', passwordsession);
                await this.auth
                  .ResetPassword(email, this.oldpassword, this.password)
                  .then(async (resetpasswordcheck) => {
                    if (resetpasswordcheck[0].StatusCode == 1) {
                      await this.LoginflowService.UpdateTempemail(
                        this.data.email,
                        this.oldpassword,
                      );
                      await this.update_onboarding_flow();
                      var userdata = {
                        email: this.data.email,
                        password: this.oldpassword,
                        cohort_id: this.data.cohort_id,
                      };
                      await this.LoginflowService.updateUserWelcomeFlow(
                        this.data.email,
                        '5',
                        this.data.cohort_id,
                      ).then(async (data) => {
                        await this.auth
                          .getAuthenticatedUser()
                          .getSession(async (err, session) => {
                            if (err) {
                              this.spinner.hide();
                              this.router.navigate(['/login']);
                            } else {
                              var token = session.getIdToken().getJwtToken();
                              await this.LoginflowService.UsersFilter(
                                this.data.email,
                                token,
                              ).then(async (user) => {
                                if (user[0].status == 200) {
                                  var list = JSON.parse(<string>user[0].json);
                                  localStorage.setItem(
                                    'CisspUserId',
                                    JSON.stringify(list.data.id),
                                  );
                                  localStorage.setItem(
                                    'userdetails',
                                    JSON.stringify(list.data),
                                  );
                                  window.localStorage.setItem(
                                    'logintrueStudent',
                                    'true',
                                  );

                                  window.localStorage.removeItem('preventBack');
                                  await this.LoginflowService.loginlog(
                                    list.data.id,
                                    '',
                                    '',
                                    '',
                                  );
                                  await this.spinner.hide();
                                  var userdata = {
                                    email: this.data.email,
                                    password: this.password,
                                    cohort_id: this.data.cohort_id,
                                  };
                                  await this.LoginflowService.UpdateTempallow_access(
                                    this.data.email,
                                    1,
                                  );
                                  await this.update_onboarding_flow();
                                  await this.LoginflowService.updateUserWelcomeFlow(
                                    this.data.email,
                                    '5',
                                    this.data.cohort_id,
                                  ).then((data) => {
                                    var datas = btoa(JSON.stringify(userdata));
                                    datas = encodeURIComponent(datas);
                                    this.router.navigate([
                                      `/onetimepsas/${datas}`,
                                    ]);
                                  });
                                } else {
                                  await this.spinner.hide();
                                }
                              });
                            }
                          });
                      });
                    } else {
                      this.spinner.hide();
                      this.service.ShowError(resetdata[0].message);
                    }
                  });
              } else if (resetdata[0].StatusCode == 2) {
                var passwordsession = btoa(JSON.stringify(this.password));
                window.localStorage.setItem('domaindata', passwordsession);
                await this.LoginflowService.UpdateTempemail(
                  this.data.email,
                  this.password,
                );
                await this.auth
                  .getAuthenticatedUser()
                  .getSession(async (err, session) => {
                    if (err) {
                      this.spinner.hide();
                      this.router.navigate(['/login']);
                    } else {
                      var token = session.getIdToken().getJwtToken();
                      await this.LoginflowService.UsersFilter(
                        this.data.email,
                        token,
                      ).then(async (user) => {
                        if (user[0].status == 200) {
                          var list = JSON.parse(<string>user[0].json);
                          localStorage.setItem(
                            'CisspUserId',
                            JSON.stringify(list.data.id),
                          );
                          localStorage.setItem(
                            'userdetails',
                            JSON.stringify(list.data),
                          );
                          window.localStorage.setItem(
                            'logintrueStudent',
                            'true',
                          );

                          window.localStorage.removeItem('preventBack');
                          this.LoginflowService.loginlog(
                            list.data.id,
                            '',
                            '',
                            '',
                          );
                          await this.LoginflowService.loginlog(
                            list.data.id,
                            '',
                            '',
                            '',
                          );
                        }
                      });
                    }
                  });
                this.spinner.hide();
                var userdata = {
                  email: this.data.email,
                  password: this.password,
                  cohort_id: this.data.cohort_id,
                };
                await this.update_onboarding_flow();
                await this.LoginflowService.updateUserWelcomeFlow(
                  this.data.email,
                  '5',
                  this.data.cohort_id,
                ).then(async (data) => {
                  var updateOnboardingUser = {
                    email: this.data.email,
                    expire_password: '0',
                  };
                  await this.LoginflowService.updateUserByEmail(
                    updateOnboardingUser,
                  ).then(async (userRes) => {
                    if (userRes[0].status == 200) {
                      var listuserRes = JSON.parse(<string>userRes[0].json);
                    }
                  });
                  var datas = btoa(JSON.stringify(userdata));
                  datas = encodeURIComponent(datas);
                  this.router.navigate([`/onetimepsas/${datas}`]);
                });
              } else {
                if (resetdata[0].message == 'Incorrect username or password.') {
                  this.service.ShowError(
                    "Please enter a correct activation code or enter the old password that you have set during the first onboarding process. Incase, you don't remember please try the forget password flow (This only applies when you have completed the reset password flow at-least once.)",
                  );
                } else {
                  this.service.ShowError(resetdata[0].message);
                }
                this.spinner.hide();
              }
            });
          await this.spinner.hide();
        } else {
          await this.spinner.hide();
        }
      }
    } catch (e) {
      this.spinner.hide();
    }
  }
  async FlashCardPassword() {
    try {
      await this.spinner.show();
      var email;
      var data = window.localStorage.getItem('userdetails');
      if (data != undefined && data != null && data != '' && data) {
        var list = JSON.parse(<string>data);
        email = list.email;
      } else {
        email = this.data.email;
      }
      await this.auth
        .FlashCardUserLogin(email, this.flashcardPassword)
        .then(async (resetdata) => {
          if (resetdata[0].StatusCode == 1) {
            var passwordsession = btoa(JSON.stringify(this.flashcardPassword));
            window.localStorage.setItem('domaindata', passwordsession);
            await this.LoginflowService.UpdateTempallow_access(
              this.data.email,
              1,
            );
            await this.auth
              .getAuthenticatedUser()
              .getSession(async (err, session) => {
                if (err) {
                  this.spinner.hide();
                  this.router.navigate(['/login']);
                } else {
                  var token = session.getIdToken().getJwtToken();
                  await this.LoginflowService.UsersFilter(
                    this.data.email,
                    token,
                  ).then(async (user) => {
                    if (user[0].status == 200) {
                      var list = JSON.parse(<string>user[0].json);
                      localStorage.setItem(
                        'CisspUserId',
                        JSON.stringify(list.data.id),
                      );
                      localStorage.setItem(
                        'userdetails',
                        JSON.stringify(list.data),
                      );
                      window.localStorage.setItem('logintrueStudent', 'true');

                      window.localStorage.removeItem('preventBack');
                      this.LoginflowService.loginlog(list.data.id, '', '', '');
                      await this.LoginflowService.loginlog(
                        list.data.id,
                        '',
                        '',
                        '',
                      );
                    }
                  });
                }
              });
            this.spinner.hide();
            var userdata = {
              email: this.data.email,
              password: this.flashcardPassword,
              cohort_id: this.data.cohort_id,
            };
            await this.update_onboarding_flow();
            await this.LoginflowService.updateUserWelcomeFlow(
              this.data.email,
              '5',
              this.data.cohort_id,
            ).then((data) => {
              var datas = btoa(JSON.stringify(userdata));
              datas = encodeURIComponent(datas);
              this.router.navigate([`/onetimepsas/${datas}`]);
            });
          } else {
            this.service.ShowError(resetdata[0].message);
            this.spinner.hide();
          }
        });
      await this.spinner.hide();
    } catch (e) {
      this.spinner.hide();
    }
  }

  async addFailedEmail(user, cohort, msg, email_type, mail_success) {
    await this.service
      .addFailedEmail(user, cohort, msg, email_type, mail_success, '')
      .then(async (data) => {});
  }
  async addActivityTracker(user_id, email, cohort_id, type, message) {
    await this.service.addActivityTracker(
      user_id,
      email,
      cohort_id,
      type,
      message,
    );
  }

  async update_onboarding_flow() {
    await this.LoginflowService.versions_getUserByEmail(this.data.email).then(
      async (data) => {
        if (data[0].status == 200) {
          var list = JSON.parse(<string>data[0].json);
          this.db_user_id = list.data[0].id;
          var onboardigdata = {
            user_id: this.db_user_id,
            cohort_id: this.data.cohort_id,
            is_expire: '1',
            welcome_flow: '5',
            link_expire_on: new Date(),
          };

          await this.LoginflowService.update_onboarding_flow(
            onboardigdata,
          ).then((onboardingFlowRes) => {
            if (onboardingFlowRes[0].status == 200) {
              var list = JSON.parse(<string>onboardingFlowRes[0].json);
            } else {
              this.router.navigate(['/login']);
            }
          });
        }
      },
    );
  }
}
