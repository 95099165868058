import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class SharedDataService {
  private reviewGuideData = [];
  private knowledgeAssessmentData = [];
  private scheduleData = '';
  private leaderBoardData = [];
  private expiryDateData = [];
  private baselineData = [];
  private componentTime = [];
  private videoLessonData = {};
  private mindMapsData = [];

  clearSharedData() {
    this.reviewGuideData = [];
    this.knowledgeAssessmentData = [];
    this.scheduleData = '';
    this.leaderBoardData = [];
    this.expiryDateData = [];
    this.baselineData = [];
    this.componentTime = [];
    this.videoLessonData = {};
    this.mindMapsData = [];
  }

  //Review Guide Start-----------------
  setSharedReviewGuideData(data: any) {
    this.reviewGuideData = data;
  }
  getSharedReviewGuideData() {
    return this.reviewGuideData;
  }
  //Review Guide End-----------------

  //Knowledge Assessment Start-----------
  setKnowledgeAssessmentData(data: any) {
    this.knowledgeAssessmentData = data;
  }
  getKnowledgeAssessmentData() {
    return this.knowledgeAssessmentData;
  }
  //Knowledge Assessment End-----------

  //Schedule Start-----------
  setScheduleData(data: any) {
    this.scheduleData = JSON.stringify(data);
  }
  getScheduleData() {
    return this.scheduleData.length > 0 ? JSON.parse(this.scheduleData) : [];
  }
  setExpiryDateData(data: any) {
    this.expiryDateData = data;
  }
  getExpiryDateData() {
    return this.expiryDateData;
  }
  setBaselineData(data: any) {
    this.baselineData = data;
  }
  getBaselineData() {
    return this.baselineData;
  }
  setComponentTimeData(data: any) {
    this.componentTime = data;
  }
  getComponentTimeData() {
    return this.componentTime;
  }
  //Schedule End-----------

  //LeaderBoard Start-----------
  setLeaderBoardData(data: any) {
    this.leaderBoardData = data;
  }
  getLeaderBoardData() {
    return this.leaderBoardData;
  }
  //LeaderBoard End-----------

  //VideoLesson Start-----------
  setVideoLessonData(data: any) {
    this.videoLessonData = data;
  }
  getVideoLessonData() {
    return this.videoLessonData;
  }
  //VideoLesson End-----------

  //mindMaps Start-----------
  setMindMapsData(data: any) {
    this.mindMapsData = data;
  }
  getMindMapsData() {
    return this.mindMapsData;
  }
  //mindMaps End-----------
}
