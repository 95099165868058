<div class="survey-wrapper">
  <div class="video-wrapper">
    <div class="video-top">
      <img src="../../../assets/images/sidebar-logo.png" alt="Logo" />
      <h4>
        Welcome to {{ course_type }}
        <span>{{ StartDate }} {{ CohortName }}</span>
      </h4>
    </div>
    <div class="video-div" (mousemove)="resetTimer()">
      <vg-player
        (onPlayerReady)="onPlayerReady($event)"
        [ngClass]="{ vgControls: !showControls }"
      >
        <vg-controls *ngIf="HideControls">
          <vg-play-pause></vg-play-pause>
          <vg-time-display
            vgProperty="current"
            vgFormat="mm:ss"
          ></vg-time-display>
          <vg-scrub-bar readonly="disabled" disabled="disabled">
            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
            <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
          </vg-scrub-bar>
          <vg-time-display
            vgProperty="left"
            vgFormat="mm:ss"
            style="justify-content: flex-end !important"
          >
          </vg-time-display>
          <div class="settings" #videoElement>
            <div
              class="settings-button"
              [ngClass]="{ active_settings: activeSettings }"
              (click)="toggleSettings()"
            >
              <span class="material-icons-outlined">settings</span>
            </div>
            <div class="settings-popup" *ngIf="showSettingsPopup">
              <div class="options" (click)="showoption('PlaybackOptions')">
                <div class="option-name">
                  <img
                    src="../../../assets/playback-speed.png"
                    alt="playback-speed"
                  />
                  <span>Playback speed</span>
                </div>
                <div class="arrow">
                  <span>{{ selectedPlaybackSpeed }}</span>
                  <span class="material-icons">keyboard_arrow_right</span>
                </div>
              </div>
              <div class="options" (click)="showoption('QualityOptions')">
                <div class="option-name">
                  <span class="material-icons-outlined"> tune </span>
                  <span>Video quality</span>
                </div>
                <div class="arrow">
                  <span>{{ selectedVideoQuality }}</span>
                  <span class="material-icons">keyboard_arrow_right</span>
                </div>
              </div>
            </div>
            <div class="PlaybackSettings" *ngIf="showPlaybackPopup">
              <div class="playback-speed-options">
                <label
                  class="back-button"
                  (click)="backShowoption('PlaybackOptions')"
                >
                  <span class="material-icons">keyboard_arrow_left</span>
                  <span>Playback speed</span>
                </label>
                <div class="playbackSpeedList">
                  <div
                    *ngFor="let speed of playbackSpeedOptions"
                    [ngClass]="{ activeSpeed: speed == selectedPlaybackSpeed }"
                    (click)="setPlaybackSpeed(speed)"
                  >
                    {{ speed }}
                    <span
                      *ngIf="speed == selectedPlaybackSpeed"
                      class="material-icons-outlined"
                      >check</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="PlaybackSettings" *ngIf="showVideoQuality">
              <div class="playback-speed-options">
                <label
                  class="back-button"
                  (click)="backShowoption('QualityOptions')"
                >
                  <span class="material-icons">keyboard_arrow_left</span>
                  <span>Video quality</span>
                </label>
                <div class="playbackSpeedList">
                  <div
                    *ngFor="let quality of videoQualityOptions"
                    [ngClass]="{ activeSpeed: quality == selectedVideoQuality }"
                    (click)="setVideoQuality(quality)"
                  >
                    {{ quality }}
                    <span
                      *ngIf="quality == selectedVideoQuality"
                      class="material-icons-outlined"
                      >check</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <vg-fullscreen></vg-fullscreen>
        </vg-controls>
        <vg-overlay-play
          vgFor="singleVideo"
          *ngIf="!HideControlsOverlay"
        ></vg-overlay-play>
        <vg-overlay-play
          vgFor="singleVideo"
          *ngIf="!HideControlsOverlay1"
          (click)="PlayAgain()"
          class="PlayAgain"
        >
        </vg-overlay-play>
        <video
          poster="{{ thumnail }}"
          [vgMedia]="media"
          #media
          id="singleVideo"
          preload="auto"
          (click)="changeplay()"
        >
          <source [src]="welcomevideo" type="video/mp4" *ngIf="isselfpaced" />
          <source
            [src]="liveClass"
            type="video/mp4"
            *ngIf="!isselfpaced && !hybrid"
          />
          <source [src]="HybridClass" type="video/mp4" *ngIf="hybrid" />
        </video>
      </vg-player>
      <p class="play_again" *ngIf="!HideControlsOverlay1" (click)="PlayAgain()">
        Play Again
      </p>
      <button
        type="button"
        class="frm-next-btn"
        (click)="next()"
        *ngIf="!HideControlsOverlay1"
      >
        Continue
      </button>
    </div>
  </div>
</div>
<div class="form-button-next">
  <button type="button" class="frm-prev-btn" style="opacity: 0">
    <span class="material-icons">arrow_back</span>
  </button>
  <button type="button" class="frm-next-btn-down" (click)="next()">
    <span class="material-icons"> arrow_forward </span>
  </button>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
