import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../services.service';
import { AuthserviceService } from '../../authservice.service';
import { ActivatedRoute } from '@angular/router';
import { LoginflowService } from '../../data/loginflow.service';
import { CryptoService } from 'src/app/services/crypto/crypto.service';
import { CookieService } from 'ngx-cookie-service';
import { CourseserviceService } from 'src/app/services/course/courseservice.service';
@Component({
  selector: 'app-authypassword',
  templateUrl: './authypassword.component.html',
  styleUrls: ['./authypassword.component.scss'],
})
export class AuthypasswordComponent implements OnInit {
  list1: any;
  data: any;
  code: any;
  dataqr: any;
  AuthyShow = false;
  elementType: 'url';
  value: any = '';
  public LoginForm: FormGroup;
  devicecode: any;
  newdata: any;
  courselist = [];
  authyPasswordEnbled: boolean = false;
  constructor(
    public LoginflowService: LoginflowService,
    public ActiveRoute: ActivatedRoute,
    public service: ServicesService,
    public auth: AuthserviceService,
    public spinner: NgxSpinnerService,
    public fb: FormBuilder,
    public router: Router,
    private crypto: CryptoService,
    private cookieService: CookieService,
    public CourseserviceService: CourseserviceService,
  ) {
    this.spinner.hide();
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(
          this.crypto.dec(decodeURIComponent(params['data'])),
        );
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
  }
  async ngOnInit() {
    this.LoginForm = this.fb.group({
      code: ['', Validators.required],
    });
    this.getQRCode();
  }
  async getQRCode() {
    try {
      await this.spinner.show();
      var passwordset = this.data.password;
      if (passwordset && this.data.email) {
        await this.auth
          .Getqrcode(this.data.email, passwordset)
          .then((dataset) => {
            if (dataset && dataset[0].StatusCode == 1) {
              this.data.password = passwordset;
              this.value = dataset[0].code;
              this.devicecode = dataset[0].devicecode;
              this.newdata = dataset;
              this.spinner.hide();
              this.authyPasswordEnbled = false;
            } else {
              if (
                dataset[0].message == 'callback.totpRequired is not a function'
              ) {
                this.authyPasswordEnbled = true;
                this.spinner.hide();
              } else {
                this.authyPasswordEnbled = true;
                this.spinner.hide();
              }
            }
          });
      } else {
        this.spinner.hide();
      }
    } catch {
      this.spinner.hide();
    }
  }
  async thankyou() {
    if (this.code) {
      if (this.code.trim() && this.code.length == 6) {
        this.spinner.show();
        await this.auth
          .verify(this.data.email, this.data.password, this.code, 0)
          .then(async (set) => {
            if (set[0].StatusCode == 1) {
              await this.auth
                .getAuthenticatedUser()
                .getSession(async (err, session) => {
                  if (err) {
                    this.spinner.hide();
                    this.router.navigate(['/login']);
                  } else {
                    var token = session.getIdToken().getJwtToken();
                    if (set[0].StatusCode == 1) {
                      await this.LoginflowService.UsersFilter(
                        this.data.email,
                        token,
                      ).then(async (user) => {
                        if (user[0].status == 200) {
                          var list = JSON.parse(<string>user[0].json);
                          this.list1 = list;
                          if (list.data.allow_access == 1) {
                            this.LoginflowService.loginlog(
                              list.data.id,
                              '',
                              '',
                              '',
                            );
                            localStorage.setItem(
                              'CisspUserId',
                              JSON.stringify(list.data.id),
                            );
                            localStorage.setItem(
                              'userdetails',
                              JSON.stringify(list.data),
                            );
                            var userdata = {
                              email: this.data.email,
                              password: this.data.password,
                            };
                            var passwordsession = btoa(
                              JSON.stringify(this.data.password),
                            );
                            window.localStorage.setItem(
                              'domaindata',
                              passwordsession,
                            );
                            var email = btoa(JSON.stringify(userdata));
                            email = encodeURIComponent(email);
                            var uri =
                              this.ActiveRoute.snapshot.queryParams[
                                'returnUrl'
                              ] || null;
                            if (uri) {
                              localStorage.setItem(
                                'CisspUserId',
                                JSON.stringify(list.data.id),
                              );
                              localStorage.setItem(
                                'userdetails',
                                JSON.stringify(list.data),
                              );
                              window.localStorage.setItem(
                                'logintrueStudent',
                                'true',
                              );
                              this.cookieService.set(
                                'cd399e84-2103-11ec-9621-0242ac130002',
                                'a9fff10f-d50b-4e35-af0d-3ec922d1b53e',
                              );
                              await this.listCourses(list.data.id, uri);
                            } else {
                              if (list.data.welcome_flow == 2) {
                                this.router.navigate([`/alias/${email}`]);
                              } else if (list.data.welcome_flow == 3) {
                                this.router.navigate([`/timezone/${email}`]);
                              } else if (list.data.welcome_flow == 4) {
                                this.router.navigate([`/protectpass/${email}`]);
                              } else if (list.data.welcome_flow == 5) {
                                this.router.navigate([`/onetimepsas/${email}`]);
                              } else if (list.data.welcome_flow == 6) {
                                this.router.navigate([`/userimage/${email}`]);
                              } else if (list.data.welcome_flow == 7) {
                                this.router.navigate([
                                  `/selfpacedvideo/${email}`,
                                ]);
                              } else if (list.data.welcome_flow == 8) {
                                this.router.navigate([
                                  `/customschedule/${email}`,
                                ]);
                              } else if (list.data.welcome_flow == 9) {
                                this.router.navigate([`/competition/${email}`]);
                              } else if (list.data.welcome_flow == 10) {
                                this.router.navigate([`/learning/${email}`]);
                              } else {
                                if (list.data.welcome_flow == 11) {
                                  await this.LoginflowService.updateUserWelcomeFlow(
                                    this.data.email,
                                    '0',
                                    this.data.cohort_id,
                                  ).then(async (data) => {});
                                }
                                window.localStorage.setItem(
                                  'logintrueStudent',
                                  'true',
                                );
                                this.cookieService.set(
                                  'cd399e84-2103-11ec-9621-0242ac130002',
                                  'a9fff10f-d50b-4e35-af0d-3ec922d1b53e',
                                );
                                this.service.ShowSuccess(set[0].message);
                                if (list.data.course_id == null) {
                                  this.router.navigate(['enrolledcourse']);
                                } else {
                                  if (list.data.show_resources == 1) {
                                    this.service.instructionHint = false;
                                  } else {
                                    this.service.instructionHint = true;
                                  }
                                  await this.listCoursesNew(list.data.id);
                                }
                                await this.spinner.hide();
                              }
                            }
                          } else {
                            this.spinner.hide();
                            this.service.ShowError(
                              'You are not allowed to access this portal. Kindly contact customer support.',
                            );
                          }
                        } else {
                          await this.spinner.hide();
                          this.service.ShowError('Encountered server error!');
                        }
                      });
                    } else {
                      if (set[0].message == 'User is not confirmed.') {
                        await this.spinner.hide();
                        var email = btoa(JSON.stringify(this.data.email));
                        email = encodeURIComponent(email);
                        this.router.navigate([`/authentication/${email}`]);
                      } else if (set[0].message == 'User does not exist.') {
                        await this.spinner.hide();
                        this.service.ShowError(
                          'You haveU+2019t set up your account yet. Please check for a welcome email from system&#64;destcert.com. Contained within the email is a link to the onboarding process.',
                        );
                      } else {
                        this.service.ShowError(set[0].message);
                        await this.spinner.hide();
                      }
                    }
                  }
                });
              await this.spinner.hide();
            } else {
              await this.spinner.hide();
              this.service.ShowError(
                'You have entered Invalid code. Please try again with valid code.',
              );
            }
          });
        await this.spinner.hide();
      } else {
        await this.spinner.hide();
        this.service.ShowError(
          'Please enter the 6-digit one-time password that has been generated by your app',
        );
      }
    } else {
      this.service.ShowError(
        'Please enter the 6-digit one-time password that has been generated by your app ',
      );
    }
    await this.spinner.hide();
  }
  async GeneratedQR() {
    try {
      await this.spinner.show();
      var passwordset = this.data.password;
      if (passwordset && this.data.email) {
        await this.auth
          .ResetMFASetup(this.data.email, passwordset)
          .then(async (dataset) => {
            await this.getQRCode();
            this.authyPasswordEnbled = false;
          });
        this.spinner.hide();
      } else {
        this.router.navigate(['/login']);
        this.spinner.hide();
      }
    } catch {
      this.router.navigate(['/login']);
      this.spinner.hide();
    }
  }
  async listCourses(userid, url) {
    this.courselist = [];
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.CourseserviceService.knowassquecource(
              userid,
              token,
            ).then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
                this.courselist = list.data;
                if (this.courselist.length > 1) {
                  this.router.navigate(['enrolledcourse'], {
                    queryParams: { returnUrl: url },
                  });
                } else {
                  await this.CertificationClick(this.courselist[0], url);
                }
              }
              await this.spinner.hide();
            });
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }
  async listCoursesNew(userid) {
    this.courselist = [];
    try {
      await this.auth
        .getAuthenticatedUser()
        .getSession(async (err, session) => {
          if (err) {
            await this.spinner.hide();
            this.router.navigate(['/login']);
          } else {
            var token = session.getIdToken().getJwtToken();
            await this.CourseserviceService.knowassquecource(
              userid,
              token,
            ).then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
                this.courselist = list.data;
                let courselistNew = [];
                this.courselist.forEach((element) => {
                  if (element.cohort_id == this.list1.data.course_id) {
                    courselistNew.push(element);
                  }
                });
                if (courselistNew.length > 0)
                  await this.CertificationClickNew(courselistNew[0]);
                else this.router.navigate(['enrolledcourse']);
                await this.spinner.hide();
              } else if (data[0].status == 502) {
                this.listCoursesNew(userid);
                await this.spinner.hide();
              } else {
                await this.spinner.hide();
              }
            });
          }
        });
    } catch (e) {
      await this.spinner.hide();
      this.service.ShowError(e.meassage);
    }
  }
  async CertificationClick(item, url) {
    this.service.courseid = item.certi_id;
    await this.getResult(item.certi_id, item.cohort_id);
    localStorage.setItem('CisspCourseId', item.certi_id);
    localStorage.setItem('cohort_id', item.cohort_id);
    localStorage.setItem('overall', item.percentage);
    localStorage.setItem('method', item.method_id);
    localStorage.setItem(
      'course_type',
      item.course_type ? item.course_type : '',
    );
    window.localStorage.setItem('accessitem', JSON.stringify(item.access));
    await this.router.navigateByUrl(url);
  }
  async CertificationClickNew(item) {
    this.service.courseid = item.certi_id;
    await this.getResult(item.certi_id, item.cohort_id);
    localStorage.setItem('CisspCourseId', item.certi_id);
    localStorage.setItem('cohort_id', item.cohort_id);
    localStorage.setItem('overall', item.percentage);
    localStorage.setItem('method', item.method_id);
    localStorage.setItem(
      'course_type',
      item.course_type ? item.course_type : '',
    );
    window.localStorage.setItem('accessitem', JSON.stringify(item.access));
    await this.router.navigate(['sidenav/dashboard']);
  }
  async getResult(cid, cohort_id) {
    await this.auth.getAuthenticatedUser().getSession(async (err, session) => {
      if (err) {
        this.spinner.hide();
        this.router.navigate(['/login']);
      } else {
        var token = session.getIdToken().getJwtToken();
        let user_id = localStorage.getItem('CisspUserId');
        this.spinner.show();
        await this.CourseserviceService.getDashboardPercentage(
          user_id,
          cid,
          cohort_id,
          token,
        ).then(async (data) => {
          if (data[0].status == 200) {
            var list = JSON.parse(<string>data[0].json);
            let domainsData = list.data;
            let sum = 0;
            let i = 0;
            let w = [9, 11, 12, 12, 12, 12, 12, 12];
            domainsData.forEach((element) => {
              element.weight = w[i];
              i++;
              sum += element.totalPro;
            });
            localStorage.setItem('domainsData', JSON.stringify(domainsData));
            this.spinner.hide();
          } else {
            this.spinner.hide();
          }
        });
      }
    });
  }
}
