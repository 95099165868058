import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Http, Headers, RequestOptions } from '@angular/http';
import { CryptoService } from '../crypto/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class TakesurveyService {
  baseurl = environment.baseurl;

  constructor(
    public http: Http,
    private crypto: CryptoService,
  ) {}

  UpdateSurveyActivity(UpdateSurvey, token) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(UpdateSurvey)),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.SURVEY_ACTIVITY;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  GetSurveyActivity(user_id, cohort_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.GET_SURVEY_ACTIVITY;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async GetAllDropdown(token) {
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.USER_LOGS_ALL_DROPDOWN;
      this.http.post(url, options, { headers: headers }).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
          console.log(err);
        },
      );
    });
  }

  GetDomainlist(cohort_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.DOMAIN_LIST;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
}
