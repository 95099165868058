import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../services.service';
import { AuthserviceService } from '../../authservice.service';
import { LoginflowService } from '../../data/loginflow.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss'],
})
export class WelcomeComponent implements OnInit {
  data: any;
  Users = [];
  cohort_id: any;
  CohortName: any;
  thumnail: any;
  welcomevideo: any;
  StartDate: any;
  IsLinkExpored = '';
  subscriptions: Subscription[] = [];
  api: VgApiService;
  HideControls: boolean = false;
  HideControlsOverlay: boolean = false;
  HideControlsOverlay1: boolean = true;
  is_selfpaced: any = '0';
  db_cohort_id: any = '';
  welcome_flow: any = '';
  showControls: boolean = false;
  showSettingsPopup: boolean = false;
  showPlaybackPopup: boolean = false;
  showVideoQuality: boolean = false;
  activeSettings: boolean = false;
  playbackSpeedOptions: string[] = [
    '0.25',
    '0.5',
    '0.75',
    'Normal',
    '1.25',
    '1.5',
    '1.75',
    '2.0',
  ];
  selectedPlaybackSpeed: string = 'Normal';
  videoQualityOptions: string[] = [
    '1080p HD',
    '720p',
    '480p',
    '360p',
    '240p',
    '144p',
  ];
  selectedVideoQuality: string = '1080p HD';
  timer: any;
  VideoLink: any;
  video_trans_list = {
    video_url:
      'https://d3dou688niv5pt.cloudfront.net/1%20Detailed%20account%20%E2%80%93%20welcome%20to%20destination%20certification_1080.mp4',
    video_url_720p:
      'https://d3dou688niv5pt.cloudfront.net/1%20Detailed%20account%20%E2%80%93%20welcome%20to%20destination%20certification_720p.mp4',
    video_url_480p:
      'https://d3dou688niv5pt.cloudfront.net/1%20Detailed%20account%20%E2%80%93%20welcome%20to%20destination%20certification_480p.mp4',
    video_url_360p:
      'https://d3dou688niv5pt.cloudfront.net/1%20Detailed%20account%20%E2%80%93%20welcome%20to%20destination%20certification_360p.mp4',
    video_url_240p:
      'https://d3dou688niv5pt.cloudfront.net/1%20Detailed%20account%20%E2%80%93%20welcome%20to%20destination%20certification_240p.mp4',
    video_url_144p:
      'https://d3dou688niv5pt.cloudfront.net/1%20Detailed%20account%20%E2%80%93%20welcome%20to%20destination%20certification_144p.mp4',
  };

  constructor(
    public LoginflowService: LoginflowService,
    public ActiveRoute: ActivatedRoute,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    public auth: AuthserviceService,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(atob(decodeURIComponent(params['data'])));
        this.cohort_id = this.data.cohort;
        localStorage.setItem('cohort_id', this.data.cohort);
        await this.service
          .get_welcome_links(this.cohort_id)
          .then(async (data) => {
            var list = JSON.parse(<string>data[0].json);
            var cohortlist = list.data[0];
            window.localStorage.setItem(
              'CisspCourseId',
              cohortlist[0].certi_id,
            );
            this.CohortName = cohortlist[0].cohort_name;
            if (cohortlist[0].course_type == null) {
              this.thumnail = cohortlist[0].live_welcome_thumbnail;
              this.welcomevideo = cohortlist[0].live_welcome_video;
              this.is_selfpaced = '0';
            } else {
              this.thumnail = cohortlist[0].self_welcome_thumbnail;
              this.welcomevideo = cohortlist[0].self_welcome_video;
              this.is_selfpaced = '1';
            }
          });

        this.StartDate = this.data.date;
        await this.versions_getUserById(this.data.user_id);
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
  }
  @ViewChild('videoElement') videoElement!: ElementRef;
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    if (!this.videoElement?.nativeElement.contains(event.target)) {
      if (
        (!this.showPlaybackPopup && this.showVideoQuality) ||
        (this.showPlaybackPopup && !this.showVideoQuality)
      ) {
        this.toggleSettings();
      } else if (this.showSettingsPopup) {
        this.toggleSettings();
      }
    }
  }
  ngOnInit() {
    this.VideoLink = this.video_trans_list.video_url;
    var myVideo = document.getElementById('singleVideo') as HTMLVideoElement;
    if (myVideo.addEventListener) {
      myVideo.addEventListener(
        'contextmenu',
        function (e) {
          e.preventDefault();
        },
        false,
      );
    }
  }
  resetTimer() {
    this.showControls = true;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.hideControls();
    }, 3000);
  }

  hideControls() {
    this.showControls = false;
  }
  async createUser() {
    await this.auth.CreateUser(this.Users[0].email).then(async (data) => {
      if (data[0].StatusCode == 1) {
        await this.spinner.hide();
      } else {
        await this.spinner.hide();
      }
    });
  }
  async versions_getUserById(user_id) {
    await this.LoginflowService.loginLogsBy(user_id).then(async (data) => {
      var list = JSON.parse(<string>data[0].json);

      await this.spinner.show();
      await this.LoginflowService.versions_getUserById(user_id).then(
        async (dataRes) => {
          if (dataRes[0].status == 200) {
            var list = JSON.parse(<string>dataRes[0].json);
            if (list.data.length <= 0) {
              this.router.navigate(['/login']);
            } else {
              this.Users = list.data;
            }
          } else {
            this.router.navigate(['/login']);
          }
        },
      );
      if (list.data.length > 0) {
        await this.onBoardingflow();
      } else {
        await this.onBoardingflow();
        this.IsLinkExpored = 'false';
      }
    });
    await this.spinner.hide();
  }
  async onBoardingflow() {
    var onboardigdata = {
      user_id: this.data.user_id,
      cohort_id: this.cohort_id,
      is_selfpaced: this.is_selfpaced,
    };
    await this.LoginflowService.add_onboarding_flow(onboardigdata).then(
      async (onboardingFlowRes) => {
        if (onboardingFlowRes[0].status == 200) {
          var list = JSON.parse(<string>onboardingFlowRes[0].json);
          if (!list.data.is_main) {
            if (!list.data.is_expire) {
              var updateOnboardingUser = {
                email: this.Users[0].email,
                onboard_cohort: this.cohort_id,
                is_selfpaced: this.is_selfpaced,
                welcome_flow: '7',
                course_id: this.cohort_id.toString(),
              };
              await this.LoginflowService.updateUserByEmail(
                updateOnboardingUser,
              ).then(async (userRes) => {
                if (userRes[0].status == 200) {
                  await this.update_onboarding_flow();
                  await this.LoginflowService.updateUserWelcomeFlow(
                    this.Users[0].email,
                    '7',
                  ).then(async (data) => {
                    var dataemail = {
                      email: this.Users[0].email,
                      password: this.data.password,
                      cohort_id: this.cohort_id,
                    };
                    this.IsLinkExpored = 'false';
                    var param = btoa(JSON.stringify(dataemail));
                    param = encodeURIComponent(param);
                    this.router.navigate(['login'], {
                      queryParams: { return: `/selfpacedvideo/${param}` },
                    });
                  });
                }
              });
              this.IsLinkExpored = 'false';
            } else {
              this.IsLinkExpored = 'true';
            }
          } else {
            if (!list.data.is_expire) {
              this.IsLinkExpored = 'false';
              var updateOnboardingUserdata = {
                email: this.Users[0].email,
                onboard_cohort: this.cohort_id,
                is_selfpaced: this.is_selfpaced,
              };
              await this.LoginflowService.updateUserByEmail(
                updateOnboardingUserdata,
              ).then((userRes) => {});
            } else {
              this.IsLinkExpored = 'true';
            }
          }
        } else {
          this.router.navigate(['/login']);
        }
      },
    );
  }

  toggleSettings() {
    if (
      !this.showSettingsPopup &&
      (this.showPlaybackPopup || this.showVideoQuality)
    ) {
      this.showSettingsPopup = false;
      this.showPlaybackPopup = false;
      this.showVideoQuality = false;
    } else {
      this.showSettingsPopup = !this.showSettingsPopup;
    }
    this.activeSettings = !this.activeSettings;
  }
  showoption(selectedOption: any) {
    if (selectedOption == 'PlaybackOptions') {
      setTimeout(() => {
        this.showPlaybackPopup = true;
      }, 10);
      this.showSettingsPopup = false;
    } else if (selectedOption == 'QualityOptions') {
      setTimeout(() => {
        this.showVideoQuality = true;
      }, 10);
      this.showSettingsPopup = false;
    }
  }
  backShowoption(selectedOption: any) {
    if (selectedOption == 'PlaybackOptions') {
      this.showPlaybackPopup = false;
      setTimeout(() => {
        this.showSettingsPopup = true;
      }, 10);
    } else if (selectedOption == 'QualityOptions') {
      this.showVideoQuality = false;
      setTimeout(() => {
        this.showSettingsPopup = true;
      }, 10);
    }
  }
  setPlaybackSpeed(speed: string) {
    this.api.playbackRate = speed == 'Normal' ? '1.0' : speed;
    this.selectedPlaybackSpeed = speed;
  }
  async setVideoQuality(quality: string) {
    this.selectedVideoQuality = quality;
    var audi = document.getElementsByTagName('video');
    var currentTime = audi[0].currentTime;
    await this.changetranscode(this.video_trans_list);
    var videoposter = document.getElementById(
      'singleVideo',
    ) as HTMLVideoElement;
    videoposter.load();
    setTimeout(() => {
      var vid = document.getElementsByTagName('video');
      vid[0].currentTime = currentTime;
      this.changePlay();
      this.api.playbackRate =
        this.selectedPlaybackSpeed == 'Normal'
          ? '1.0'
          : this.selectedPlaybackSpeed;
    }, 1000);
  }
  async changetranscode(item: any) {
    if (this.selectedVideoQuality == '720p' && item.video_url_720p) {
      this.VideoLink = item.video_url_720p;
    } else if (this.selectedVideoQuality == '480p' && item.video_url_480p) {
      this.VideoLink = item.video_url_480p;
    } else if (this.selectedVideoQuality == '360p' && item.video_url_360p) {
      this.VideoLink = item.video_url_360p;
    } else if (this.selectedVideoQuality == '240p' && item.video_url_240p) {
      this.VideoLink = item.video_url_240p;
    } else if (this.selectedVideoQuality == '144p' && item.video_url_144p) {
      this.VideoLink = item.video_url_144p;
    } else {
      this.VideoLink = item.video_url;
    }
  }

  async next() {
    await this.LoginflowService.UpdatedefaultCohortEmail(
      this.Users[0].email,
      this.cohort_id,
    ).then(async (data) => {});

    var userdata = {
      email: this.Users[0].email,
      password: localStorage.getItem('domaindata')
        ? JSON.parse(atob(localStorage.getItem('domaindata')))
        : this.Users[0].password,
      cohort_id: this.cohort_id,
    };
    var email = btoa(JSON.stringify(userdata));
    email = encodeURIComponent(email);
    let wel_flow =
      this.Users[0].welcome_flow != undefined ||
      this.Users[0].welcome_flow != null
        ? parseInt(this.Users[0].welcome_flow)
        : 0;
    if (wel_flow > 2) {
      if (wel_flow == 2) {
        this.router.navigate([`/alias/${email}`]);
        return;
      } else if (wel_flow == 3) {
        this.router.navigate([`/timezone/${email}`]);
        return;
      } else if (wel_flow == 4) {
        this.router.navigate([`/protectpass/${email}`]);
        return;
      } else if (wel_flow == 5) {
        this.router.navigate([`/onetimepsas/${email}`]);
        return;
      } else if (wel_flow == 6) {
        this.router.navigate([`/userimage/${email}`]);
        return;
      } else if (wel_flow == 7) {
        this.router.navigate([`/selfpacedvideo/${email}`]);
        return;
      } else if (wel_flow == 8) {
        this.router.navigate([`/customschedule/${email}`]);
        return;
      } else if (wel_flow == 9) {
        this.router.navigate([`/competition/${email}`]);
        return;
      } else if (wel_flow == 10) {
        this.router.navigate([`/learning/${email}`]);
        return;
      } else if (wel_flow == 11) {
        this.router.navigate([`/thankyou/${email}`]);
        return;
      }
    }
    await this.LoginflowService.updateUserWelcomeFlow(
      userdata.email,
      '2',
      this.cohort_id,
    ).then(async (data) => {
      this.router.navigate([`/alias/${email}`]);
    });
  }

  onPlayerReady(api: VgApiService) {
    this.api = api;
    let me = this;
    document.onkeydown = function (event) {
      const video = document.getElementsByTagName('video');
      switch (event.keyCode) {
        case 37: //ArrowLeft-  Backward 10 sec
          video[0].currentTime = video[0].currentTime - 10;
          break;
        case 38: //ArrowUp- volume increase
          if (video[0].volume != 1) video[0].volume += 0.1;
          break;
        case 39: //ArrowRight- Forward 10 sec
          video[0].currentTime = video[0].currentTime + 10;
          break;
        case 40: //ArrowDown- Volume decrease
          if (video[0].volume > 0.1) video[0].volume -= 0.1;
          break;
        case 32: //SpaceBar- Play Pause
          event.preventDefault();
          video[0].paused ? video[0].play() : video[0].pause();
          break;
        case 70: //F- Fullscreen
          me.api.fsAPI.toggleFullscreen();
          break;
        case 74: //J- Backward 10 sec
          video[0].currentTime = video[0].currentTime - 10;
          break;
        case 75: //K- Play Paused
          video[0].paused ? video[0].play() : video[0].pause();
          break;
        case 76: //L- Forward 10 sec
          video[0].currentTime = video[0].currentTime + 10;
          break;
        case 77: //M- Mute Unmute
          video[0].volume = video[0].volume == 1 ? 0 : 1;
          break;
        case 188: //>- Slod down video playback rate
          if (video[0].playbackRate > 0.25) video[0].playbackRate -= 0.25;
          me.playbackRate(video[0].playbackRate);
          break;
        case 190: //>- Speedup video playback rate
          video[0].playbackRate += 0.25;
          me.playbackRate(video[0].playbackRate);
          break;
      }
    };
    this.api.getDefaultMedia().subscriptions.loadedMetadata.subscribe();
    this.api.getDefaultMedia().subscriptions.play.subscribe((event) => {
      this.HideControls = true;
      this.HideControlsOverlay = true;
      this.HideControlsOverlay1 = true;
    });

    this.api.getDefaultMedia().subscriptions.ended.subscribe((event) => {
      var videoposter = document.getElementById(
        'singleVideo',
      ) as HTMLVideoElement;
      videoposter.load();
      this.HideControlsOverlay1 = false;
      this.HideControls = false;
      this.HideControlsOverlay = true;
    });
  }
  PlayAgain() {
    this.api.playbackRate =
      this.selectedPlaybackSpeed == 'Normal'
        ? '1.0'
        : this.selectedPlaybackSpeed;
  }
  playbackRate(item) {
    this.selectedPlaybackSpeed = item == 1 ? 'Normal' : item;
  }
  changePlay() {
    if (this.api.state == 'paused') this.api.play();
    if (this.api.state == 'playing') this.api.pause();
  }
  async update_onboarding_flow() {
    var onboardigdata = {
      user_id: this.data.user_id,
      cohort_id: this.cohort_id,
      is_expire: '1',
      welcome_flow: '5',
      link_expire_on: new Date(),
    };
    await this.LoginflowService.update_onboarding_flow(onboardigdata).then(
      (onboardingFlowRes) => {
        if (onboardingFlowRes[0].status == 200) {
          var list = JSON.parse(<string>onboardingFlowRes[0].json);
        } else {
          this.router.navigate(['/login']);
        }
      },
    );
  }
}
