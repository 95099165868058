import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { VideoserviceService } from './services/video/videoservice.service';
import { CookieService } from 'ngx-cookie-service';
import { LoginflowService } from './data/loginflow.service';
import { ServicesService } from './services.service';
import { AuthserviceService } from './authservice.service';
import { DomSanitizer } from '@angular/platform-browser';
import { StudyguideService } from './data/studyguide.service';
import { SharedDataService } from './services/SharedData/shared-data.service';
import { KnowledgeserviceService } from './services/knowledge/knowledgeservice.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'studentdashboard';

  constructor(
    public route: Router,
    public VideoserviceService: VideoserviceService,
    private cookieService: CookieService,
    public LoginflowService: LoginflowService,
    public service: ServicesService,
    public auth: AuthserviceService,
    public sanitizer: DomSanitizer,
    public studyGuideService: StudyguideService,
    public sharedDataService: SharedDataService,
    public knowledgeService: KnowledgeserviceService,
    public router: Router,
  ) {
    sessionStorage.setItem('clearInterval', 'true');
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        var userdetails = localStorage.getItem('userdetails');
        if (
          userdetails != undefined &&
          userdetails != null &&
          userdetails != ''
        ) {
          var userdata = JSON.parse(<string>userdetails);
          this.LoginflowService.versions_getUserById(userdata['id']).then(
            (data) => {
              var list = JSON.parse(<string>data[0].json);
              if (list.data) {
                if (list.data.length <= 0) {
                  localStorage.clear();
                  sessionStorage.clear();
                  this.router.navigate(['login']);
                }
              }
            },
          );
        }
      }
    });
    let PassExamurl = this.route.url;
    let url = this.route.url;
    url = url.substring(1, url.length);
    if (url.startsWith('D')) {
      this.VideoserviceService.getShortUrl(url).then(async (data) => {
        if (data[0].status == 200) {
          var list = JSON.parse(<string>data[0].json);
          var v = btoa(JSON.stringify(list.data[0].id));
          this.route.navigate([`/videolesson/${v}`]);
        } else {
          this.route.navigate(['/login']);
        }
      });
    } else if (url.substring(0, 2) == 'CL') {
      url = url.replace('%2F', '/');
      let ur = '/' + url.split('/')[1] + '/' + url.split('/')[2];
      this.route.navigate([ur]);
    }
    window.onbeforeunload = function (e) {
      return 'Please press the Logout button to logout.';
    };
  }
  ngOnInit() {
    let user_details = window.localStorage.getItem('userdetails');
    let isLoginStudent = window.localStorage.getItem('logintrueStudent');
    if (
      user_details &&
      user_details != '' &&
      user_details != null &&
      isLoginStudent
    ) {
      let cohort_id = window.localStorage.getItem('cohort_id');
      if (cohort_id && cohort_id != '' && cohort_id != null) {
        this.getReviewGuide();
        this.getKnowledgeAssessment();
      }
    }
  }
  async getReviewGuide() {
    try {
      let studyGuideList = [];
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          var cid = localStorage.getItem('CisspCourseId');
          var cohort_id = localStorage.getItem('cohort_id');

          let method_id = localStorage.getItem('method');
          let method_type = localStorage.getItem('course_type');
          let user_id = localStorage.getItem('CisspUserId');
          let material_type = 'Video';
          let study_progress = [];
          await this.studyGuideService
            .getReviewGuidePercentage(user_id, cid, cohort_id, token)
            .then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);
                study_progress = list.data;
              }
            });

          await this.studyGuideService
            .userstudyguidelist(
              user_id,
              cid,
              cohort_id,
              method_id,
              method_type,
              material_type,
              token,
            )
            .then(
              async (data) => {
                if (data[0].status == 200) {
                  var list = JSON.parse(<string>data[0].json);

                  if (list.data.length > 0) {
                    let selected_video = null;
                    let selected_sub = null;
                    let selected_mind = null;
                    list.data.forEach(async (d, i) => {
                      let dom_per = 0;
                      let dom_per_inc = 0;
                      let sub_dom_not_count = 0;
                      d.subdomains.forEach((s, sDom_index) => {
                        let sub_dom_per = 0;
                        let sub_dom_inper = 0;
                        let topic_not_count = 0;
                        s.topics.forEach((t, topicindex) => {
                          let count = 0;
                          if (t.Flashcards.length > 0) {
                            count++;
                          }
                          if (t.PracticeQuestions.length > 0) {
                            count++;
                          }
                          var studyProgressItem = study_progress.find(
                            (x) => x.id === t.id,
                          );
                          if (studyProgressItem.question_count > 0) {
                            count++;
                          }
                          if (studyProgressItem.knowledge_count > 0) {
                            count++;
                          }
                          t['flash_cor_per'] = studyProgressItem.flash_cor_per
                            ? studyProgressItem.flash_cor_per
                            : 0;

                          t['percent'] =
                            ((studyProgressItem.flash_cor_per || 0) +
                              (studyProgressItem.kpcorrectper || 0) +
                              (studyProgressItem.pcorrectper || 0) +
                              (studyProgressItem.practice_question_correct ||
                                0)) /
                            count;
                          t['wpercent'] =
                            ((studyProgressItem.pper || 0) +
                              (studyProgressItem.flash_inc_per || 0) +
                              (studyProgressItem.kper || 0) +
                              (studyProgressItem.practice_question_incorrect ||
                                0)) /
                            count;
                          if (
                            t.Flashcards.length == 0 &&
                            t.PracticeQuestions.length == 0 &&
                            studyProgressItem.question_count == 0 &&
                            studyProgressItem.knowledge_count == 0
                          ) {
                            t['percent'] = 100;
                            topic_not_count++;
                          }
                          if (
                            t.Flashcards.length == 0 &&
                            t.PracticeQuestions.length == 0 &&
                            studyProgressItem.question_count == 0 &&
                            studyProgressItem.knowledge_count == 0
                          ) {
                            t['wpercent'] = 0;
                          }
                          if (
                            t.Flashcards.length == 0 &&
                            t.PracticeQuestions.length == 0 &&
                            studyProgressItem.question_count == 0 &&
                            studyProgressItem.knowledge_count == 0 &&
                            s.topics.length == 1
                          ) {
                            sub_dom_not_count++;
                          }
                          sub_dom_per += t['percent'];
                          sub_dom_inper += t['wpercent'];

                          t['showTab'] = false;
                          t.Video.forEach((videolistelement, video_index) => {
                            if (
                              topicindex == 0 &&
                              video_index == 0 &&
                              selected_video == null
                            ) {
                              selected_video = videolistelement;
                              selected_sub = s.id;
                            }
                          });
                          t.MindMaps.forEach((maps, map_index) => {
                            if (
                              topicindex == 0 &&
                              map_index == 0 &&
                              selected_mind == null
                            ) {
                              selected_mind = maps;
                            }
                          });

                          t['DropdownShow'] = false;
                          t['Bookdropdown'] = [];
                          t['Multipleshow'] = [];
                          if (t.ReferencesBook && t.ReferencesBook.length > 0) {
                            var groups = t.ReferencesBook.reduce(function (
                              obj,
                              item,
                            ) {
                              obj[item.book_title] = obj[item.book_title] || [];
                              obj[item.book_title].push(item);
                              return obj;
                            }, {});

                            var myArray = Object.keys(groups).map(
                              function (key) {
                                return { book_title: key, data: groups[key] };
                              },
                            );
                            if (myArray.length > 0) {
                              let newBookDropdwn = [];
                              if (myArray.length > 0) {
                                myArray.forEach((newBook, i) => {
                                  newBookDropdwn.push(newBook);
                                });
                              }
                              t['BookWiseRef'] = myArray;

                              t['Bookdropdown'] = newBookDropdwn;
                              if (t['Bookdropdown'].length > 0) {
                                t['Bookdropdown'].forEach(
                                  (Bookdropdowndata) => {
                                    Bookdropdowndata['checkmark'] = false;
                                  },
                                );
                              }
                            } else {
                              t['Bookdropdown'] = [];
                            }
                          } else {
                            t['Bookdropdown'] = [];
                          }
                          t.Summary.forEach((sum) => {
                            sum.content =
                              this.sanitizer.bypassSecurityTrustHtml(
                                sum.content,
                              );
                          });
                        });
                        if (s.topics.length == 0) {
                          sub_dom_not_count++;
                        }
                        s.percent =
                          s.topics.length <= 0
                            ? 100
                            : isNaN(
                                  (sub_dom_per - topic_not_count * 100) /
                                    (s.topics.length - topic_not_count),
                                )
                              ? 100
                              : (sub_dom_per - topic_not_count * 100) /
                                (s.topics.length - topic_not_count);
                        s.wpercent =
                          s.topics.length <= 0
                            ? 0
                            : isNaN(
                                  sub_dom_inper /
                                    (s.topics.length - topic_not_count),
                                )
                              ? 0
                              : sub_dom_inper /
                                (s.topics.length - topic_not_count);
                        if (!s.percent) {
                          s.percent = 0;
                        }
                        if (!s.wpercent) {
                          s.wpercent = 0;
                        }
                        dom_per +=
                          s.topics.length <= 0
                            ? 100
                            : isNaN(
                                  (sub_dom_per - topic_not_count * 100) /
                                    (s.topics.length - topic_not_count),
                                )
                              ? 100
                              : (sub_dom_per - topic_not_count * 100) /
                                (s.topics.length - topic_not_count);
                        dom_per_inc +=
                          s.topics.length <= 0
                            ? 0
                            : isNaN(
                                  sub_dom_inper /
                                    (s.topics.length - topic_not_count),
                                )
                              ? 0
                              : sub_dom_inper /
                                (s.topics.length - topic_not_count);
                        sub_dom_per = 0;
                        sub_dom_inper = 0;
                        topic_not_count = 0;
                      });
                      d['percent'] =
                        (dom_per - sub_dom_not_count * 100) /
                        (d.subdomains.length - sub_dom_not_count);
                      d['wpercent'] =
                        dom_per_inc / (d.subdomains.length - sub_dom_not_count);
                      d['srno'] = i + 1;
                      dom_per = 0;
                      dom_per_inc = 0;
                      sub_dom_not_count = 0;
                      studyGuideList.push(d);
                    });
                  }
                  this.sharedDataService.setSharedReviewGuideData(
                    studyGuideList,
                  );
                }
              },
              (err) => {},
            );
        }
      });
    } catch (e) {
      this.service.ShowError(e.meassage);
    }
  }
  async getKnowledgeAssessment() {
    try {
      let KnowAssList = [];
      let user_id = localStorage.getItem('CisspUserId');
      this.auth.getAuthenticatedUser().getSession(async (err, session) => {
        if (err) {
          this.router.navigate(['/login']);
        } else {
          var token = session.getIdToken().getJwtToken();
          let cert_id = localStorage.getItem('CisspCourseId');
          var cohort_id = localStorage.getItem('cohort_id');
          await this.knowledgeService
            .ListKnowAssCerfication(user_id, cert_id, cohort_id, token)
            .then(async (data) => {
              if (data[0].status == 200) {
                var list = JSON.parse(<string>data[0].json);

                if (list.data.length > 0) {
                  await list.data.forEach((element) => {
                    if (
                      element.c_date != undefined &&
                      element.c_date != '' &&
                      element.c_date != null
                    ) {
                      var CompletedDate = new Date(element.c_date);
                      element['CompletedDate'] = CompletedDate.toDateString();
                      element['label'] = 'Completed On';
                      element['Slabel'] = 'Completed';
                      element['dateset'] = CompletedDate.toDateString();
                      element['DueClassName'] = '';
                      element['DayGo'] = '';
                    } else if (
                      new Date(element.schedule[0].overdue_date) > new Date()
                    ) {
                      element['CompletedDate'] = '';
                      element['Slabel'] = 'In Progress';
                      element['label'] = 'Duedate';
                      element['DueClassName'] = '';
                      element['DayGo'] =
                        '(Due in ' +
                        Math.ceil(
                          (new Date(
                            element.schedule[0].overdue_date,
                          ).getTime() -
                            new Date().getTime()) /
                            (1000 * 3600 * 24),
                        ) +
                        ' days)';
                    } else {
                      element['CompletedDate'] = '';
                      element['Slabel'] = 'In Progress';
                      element['label'] = 'Overdue Date';
                      element['DueClassName'] = 'overduedate';
                      element['DayGo'] = '';
                    }
                    if (element.schedule.length > 0) {
                      if (element.total != 0) {
                        element['currectper'] = Math.round(
                          ((element.currect + element.wrong) * 100) /
                            element.total,
                        );
                        element['totalcorrectper'] = Math.round(
                          (element.currect * 100) / element.total,
                        );
                      } else {
                        element['currectper'] = 0;
                        element['totalcorrectper'] = 0;
                      }
                      element['resultcount'] =
                        element.totalcorrectper +
                        ' ' +
                        (100 - element.totalcorrectper);
                      if (element.schedule[0] && element.schedule[0].due_date) {
                        var CurrentDate = new Date();

                        var startExamdate = new Date(
                          element.schedule[0].due_date,
                        );
                        var StartExam_Dt =
                          startExamdate.getTime() - CurrentDate.getTime();
                        var StartDateDay =
                          Math.floor(StartExam_Dt / (1000 * 60 * 60 * 24)) + 1;
                        element['Ngclass'] = false;
                        if (!element.c_date) {
                          if (StartDateDay <= 0) {
                            if (
                              element.schedule[0] &&
                              element.schedule[0].overdue_date
                            ) {
                              var Enddate = new Date(
                                element.schedule[0].overdue_date,
                              );
                              let dt =
                                Enddate.getTime() - CurrentDate.getTime();
                              let days =
                                Math.floor(dt / (1000 * 60 * 60 * 24)) + 1;
                              var month = Enddate.toDateString();
                              if (days == 0) {
                                element['statusExam'] = '';
                                element['dateset'] = month;
                              } else if (days >= 0 && element.c_date == '') {
                                var monthnew = startExamdate.toDateString();

                                days = Math.abs(days);
                                element['statusExam'] = 'Due';
                                element['dateset'] = monthnew;
                              } else {
                                element['statusExam'] = 'Overdue';
                                element['dateset'] = month;
                              }
                            } else {
                              var monthnew = startExamdate.toDateString();
                              element['statusExam'] = 'Due';
                              element['dateset'] = monthnew;
                            }
                          } else {
                            element['Ngclass'] = true;
                            var month = startExamdate.toDateString();
                            element['label'] = 'Upcoming';
                            element['dateset'] = month;
                          }
                        }

                        KnowAssList.push(element);
                      }
                    }
                  });
                }
                if (KnowAssList.length > 0) {
                  KnowAssList.sort(
                    (a, b) =>
                      new Date(a.dateset).getTime() -
                      new Date(b.dateset).getTime(),
                  );
                }
                this.sharedDataService.setKnowledgeAssessmentData(KnowAssList);
              } else {
                window.sessionStorage.setItem('KnowAssList', null);
              }
            });
        }
      });
    } catch (e) {}
  }
}
