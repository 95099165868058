<div class="maintenance-modal" *ngIf="isMaintenanceModeOn">
  <img
    class="header"
    src="https://qnw506.files.keap.app/qnw506/7b35c7e0-33d6-40af-ab4f-3c52dd7f2971"
  />
  <div class="content">
    <div class="title">Maintenance</div>
    <div class="description">
      As part of our ongoing efforts to provide you with the best online
      learning experience, we would like to inform you of scheduled downtime for
      vital maintenance.

      <br />
      <p style="">
        <span style="color: rgb(185, 106, 217) !important"
          ><strong>Maintenance Schedule:</strong></span
        >
      </p>

      <!-- Date:
      <strong style="color: rgb(241, 196, 15) !important"
        >January 31, 2024</strong
      > -->
      <br />
      Start Time:
      <strong style="color: rgb(241, 196, 15) !important"
        >January 31, 2024 - 9AM PST</strong
      >
      <br />
      End Time:
      <strong style="color: rgb(241, 196, 15) !important"
        >February 02, 2024 - 7PM PST</strong
      >

      <br />
      <p style="">
        <span style="color: rgb(185, 106, 217) !important"
          ><strong>Impact on Services:</strong></span
        >
      </p>

      During this maintenance window, the online learning system will be
      temporarily unavailable. This downtime is necessary to perform critical
      updates and optimizations that will enhance the overall performance,
      security, and reliability of our platform.
      <br />

      <p style="">
        <span style="color: rgb(185, 106, 217) !important"
          ><strong>What to Expect:</strong></span
        >
      </p>

      <ul>
        <li>
          The platform will be inaccessible during the specified maintenance
          window.
        </li>
        <li>
          Your progress and data will remain secure and will not be affected by
          the downtime.
        </li>
        <li>
          Once the maintenance is complete, you will be able to resume your
          learning without any disruptions.
        </li>
      </ul>

      <p style="">
        <span style="color: rgb(185, 106, 217) !important"
          ><strong>We Appreciate Your Understanding:</strong></span
        >
      </p>

      We understand the importance of uninterrupted access to our learning
      resources, and we apologize for any inconvenience this temporary downtime
      may cause. Our team is dedicated to ensuring that the maintenance is
      completed efficiently to minimize disruption to your learning experience.
      <br />
      <p style="">
        <span style="color: rgb(185, 106, 217) !important"
          ><strong>Stay Connected:</strong></span
        >
      </p>
      <!-- We will be hosting a live zoom meeting during the downtime. This is a
      bonus Q&A call and will allow you to keep studying while the system is
      down. We look forward to seeing you there if you can join!
    </div>
    <br />
    <a
      target="_blank"
      href="https://qnw506.keap-link012.com/v2/click/0f8587c093c37060b712ad6ac19a9487/eJyNkF1LwzAUhv_LuS79MEnXFkTmVmporSAbiDfSLQFjtzSmp5Y5-t_NpuxKwdtznvO8vOcIKHWjkQvI4F2PLIzBAyu3yiipcdFpbLbnLZ3FYerBTum2sN1gIDv-ensBTmNCI-YBHox0yOpxvih5XbxUvC4daRrrIv7lSQiJyEWU3895BdP0p1nuFeYfTt5DhnaQp0ZCuVa4tjvHvyKaPguCoQ_jUW78z67b-0MfvAXJVeziE0ZZemNGcT2jD2K5ruuiTFu64XfE3uaaPS95u6raJz9yYY0xUoufT5Xy8B05fQFWpm3_"
      class="zoom-button"
      >Join our Zoom meeting</a
    >
    <br /> -->
      <div class="footer">
        **Thank you for your understanding as we work to improve your learning
        environment. If you have any urgent concerns or questions, please don't
        hesitate to contact our support team at
        <strong
          ><a
            href="mailto:support@destcert.com"
            style="color: rgb(185, 106, 217)"
            >support&#64;destcert.com</a
          >&nbsp;</strong
        >
      </div>
    </div>
  </div>

  <!--<div *ngIf="service.status == 'ONLINE'">-->
  <!--  <div class="loginmain">-->
  <!--    <div class="login-form">-->
  <!--      <div class="logo">-->
  <!--        <img src="../../assets/cissplogo.png" alt="CISSP" />-->
  <!--      </div>-->
  <!--      <h3><span>Welcome back!</span> Please login to your account.</h3>-->
  <!--      <form class="form" validate [formGroup]="LoginForm" (key.enter)="login()">-->
  <!--        <div class="input-form mb-20">-->
  <!--          <input-->
  <!--            type="email"-->
  <!--            name="email"-->
  <!--            formControlName="email"-->
  <!--            [(ngModel)]="email"-->
  <!--            class="input"-->
  <!--            placeholder="Enter Email Address"-->
  <!--            id="email"-->
  <!--          />-->
  <!--          <div-->
  <!--            *ngIf="-->
  <!--              !LoginForm.get('email').invalid &&-->
  <!--              (LoginForm.get('email').dirty || LoginForm.get('email').touched)-->
  <!--            "-->
  <!--          >-->
  <!--            <svg-->
  <!--              class="checkedbox"-->
  <!--              width="18"-->
  <!--              height="18"-->
  <!--              viewBox="0 0 18 18"-->
  <!--              fill="none"-->
  <!--              xmlns="http://www.w3.org/2000/svg"-->
  <!--            >-->
  <!--              <path-->
  <!--                fill-rule="evenodd"-->
  <!--                clip-rule="evenodd"-->
  <!--                d="M9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0ZM9 1.3C4.74741 1.3 1.3 4.74741 1.3 9C1.3 13.2526 4.74741 16.7 9 16.7C13.2526 16.7 16.7 13.2526 16.7 9C16.7 4.74741 13.2526 1.3 9 1.3ZM4.98231 8.5842C5.2541 8.30833 5.69806 8.30502 5.97393 8.57681L8.02702 10.5689L12.316 6.19627C12.5908 5.9234 13.0348 5.92496 13.3076 6.19976C13.5805 6.47456 13.579 6.91854 13.3042 7.19141L8.52303 12.0527C8.25036 12.3235 7.81057 12.3243 7.53684 12.0546L4.9897 9.57582C4.71383 9.30403 4.71052 8.86007 4.98231 8.5842Z"-->
  <!--                fill="#944BEB"-->
  <!--              />-->
  <!--            </svg>-->
  <!--          </div>-->
  <!--          <svg-->
  <!--            class="maill"-->
  <!--            width="17"-->
  <!--            height="12"-->
  <!--            viewBox="0 0 17 12"-->
  <!--            fill="none"-->
  <!--            xmlns="http://www.w3.org/2000/svg"-->
  <!--          >-->
  <!--            <path-->
  <!--              fill-rule="evenodd"-->
  <!--              clip-rule="evenodd"-->
  <!--              d="M14.789 0H2.21098C0.990338 0 0 1.01062 0 2.25627V9.74373C0 10.9894 0.990338 12 2.21098 12H14.789C16.0097 12 17 10.9894 17 9.74373V2.25627C17 1.01062 16.0097 0 14.789 0ZM2.21098 1.30362H14.789C15.3041 1.30362 15.7225 1.73059 15.7225 2.25627V9.74373C15.7225 10.2694 15.3041 10.6964 14.789 10.6964H2.21098C1.69586 10.6964 1.27746 10.2694 1.27746 9.74373V2.25627C1.27746 1.73059 1.69586 1.30362 2.21098 1.30362ZM14.3683 3.36019C14.1825 3.05416 13.7888 2.95974 13.4889 3.14931L9.9686 5.37403C9.85874 5.40653 9.75708 5.46906 9.67654 5.5586L8.50098 6.3015L7.31492 5.54929C7.23926 5.46843 7.14602 5.41066 7.04536 5.37833L3.53161 3.14988L3.43921 3.1013C3.15534 2.98068 2.81956 3.08413 2.65193 3.35927C2.46567 3.66499 2.55754 4.0669 2.85712 4.25697L5.63005 6.01629L2.85381 7.80182L2.77061 7.8654C2.54026 8.07327 2.48472 8.42669 2.654 8.70078C2.84209 9.00533 3.23649 9.09662 3.53492 8.90468L6.83615 6.78151L8.16275 7.62319L8.25415 7.67125C8.4424 7.75139 8.65944 7.73558 8.83635 7.62375L10.1673 6.78246L13.486 8.90552L13.5783 8.95444C13.8617 9.07614 14.1978 8.97396 14.3665 8.69945C14.5538 8.39444 14.4634 7.99218 14.1645 7.80098L11.377 6.01776L14.1616 4.25754L14.2453 4.19462C14.4772 3.98858 14.5355 3.63562 14.3683 3.36019Z"-->
  <!--              fill="#7066AB"-->
  <!--            />-->
  <!--          </svg>-->
  <!--        </div>-->
  <!--        <div class="input-form">-->
  <!--          <input-->
  <!--            type="password"-->
  <!--            name="password"-->
  <!--            formControlName="password"-->
  <!--            [(ngModel)]="password"-->
  <!--            class="input"-->
  <!--            placeholder="Enter Password"-->
  <!--          />-->
  <!--          <svg-->
  <!--            width="14"-->
  <!--            height="21"-->
  <!--            viewBox="0 0 14 21"-->
  <!--            fill="none"-->
  <!--            xmlns="http://www.w3.org/2000/svg"-->
  <!--          >-->
  <!--            <path-->
  <!--              fill-rule="evenodd"-->
  <!--              clip-rule="evenodd"-->
  <!--              d="M7 20.0311C3.13409 20.0311 0 16.9477 0 13.1452C0 11.6761 0.476 10.321 1.27273 9.2037V5.19335C1.27273 2.32534 3.83664 0 7 0C10.1634 0 12.7273 2.32534 12.7273 5.19335V9.20307C13.524 10.321 14 11.6761 14 13.1452C14 16.9477 10.8659 20.0311 7 20.0311ZM11.4545 5.17144C11.4545 3.00697 9.46018 1.25186 7 1.25186C4.53982 1.25186 2.54545 3.00697 2.54545 5.17144V7.87484C3.759 6.88149 5.29836 6.25994 7 6.25994C8.70227 6.25994 10.241 6.88149 11.4545 7.87422V5.17144ZM7 7.5118C3.83727 7.5118 1.27273 10.0337 1.27273 13.1452C1.27273 16.2567 3.83664 18.7786 7 18.7786C10.1634 18.7786 12.7273 16.2567 12.7273 13.1452C12.7273 10.0337 10.1627 7.5118 7 7.5118ZM7 15.0224C6.64873 15.0224 6.36364 14.7426 6.36364 14.397V11.8939C6.36364 11.5478 6.64809 11.268 7 11.268C7.35191 11.268 7.63636 11.5478 7.63636 11.8939V14.397C7.63636 14.7426 7.35127 15.0224 7 15.0224Z"-->
  <!--              fill="#7066AB"-->
  <!--            />-->
  <!--          </svg>-->
  <!--        </div>-->
  <!--        <a routerLink="/forgotpassword" class="forget">Forgot Password?</a>-->
  <!--        <button type="submit" (click)="login()" class="submit">Login</button>-->
  <!--      </form>-->
  <!--    </div>-->
  <!--  </div>-->
  <!--</div>-->
  <!--<ngx-spinner-->
  <!--  bdColor="#00041aa6"-->
  <!--  size="default"-->
  <!--  color="##d7779b"-->
  <!--  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"-->
  <!--  [fullScreen]="true"-->
  <!--&gt;-->
  <!--</ngx-spinner>-->
  <!--<app-noconnection></app-noconnection>-->
</div>
