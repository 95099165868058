import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../services.service';
import { AuthserviceService } from '../../authservice.service';
import { LoginflowService } from '../../data/loginflow.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
@Component({
  selector: 'app-learningvideo',
  templateUrl: './learningvideo.component.html',
  styleUrls: ['./learningvideo.component.scss'],
})
export class LearningvideoComponent implements OnInit {
  data: any;
  Users = [];
  CohortName: any;
  StartDate: any;
  api: VgApiService;
  HideControls: boolean = false;
  HideControlsOverlay: boolean = false;
  HideControlsOverlay1: boolean = true;
  showControls: boolean = false;
  showSettingsPopup: boolean = false;
  showPlaybackPopup: boolean = false;
  showVideoQuality: boolean = false;
  activeSettings: boolean = false;
  playbackSpeedOptions: string[] = [
    '0.25',
    '0.5',
    '0.75',
    'Normal',
    '1.25',
    '1.5',
    '1.75',
    '2.0',
  ];
  selectedPlaybackSpeed: string = 'Normal';
  videoQualityOptions: string[] = [
    '1080p HD',
    '720p',
    '480p',
    '360p',
    '240p',
    '144p',
  ];
  selectedVideoQuality: string = '1080p HD';
  timer: any;
  VideoLink: any;
  video_trans_list = {
    video_url:
      'https://d3dou688niv5pt.cloudfront.net/3%20Introduction%20to%20the%20learning%20system_1080.mp4',
    video_url_720p:
      'https://d3dou688niv5pt.cloudfront.net/3%20Introduction%20to%20the%20learning%20system_720p.mp4',
    video_url_480p:
      'https://d3dou688niv5pt.cloudfront.net/3%20Introduction%20to%20the%20learning%20system_480p.mp4',
    video_url_360p:
      'https://d3dou688niv5pt.cloudfront.net/3%20Introduction%20to%20the%20learning%20system_360p.mp4',
    video_url_240p:
      'https://d3dou688niv5pt.cloudfront.net/3%20Introduction%20to%20the%20learning%20system_240p.mp4',
    video_url_144p:
      'https://d3dou688niv5pt.cloudfront.net/3%20Introduction%20to%20the%20learning%20system_144p.mp4',
  };
  constructor(
    public LoginflowService: LoginflowService,
    public ActiveRoute: ActivatedRoute,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    public auth: AuthserviceService,
    public EleRef: ElementRef,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(atob(decodeURIComponent(params['data'])));
        this.CohortName = this.data.cohort;
        this.StartDate = this.data.date;
      } catch (error) {
        this.router.navigate(['/login']);
      }
      this.spinner.hide();
    });
  }
  @ViewChild('videoElement') videoElement!: ElementRef;
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    if (!this.videoElement?.nativeElement.contains(event.target)) {
      if (
        (!this.showPlaybackPopup && this.showVideoQuality) ||
        (this.showPlaybackPopup && !this.showVideoQuality)
      ) {
        this.toggleSettings();
      } else if (this.showSettingsPopup) {
        this.toggleSettings();
      }
    }
  }
  ngOnInit() {
    this.VideoLink = this.video_trans_list.video_url;
    var myVideo = document.getElementById('singleVideo') as HTMLVideoElement;
    if (myVideo.addEventListener) {
      myVideo.addEventListener(
        'contextmenu',
        function (e) {
          e.preventDefault();
        },
        false,
      );
    }
  }
  resetTimer() {
    this.showControls = true;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.hideControls();
    }, 3000);
  }

  hideControls() {
    this.showControls = false;
  }

  toggleSettings() {
    if (
      !this.showSettingsPopup &&
      (this.showPlaybackPopup || this.showVideoQuality)
    ) {
      this.showSettingsPopup = false;
      this.showPlaybackPopup = false;
      this.showVideoQuality = false;
    } else {
      this.showSettingsPopup = !this.showSettingsPopup;
    }
    this.activeSettings = !this.activeSettings;
  }
  showoption(selectedOption: any) {
    if (selectedOption == 'PlaybackOptions') {
      setTimeout(() => {
        this.showPlaybackPopup = true;
      }, 10);
      this.showSettingsPopup = false;
    } else if (selectedOption == 'QualityOptions') {
      setTimeout(() => {
        this.showVideoQuality = true;
      }, 10);
      this.showSettingsPopup = false;
    }
  }
  backShowoption(selectedOption: any) {
    if (selectedOption == 'PlaybackOptions') {
      this.showPlaybackPopup = false;
      setTimeout(() => {
        this.showSettingsPopup = true;
      }, 10);
    } else if (selectedOption == 'QualityOptions') {
      this.showVideoQuality = false;
      setTimeout(() => {
        this.showSettingsPopup = true;
      }, 10);
    }
  }
  setPlaybackSpeed(speed: string) {
    this.api.playbackRate = speed == 'Normal' ? '1.0' : speed;
    this.selectedPlaybackSpeed = speed;
  }
  async setVideoQuality(quality: string) {
    this.selectedVideoQuality = quality;
    var audi = document.getElementsByTagName('video');
    var currentTime = audi[0].currentTime;
    await this.changetranscode(this.video_trans_list);
    var videoposter = document.getElementById(
      'singleVideo',
    ) as HTMLVideoElement;
    videoposter.load();
    setTimeout(() => {
      var vid = document.getElementsByTagName('video');
      vid[0].currentTime = currentTime;
      this.changeplay();
      this.api.playbackRate =
        this.selectedPlaybackSpeed == 'Normal'
          ? '1.0'
          : this.selectedPlaybackSpeed;
    }, 1000);
  }
  async changetranscode(item: any) {
    if (this.selectedVideoQuality == '720p' && item.video_url_720p) {
      this.VideoLink = item.video_url_720p;
    } else if (this.selectedVideoQuality == '480p' && item.video_url_480p) {
      this.VideoLink = item.video_url_480p;
    } else if (this.selectedVideoQuality == '360p' && item.video_url_360p) {
      this.VideoLink = item.video_url_360p;
    } else if (this.selectedVideoQuality == '240p' && item.video_url_240p) {
      this.VideoLink = item.video_url_240p;
    } else if (this.selectedVideoQuality == '144p' && item.video_url_144p) {
      this.VideoLink = item.video_url_144p;
    } else {
      this.VideoLink = item.video_url;
    }
  }

  async next() {
    var userdata = {
      email: this.data.email,
      password: this.data.password,
      cohort_id: this.data.cohort_id,
    };
    var email = btoa(JSON.stringify(userdata));
    email = encodeURIComponent(email);
    await this.LoginflowService.updateUserWelcomeFlow(
      this.data.email,
      '11',
      this.data.cohort_id,
    ).then(async (data) => {
      this.router.navigate([`/thankyou/${email}`]);
    });
  }
  onPlayerReady(api: VgApiService) {
    this.api = api;
    let me = this;
    document.onkeydown = function (event) {
      const video = document.getElementsByTagName('video');
      switch (event.keyCode) {
        case 37: //ArrowLeft-  Backward 10 sec
          video[0].currentTime = video[0].currentTime - 10;
          break;
        case 38: //ArrowUp- volume increase
          if (video[0].volume != 1) video[0].volume += 0.1;
          break;
        case 39: //ArrowRight- Forward 10 sec
          video[0].currentTime = video[0].currentTime + 10;
          break;
        case 40: //ArrowDown- Volume decrease
          if (video[0].volume > 0.1) video[0].volume -= 0.1;
          break;
        case 32: //SpaceBar- Play Pause
          event.preventDefault();
          video[0].paused ? video[0].play() : video[0].pause();
          break;
        case 70: //F- Fullscreen
          me.api.fsAPI.toggleFullscreen();
          break;
        case 74: //J- Backward 10 sec
          video[0].currentTime = video[0].currentTime - 10;
          break;
        case 75: //K- Play Paused
          video[0].paused ? video[0].play() : video[0].pause();
          break;
        case 76: //L- Forward 10 sec
          video[0].currentTime = video[0].currentTime + 10;
          break;
        case 77: //M- Mute Unmute
          video[0].volume = video[0].volume == 1 ? 0 : 1;
          break;
        case 188: //>- Slod down video playback rate
          if (video[0].playbackRate > 0.25) video[0].playbackRate -= 0.25;
          me.playbackRate(video[0].playbackRate);
          break;
        case 190: //>- Speedup video playback rate
          video[0].playbackRate += 0.25;
          me.playbackRate(video[0].playbackRate);
          break;
      }
    };
    this.api.getDefaultMedia().subscriptions.play.subscribe((event) => {
      this.HideControls = true;
      this.HideControlsOverlay = true;
      this.HideControlsOverlay1 = true;
    });
    this.api.getDefaultMedia().subscriptions.ended.subscribe((event) => {
      var videoposter = document.getElementById(
        'singleVideo',
      ) as HTMLVideoElement;
      videoposter.load();
      this.HideControlsOverlay1 = false;
      this.HideControls = false;
      this.HideControlsOverlay = true;
    });
  }
  PlayAgain() {
    this.api.playbackRate =
      this.selectedPlaybackSpeed == 'Normal'
        ? '1.0'
        : this.selectedPlaybackSpeed;
  }
  playbackRate(item) {
    this.selectedPlaybackSpeed = item == 1 ? 'Normal' : item;
  }
  changeplay() {
    if (this.api.state == 'paused') this.api.play();
    if (this.api.state == 'playing') this.api.pause();
  }
}
