import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  HostListener,
  ElementRef,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ServicesService } from '../../services.service';
import { AuthserviceService } from '../../authservice.service';
import { LoginflowService } from '../../data/loginflow.service';
import {
  VgApiService,
  VgControlsHiddenService,
} from '@videogular/ngx-videogular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-selfpacedvideo',
  templateUrl: './selfpacedvideo.component.html',
  styleUrls: ['./selfpacedvideo.component.scss'],
})
export class SelfpacedvideoComponent implements OnInit {
  data: any;
  Users = [];
  cohort_id: any;
  CohortName: any;
  thumnail: any;
  welcomevideo: 'https://d3dou688niv5pt.cloudfront.net/2%20Welcome%20to%20Class%20%E2%80%93%20Self-Paced%20MasterClass_1080.mp4';
  liveClass =
    'https://d3dou688niv5pt.cloudfront.net/2%20Welcome%20to%20Class%20%E2%80%93%20Live%20online_1080.mp4';
  HybridClass =
    'https://d3dou688niv5pt.cloudfront.net/2%20Welcome%20to%20Class%20%E2%80%93%20Live%20online_1080.mp4';
  StartDate: any;
  api: VgApiService;
  HideControls: boolean = false;
  HideControlsOverlay: boolean = false;
  HideControlsOverlay1: boolean = true;
  course_type: any;
  isselfpaced: boolean = false;
  hybrid: boolean = false;
  isse: boolean = false;
  showControls: boolean = false;
  showSettingsPopup: boolean = false;
  showPlaybackPopup: boolean = false;
  showVideoQuality: boolean = false;
  activeSettings: boolean = false;
  playbackSpeedOptions: string[] = [
    '0.25',
    '0.5',
    '0.75',
    'Normal',
    '1.25',
    '1.5',
    '1.75',
    '2.0',
  ];
  selectedPlaybackSpeed: string = 'Normal';
  videoQualityOptions: string[] = [
    '1080p HD',
    '720p',
    '480p',
    '360p',
    '240p',
    '144p',
  ];
  selectedVideoQuality: string = '1080p HD';
  timer: any;
  selfpaced_trans_list = {
    selfpaced_video_url:
      'https://d3dou688niv5pt.cloudfront.net/2%20Welcome%20to%20Class%20%E2%80%93%20Self-Paced%20MasterClass_1080.mp4',
    selfpaced_video_url_720p:
      'https://d3dou688niv5pt.cloudfront.net/2%20Welcome%20to%20Class%20%E2%80%93%20Self-Paced%20MasterClass_720p.mp4',
    selfpaced_video_url_480p:
      'https://d3dou688niv5pt.cloudfront.net/2%20Welcome%20to%20Class%20%E2%80%93%20Self-Paced%20MasterClass_480p.mp4',
    selfpaced_video_url_360p:
      'https://d3dou688niv5pt.cloudfront.net/2%20Welcome%20to%20Class%20%E2%80%93%20Self-Paced%20MasterClass_360p.mp4',
    selfpaced_video_url_240p:
      'https://d3dou688niv5pt.cloudfront.net/2%20Welcome%20to%20Class%20%E2%80%93%20Self-Paced%20MasterClass_240p.mp4',
    selfpaced_video_url_144p:
      'https://d3dou688niv5pt.cloudfront.net/2%20Welcome%20to%20Class%20%E2%80%93%20Self-Paced%20MasterClass_144p.mp4',
  };
  liveClass_trans_list = {
    liveClass_video_url:
      'https://d3dou688niv5pt.cloudfront.net/2%20Welcome%20to%20Class%20%E2%80%93%20Live%20online_1080.mp4',
    liveClass_video_url_720p:
      'https://d3dou688niv5pt.cloudfront.net/2%20Welcome%20to%20Class%20%E2%80%93%20Live%20online_720p.mp4',
    liveClass_video_url_480p:
      'https://d3dou688niv5pt.cloudfront.net/2%20Welcome%20to%20Class%20%E2%80%93%20Live%20online_480p.mp4',
    liveClass_video_url_360p:
      'https://d3dou688niv5pt.cloudfront.net/2%20Welcome%20to%20Class%20%E2%80%93%20Live%20online_360p.mp4',
    liveClass_video_url_240p:
      'https://d3dou688niv5pt.cloudfront.net/2%20Welcome%20to%20Class%20%E2%80%93%20Live%20online_240p.mp4',
    liveClass_video_url_144p:
      'https://d3dou688niv5pt.cloudfront.net/2%20Welcome%20to%20Class%20%E2%80%93%20Live%20online_144p.mp4',
  };

  constructor(
    public LoginflowService: LoginflowService,
    public ActiveRoute: ActivatedRoute,
    public router: Router,
    public spinner: NgxSpinnerService,
    public service: ServicesService,
    public auth: AuthserviceService,
  ) {
    this.ActiveRoute.params.subscribe(async (params) => {
      try {
        this.data = JSON.parse(atob(decodeURIComponent(params['data'])));
        if (this.data.cohort_id) {
          this.data['password'] = JSON.parse(
            atob(localStorage.getItem('domaindata')),
          );
          localStorage.setItem('cohort_id', this.data.cohort_id);
        }
        this.cohort_id = localStorage.getItem('cohort_id');
        await this.service
          .get_welcome_links(this.cohort_id, this.data.email)
          .then(async (data) => {
            var list = JSON.parse(<string>data[0].json);
            var cohortlist = list.data[0];
            window.localStorage.setItem(
              'CisspCourseId',
              cohortlist[0].certi_id,
            );
            this.CohortName = cohortlist[0].cohort_name;
            if (
              cohortlist[0].course_type == null ||
              cohortlist[0].course_type == ''
            ) {
              this.course_type = 'Live Class';
              this.thumnail = cohortlist[0].live_welcome_thumbnail;
              this.liveClass = cohortlist[0].live_welcome_video;
            } else if (cohortlist[0].course_type == 'hybrid') {
              this.course_type = 'Hybrid Class';
              this.thumnail = cohortlist[0].live_welcome_thumbnail;
              this.HybridClass = cohortlist[0].live_welcome_video;
              this.videoQualityOptions = ['1080p HD'];
            } else {
              this.course_type = 'Self-Paced';
              this.thumnail = cohortlist[0].self_welcome_thumbnail;
              this.welcomevideo = cohortlist[0].self_welcome_video;
              this.isselfpaced = true;
            }
          });
        this.StartDate = this.data.date;
        await this.versions_getUserById(this.data.user_id);
      } catch (error) {
        this.router.navigate(['/login']);
      }
    });
  }
  @ViewChild('videoElement') videoElement!: ElementRef;
  @HostListener('document:click', ['$event'])
  onDocumentClick(event: Event): void {
    if (!this.videoElement?.nativeElement.contains(event.target)) {
      if (
        (!this.showPlaybackPopup && this.showVideoQuality) ||
        (this.showPlaybackPopup && !this.showVideoQuality)
      ) {
        this.toggleSettings();
      } else if (this.showSettingsPopup) {
        this.toggleSettings();
      }
    }
  }
  ngOnInit() {
    var myVideo = document.getElementById('singleVideo') as HTMLVideoElement;
    if (myVideo.addEventListener) {
      myVideo.addEventListener(
        'contextmenu',
        function (e) {
          e.preventDefault();
        },
        false,
      );
    }
  }
  resetTimer() {
    this.showControls = true;
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.hideControls();
    }, 3000);
  }

  hideControls() {
    this.showControls = false;
  }
  async versions_getUserById(user_id) {
    await this.spinner.show();
    await this.LoginflowService.versions_getUserByEmail(this.data.email).then(
      (data) => {
        var list = JSON.parse(<string>data[0].json);
        this.Users = list.data;
      },
    );
    await this.spinner.hide();
  }
  toggleSettings() {
    if (
      !this.showSettingsPopup &&
      (this.showPlaybackPopup || this.showVideoQuality)
    ) {
      this.showSettingsPopup = false;
      this.showPlaybackPopup = false;
      this.showVideoQuality = false;
    } else {
      this.showSettingsPopup = !this.showSettingsPopup;
    }
    this.activeSettings = !this.activeSettings;
  }
  showoption(selectedOption: any) {
    if (selectedOption == 'PlaybackOptions') {
      setTimeout(() => {
        this.showPlaybackPopup = true;
      }, 10);
      this.showSettingsPopup = false;
    } else if (selectedOption == 'QualityOptions') {
      setTimeout(() => {
        this.showVideoQuality = true;
      }, 10);
      this.showSettingsPopup = false;
    }
  }
  backShowoption(selectedOption: any) {
    if (selectedOption == 'PlaybackOptions') {
      this.showPlaybackPopup = false;
      setTimeout(() => {
        this.showSettingsPopup = true;
      }, 10);
    } else if (selectedOption == 'QualityOptions') {
      this.showVideoQuality = false;
      setTimeout(() => {
        this.showSettingsPopup = true;
      }, 10);
    }
  }
  setPlaybackSpeed(speed: string) {
    this.api.playbackRate = speed == 'Normal' ? '1.0' : speed;
    this.selectedPlaybackSpeed = speed;
  }
  async setVideoQuality(quality: string) {
    this.selectedVideoQuality = quality;
    var audi = document.getElementsByTagName('video');
    var currentTime = audi[0].currentTime;
    if (this.course_type == 'Live Class') {
      await this.changetranscode(this.liveClass_trans_list);
    } else if (this.course_type == 'Self-Paced') {
      await this.changetranscode(this.selfpaced_trans_list);
    } else {
      this.HybridClass =
        'https://d3dou688niv5pt.cloudfront.net/2%20Welcome%20to%20Class%20%E2%80%93%20Live%20online.mp4';
    }
    var videoposter = document.getElementById(
      'singleVideo',
    ) as HTMLVideoElement;
    videoposter.load();
    setTimeout(() => {
      var vid = document.getElementsByTagName('video');
      vid[0].currentTime = currentTime;
      this.changeplay();
      this.api.playbackRate =
        this.selectedPlaybackSpeed == 'Normal'
          ? '1.0'
          : this.selectedPlaybackSpeed;
    }, 1000);
  }
  async changetranscode(item: any) {
    if (this.course_type == 'Self-Paced') {
      if (
        this.selectedVideoQuality == '720p' &&
        item.selfpaced_video_url_720p
      ) {
        this.welcomevideo = item.selfpaced_video_url_720p;
      } else if (
        this.selectedVideoQuality == '480p' &&
        item.selfpaced_video_url_480p
      ) {
        this.welcomevideo = item.selfpaced_video_url_480p;
      } else if (
        this.selectedVideoQuality == '360p' &&
        item.selfpaced_video_url_360p
      ) {
        this.welcomevideo = item.selfpaced_video_url_360p;
      } else if (
        this.selectedVideoQuality == '240p' &&
        item.selfpaced_video_url_240p
      ) {
        this.welcomevideo = item.selfpaced_video_url_240p;
      } else if (
        this.selectedVideoQuality == '144p' &&
        item.selfpaced_video_url_144p
      ) {
        this.welcomevideo = item.selfpaced_video_url_144p;
      } else {
        this.welcomevideo = item.selfpaced_video_url;
      }
    } else if (this.course_type == 'Live Class') {
      if (
        this.selectedVideoQuality == '720p' &&
        item.liveClass_video_url_720p
      ) {
        this.liveClass = item.liveClass_video_url_720p;
      } else if (
        this.selectedVideoQuality == '480p' &&
        item.liveClass_video_url_480p
      ) {
        this.liveClass = item.liveClass_video_url_480p;
      } else if (
        this.selectedVideoQuality == '360p' &&
        item.liveClass_video_url_360p
      ) {
        this.liveClass = item.liveClass_video_url_360p;
      } else if (
        this.selectedVideoQuality == '240p' &&
        item.liveClass_video_url_240p
      ) {
        this.liveClass = item.liveClass_video_url_240p;
      } else if (
        this.selectedVideoQuality == '144p' &&
        item.liveClass_video_url_144p
      ) {
        this.liveClass = item.liveClass_video_url_144p;
      } else {
        this.liveClass = item.liveClass_video_url;
      }
    }
  }
  async next() {
    var userdata = {
      email: this.data.email,
      password: this.data.password,
      cohort_id: this.data.cohort_id,
    };
    var email = btoa(JSON.stringify(userdata));
    email = encodeURIComponent(email);
    if (this.Users[0].is_selfpaced == 1 && this.course_type == 'Self-Paced') {
      await this.LoginflowService.updateUserWelcomeFlow(
        this.data.email,
        '8',
        this.data.cohort_id,
      ).then(async (data) => {
        this.router.navigate([`/customschedule/${email}`]);
      });
    } else {
      await this.LoginflowService.updateUserWelcomeFlow(
        this.data.email,
        '9',
        this.data.cohort_id,
      ).then(async (data) => {
        this.router.navigate([`/competition/${email}`]);
      });
    }
  }
  onPlayerReady(api: VgApiService) {
    this.api = api;
    let me = this;
    document.onkeydown = function (event) {
      const video = document.getElementsByTagName('video');
      switch (event.keyCode) {
        case 37: //ArrowLeft-  Backward 10 sec
          video[0].currentTime = video[0].currentTime - 10;
          break;
        case 38: //ArrowUp- volume increase
          if (video[0].volume != 1) video[0].volume += 0.1;
          break;
        case 39: //ArrowRight- Forward 10 sec
          video[0].currentTime = video[0].currentTime + 10;
          break;
        case 40: //ArrowDown- Volume decrease
          if (video[0].volume > 0.1) video[0].volume -= 0.1;
          break;
        case 32: //SpaceBar- Play Pause
          event.preventDefault();
          video[0].paused ? video[0].play() : video[0].pause();
          break;
        case 70: //F- Fullscreen
          me.api.fsAPI.toggleFullscreen();
          break;
        case 74: //J- Backward 10 sec
          video[0].currentTime = video[0].currentTime - 10;
          break;
        case 75: //K- Play Paused
          video[0].paused ? video[0].play() : video[0].pause();
          break;
        case 76: //L- Forward 10 sec
          video[0].currentTime = video[0].currentTime + 10;
          break;
        case 77: //M- Mute Unmute
          video[0].volume = video[0].volume == 1 ? 0 : 1;
          break;
        case 188: //>- Slod down video playback rate
          if (video[0].playbackRate > 0.25) video[0].playbackRate -= 0.25;
          me.playbackRate(video[0].playbackRate);
          break;
        case 190: //>- Speedup video playback rate
          video[0].playbackRate += 0.25;
          me.playbackRate(video[0].playbackRate);
          break;
      }
    };
    this.api.getDefaultMedia().subscriptions.loadedMetadata.subscribe();
    this.api.getDefaultMedia().subscriptions.play.subscribe((event) => {
      this.HideControls = true;
      this.HideControlsOverlay = true;
      this.HideControlsOverlay1 = true;
    });
    this.api.getDefaultMedia().subscriptions.ended.subscribe((event) => {
      var videoposter = document.getElementById(
        'singleVideo',
      ) as HTMLVideoElement;
      videoposter.load();
      this.HideControlsOverlay1 = false;
      this.HideControls = false;
      this.HideControlsOverlay = true;
    });
  }
  PlayAgain() {
    this.api.playbackRate =
      this.selectedPlaybackSpeed == 'Normal'
        ? '1.0'
        : this.selectedPlaybackSpeed;
  }
  playbackRate(item) {
    this.selectedPlaybackSpeed = item == 1 ? 'Normal' : item;
  }
  changeplay() {
    if (this.api.state == 'paused') this.api.play();
    if (this.api.state == 'playing') this.api.pause();
  }
}
