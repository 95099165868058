// import { enableProdMode, isDevMode } from '@angular/core';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// import { AppModule } from './app/app.module';
// import { environment } from './environments/environment';
// import { inject } from '@vercel/analytics';

// if (environment.production) {
//   enableProdMode();
// }

// const bootstrapApplication = async () => {
//   // TODO: Add code to disable dev mode when published to production
//   inject();
//   // if (isDevMode()) {
//   // await import('@vercel/analytics').then((analyticsModule) => {
//   //   analyticsModule.inject({ mode: 'development' });
//   // });
//   // } else {
//   //   await import('@vercel/analytics').then((analyticsModule) => {
//   //     analyticsModule.inject({ mode: 'production' });
//   //   });
//   // }
//   const platform = platformBrowserDynamic();
//   await platform.bootstrapModule(AppModule);
// };

// bootstrapApplication().catch((err) => console.error(err));

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {
  init,
  instrumentAngularRouting,
  BrowserTracing,
} from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

init({
  dsn: 'https://bbb65048ac9c1e2108b2201ccf11c7eb@o4506227010371584.ingest.sentry.io/4506356247363584',
  // Need to setup CORS in backend https://github.com/getsentry/sentry-javascript/issues/6077#issuecomment-1643572080
  // integrations: [
  //   new BrowserTracing({
  //     tracingOrigins: [
  //       'localhost',
  //       /^https:\/\/hwni3pu1y1\.execute-api\.us-east-2\.amazonaws\.com\/prod\//,
  //     ],
  //     routingInstrumentation: instrumentAngularRouting,
  //   }),
  // ],
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((success) => console.log(`Bootstrap success`))
  .catch((err) => console.error(err));
