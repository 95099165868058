import { Injectable } from '@angular/core';
import { Http, Headers, RequestOptions } from '@angular/http';
import { environment } from 'src/environments/environment';
import { ConnectionService } from 'ngx-connection-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoginflowService } from './data/loginflow.service';
import { CryptoService } from '../app/services/crypto/crypto.service';
import * as AWS from 'aws-sdk';
import { config } from 'aws-sdk';
const SES = new AWS.SES();
@Injectable({
  providedIn: 'root',
})
export class ServicesService {
  public ErrorPopup: boolean = false;
  public ErrorMessage: any;
  public instructionHint: boolean = false;
  public headershow: boolean = false;
  public title = '';
  courseid = '';
  public SearchValue: boolean = false;
  baseurl = environment.baseurl;
  public knnowlength = 0;
  public Studentheader = 'Student Profile';
  public access = {
    Practiceaccess: false,
    Knowaccess: false,
    CATaccess: false,
    livechat_access: false,
  };
  name: '';
  public headerset = {
    dashboard: false,
    studyguuide: false,
    knowledge: false,
    knowques: false,
    knowresult: false,
    practice: false,
    cat: false,
    datestring: false,
    CATresult: false,
    CATQue: false,
    PracticeResult: false,
    Practiceques: false,
    studentprofile: false,
    resource: false,
    liveSupport: false,
  };
  DateSchdule = '';
  isConnected;
  status = 'ONLINE';
  srcData: SafeResourceUrl;
  baseNonet = `data:image/jpeg;base64,/9j/4QmORXhpZgAATU0AKgAAAAgABwESAAMAAAABAAEAAAEaAAUAA
AABAAAAYgEbAAUAAAABAAAAagEoAAMAAAABAAIAAAExAAIAAAAeAAAAcgEyAAIAAAAUAAAAkIdpA
AQAAAABAAAApAAAANAACvyAAAAnEAAK/IAAACcQQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93c
ykAMjAyMTowNjowMiAxNTo1MDo1NwAAA6ABAAMAAAAB//8AAKACAAQAAAABAAAAXqADAAQAAAABA
AAARwAAAAAAAAAGAQMAAwAAAAEABgAAARoABQAAAAEAAAEeARsABQAAAAEAAAEmASgAAwAAAAEAA
gAAAgEABAAAAAEAAAEuAgIABAAAAAEAAAhYAAAAAAAAAEgAAAABAAAASAAAAAH/2P/tAAxBZG9iZ
V9DTQAC/+4ADkFkb2JlAGSAAAAAAf/bAIQADAgICAkIDAkJDBELCgsRFQ8MDA8VGBMTFRMTGBEMD
AwMDAwRDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAENCwsNDg0QDg4QFA4ODhQUDg4ODhQRD
AwMDAwREQwMDAwMDBEMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwM/8AAEQgARwBeAwEiAAIRA
QMRAf/dAAQABv/EAT8AAAEFAQEBAQEBAAAAAAAAAAMAAQIEBQYHCAkKCwEAAQUBAQEBAQEAAAAAA
AAAAQACAwQFBgcICQoLEAABBAEDAgQCBQcGCAUDDDMBAAIRAwQhEjEFQVFhEyJxgTIGFJGhsUIjJ
BVSwWIzNHKC0UMHJZJT8OHxY3M1FqKygyZEk1RkRcKjdDYX0lXiZfKzhMPTdePzRieUpIW0lcTU5
PSltcXV5fVWZnaGlqa2xtbm9jdHV2d3h5ent8fX5/cRAAICAQIEBAMEBQYHBwYFNQEAAhEDITESB
EFRYXEiEwUygZEUobFCI8FS0fAzJGLhcoKSQ1MVY3M08SUGFqKygwcmNcLSRJNUoxdkRVU2dGXi8
rOEw9N14/NGlKSFtJXE1OT0pbXF1eX1VmZ2hpamtsbW5vYnN0dXZ3eHl6e3x//aAAwDAQACEQMRA
D8A8xSSSVhapJJJJSkXFx7crJrxqRNlrg1o8ygroPqk1lL83qbxIwqCWj+U4O/77W5ibklwwMhuN
v73Rfhx+5kjAmgT6v7sfVJb6wN6fhUU9Fw622ZFTg/IyY95eR/NtP8Aa+gsjLw8rCt9HKrNVhaHb
XcweFsfVmtlmXldYzf0jcJpuM/nWO3On+t7X/20LDw8n6x52Tl5d4pqrbvuuIkMGuytrZb+a1RRl
7dgm4wF5JneWSf7rPPH7nDKIqWQ1ixx0EcWPrJxUTHx7sm5lFDDZbYYYwckoZgEgGR2K1crpd3Ts
TC6viZHqMtg+o0Qa7R7tnLv3XNUspAULqUtI3txNeEDKzVxhrOt+FL0J2HRl39L6tQAMmKjY4e+p
4J2w78zc5yGegZDeut6QXQXO9tsaGuDZ6n/AG23/PVv6xen1DpuH1ytobbZ+hyQP32zDv8AoP8A7
HpqxnZ1juldN620/rNbLcV7/wA7c5llTH/2XNdZ/bUAnIHjA1ncJQ6DND5f8ZtHFEg4ydMfDkhkA
1ly+Q+r/F4+J//Q8xSSSVhapJJJFSlvdB93ROsVt+kK2u+QFn/kVgrW+rObXjdR9K7+Zy2ml88S7
6E/9QmZBcT4Uf8AF1ZuVIGaIJoS4oX/ALSPB/3TZ6J+k6B1aln84GtfHfaJcf8AqE/QQcnonU8Gn
+kPAe1vdwH5o/zdv9tAxrbPq91p9V4LqDLLNPpVu+hZ/r/UVXONfT+oud0zImsjdW+s6gO/wZcP3
Uzgsn+sRMH+62BIQjAzGuLj5fNjup1PiqUf8ZsY2Hju+rWZlOrByK7mtY/uBNQ2/wDgjlZtDsX6o
Mpv0flXCylh5DdHbv8ANb/4IoYhJ+qOeTqTe38tCzKXvzsnHoy8gtqbFYsedGMHhKfKN1f6J4vsa
2GYjxirlkj7cOkfXvxOo/8ARfU1gfzdkzWD4Cf/AEm5K72fU2gO/wAJkks+Xqf+QQur5Teo5WP0z
pwnHx4qpA/Odw6z+r/K/trRy2Y9vUMHoDHfo8eqxlj/APhH1P2u/rN+n/bUdbaamRyU3KiTOpDgj
CHKCf6Mskz6pf3Y+t//0fMUle6b0fN6kT9naBW3R9rzDB8//Iq//wA1iOc/GB7jcrFha4SS3f8Am
v8A+bDG/wA5L/mv/wCbDG/zkrCnCS41W7/zX/8ANhjf5yX/ADW/82GN/nJWFNPP6x9vwKacivdlU
GBkTqWR9F38pZq3f+a3/mwxv85L/mt/5sMb/OQFDZdkyTyHimblQF/3e6+H/wCJHO/49v5aFgrr6
OkV19EyOnHNoL7rA8WBw2iDWYP/AG2s3/mt/wCbDG/zkgRqsafSeqM6aL7G0h+S9u2mw8M/e9qpe
vd6/wBo3n1t2/1J13Tu3Str/mpa/SnNx7H9mBxk/duWaek57c5uAaiMl30WkiCI3bg76Oza1IVZK
+WSZhGBPphfCP727//S5Hrlz8LpmB06g7K7KhZdGm4n97+1uXPwVu/WjVvTnD6JxmwfuVHpvVrOn
1ZFbKmWDJbtJd2+l/5L6KsDZa0EiCOU7XbXB2hgzB40V3q3VbOqXsusrbUWMDIb3gz3+KSmjB+5J
X8Lq1mHhZOI2pj25QgvdyJG3+1/JVOm003V2gBxrcHAO1B2ndDklMCCDqmV3qvUn9SyjkvY2s7Q3
a3y8Sp9M6zd01r2101Wiwgk2NJIjwILUkOekt3/AJ3Zf/cXG/zHf+TS/wCd2X/3Fxv8x3/k0teyn
DBLSCDBGoIXSX5uRd0DG6lujMxrHUi784te11btf39rmrn8m92TkWXua1jrHFxawQ0T+6FsnT6ni
dN2Tp5/6wkein//0+Sx78XK6ZRg9aZZjBgnDyy0gFscbiP3f7CH/wA3+ldusUR8G/8ApZc2kp9ei
16T/m/0v/y4o+5v/pZL/m/0v/y4o+5v/pZc2kl6lPSf83+l/wDlxR9zf/SyX/N/pf8A5cUfc3/0s
ubSS9SnpP8Am/0v/wAuKPub/wClk3/N/pf/AJc0fc3/ANLLnEkvUp6P/m/0v/y5o+5v/pZL/m/0v
/y5o+5v/pZc4kl6laPSDoXRayH3dXrfWPpNrA3EeUWWf9QjZOfiWWYlRxrGdDp3sDyx0Pc5j6/UD
v5H0/8ASfTeuVSS16qf/9n/7RGYUGhvdG9zaG9wIDMuMAA4QklNBCUAAAAAABAAAAAAAAAAAAAAA
AAAAAAAOEJJTQQ6AAAAAADlAAAAEAAAAAEAAAAAAAtwcmludE91dHB1dAAAAAUAAAAAUHN0U2Jvb
2wBAAAAAEludGVlbnVtAAAAAEludGUAAAAAQ2xybQAAAA9wcmludFNpeHRlZW5CaXRib29sAAAAA
AtwcmludGVyTmFtZVRFWFQAAAABAAAAAAAPcHJpbnRQcm9vZlNldHVwT2JqYwAAAAwAUAByAG8Ab
wBmACAAUwBlAHQAdQBwAAAAAAAKcHJvb2ZTZXR1cAAAAAEAAAAAQmx0bmVudW0AAAAMYnVpbHRpb
lByb29mAAAACXByb29mQ01ZSwA4QklNBDsAAAAAAi0AAAAQAAAAAQAAAAAAEnByaW50T3V0cHV0T
3B0aW9ucwAAABcAAAAAQ3B0bmJvb2wAAAAAAENsYnJib29sAAAAAABSZ3NNYm9vbAAAAAAAQ3JuQ
2Jvb2wAAAAAAENudENib29sAAAAAABMYmxzYm9vbAAAAAAATmd0dmJvb2wAAAAAAEVtbERib29sA
AAAAABJbnRyYm9vbAAAAAAAQmNrZ09iamMAAAABAAAAAAAAUkdCQwAAAAMAAAAAUmQgIGRvdWJAb
+AAAAAAAAAAAABHcm4gZG91YkBv4AAAAAAAAAAAAEJsICBkb3ViQG/gAAAAAAAAAAAAQnJkVFVud
EYjUmx0AAAAAAAAAAAAAAAAQmxkIFVudEYjUmx0AAAAAAAAAAAAAAAAUnNsdFVudEYjUHhsQFIAA
AAAAAAAAAAKdmVjdG9yRGF0YWJvb2wBAAAAAFBnUHNlbnVtAAAAAFBnUHMAAAAAUGdQQwAAAABMZ
WZ0VW50RiNSbHQAAAAAAAAAAAAAAABUb3AgVW50RiNSbHQAAAAAAAAAAAAAAABTY2wgVW50RiNQc
mNAWQAAAAAAAAAAABBjcm9wV2hlblByaW50aW5nYm9vbAAAAAAOY3JvcFJlY3RCb3R0b21sb25nA
AAAAAAAAAxjcm9wUmVjdExlZnRsb25nAAAAAAAAAA1jcm9wUmVjdFJpZ2h0bG9uZwAAAAAAAAALY
3JvcFJlY3RUb3Bsb25nAAAAAAA4QklNA+0AAAAAABAASAAAAAEAAgBIAAAAAQACOEJJTQQmAAAAA
AAOAAAAAAAAAAAAAD+AAAA4QklNBA0AAAAAAAQAAAAeOEJJTQQZAAAAAAAEAAAAHjhCSU0D8wAAA
AAACQAAAAAAAAAAAQA4QklNJxAAAAAAAAoAAQAAAAAAAAACOEJJTQP1AAAAAABIAC9mZgABAGxmZ
gAGAAAAAAABAC9mZgABAKGZmgAGAAAAAAABADIAAAABAFoAAAAGAAAAAAABADUAAAABAC0AAAAGA
AAAAAABOEJJTQP4AAAAAABwAAD/////////////////////////////A+gAAAAA/////////////
////////////////wPoAAAAAP////////////////////////////8D6AAAAAD//////////////
///////////////A+gAADhCSU0EAAAAAAAAAgAAOEJJTQQCAAAAAAACAAA4QklNBDAAAAAAAAEBA
DhCSU0ELQAAAAAABgABAAAAAzhCSU0ECAAAAAAAEAAAAAEAAAJAAAACQAAAAAA4QklNBB4AAAAAA
AQAAAAAOEJJTQQaAAAAAANNAAAABgAAAAAAAAAAAAAARwAAAF4AAAAMAE4AbwAtAEkAbgB0AGUAc
gBuAGUAdAAxAAAAAQAAAAAAAAAAAAAAAAAAAAAAAAABAAAAAAAAAAAAAABeAAAARwAAAAAAAAAAA
AAAAAAAAAABAAAAAAAAAAAAAAAAAAAAAAAAABAAAAABAAAAAAAAbnVsbAAAAAIAAAAGYm91bmRzT
2JqYwAAAAEAAAAAAABSY3QxAAAABAAAAABUb3AgbG9uZwAAAAAAAAAATGVmdGxvbmcAAAAAAAAAA
EJ0b21sb25nAAAARwAAAABSZ2h0bG9uZwAAAF4AAAAGc2xpY2VzVmxMcwAAAAFPYmpjAAAAAQAAA
AAABXNsaWNlAAAAEgAAAAdzbGljZUlEbG9uZwAAAAAAAAAHZ3JvdXBJRGxvbmcAAAAAAAAABm9ya
WdpbmVudW0AAAAMRVNsaWNlT3JpZ2luAAAADWF1dG9HZW5lcmF0ZWQAAAAAVHlwZWVudW0AAAAKR
VNsaWNlVHlwZQAAAABJbWcgAAAABmJvdW5kc09iamMAAAABAAAAAAAAUmN0MQAAAAQAAAAAVG9wI
GxvbmcAAAAAAAAAAExlZnRsb25nAAAAAAAAAABCdG9tbG9uZwAAAEcAAAAAUmdodGxvbmcAAABeA
AAAA3VybFRFWFQAAAABAAAAAAAAbnVsbFRFWFQAAAABAAAAAAAATXNnZVRFWFQAAAABAAAAAAAGY
Wx0VGFnVEVYVAAAAAEAAAAAAA5jZWxsVGV4dElzSFRNTGJvb2wBAAAACGNlbGxUZXh0VEVYVAAAA
AEAAAAAAAlob3J6QWxpZ25lbnVtAAAAD0VTbGljZUhvcnpBbGlnbgAAAAdkZWZhdWx0AAAACXZlc
nRBbGlnbmVudW0AAAAPRVNsaWNlVmVydEFsaWduAAAAB2RlZmF1bHQAAAALYmdDb2xvclR5cGVlb
nVtAAAAEUVTbGljZUJHQ29sb3JUeXBlAAAAAE5vbmUAAAAJdG9wT3V0c2V0bG9uZwAAAAAAAAAKb
GVmdE91dHNldGxvbmcAAAAAAAAADGJvdHRvbU91dHNldGxvbmcAAAAAAAAAC3JpZ2h0T3V0c2V0b
G9uZwAAAAAAOEJJTQQoAAAAAAAMAAAAAj/wAAAAAAAAOEJJTQQRAAAAAAABAQA4QklNBBQAAAAAA
AQAAAAEOEJJTQQMAAAAAAh0AAAAAQAAAF4AAABHAAABHAAATsQAAAhYABgAAf/Y/+0ADEFkb2JlX
0NNAAL/7gAOQWRvYmUAZIAAAAAB/9sAhAAMCAgICQgMCQkMEQsKCxEVDwwMDxUYExMVExMYEQwMD
AwMDBEMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMAQ0LCw0ODRAODhAUDg4OFBQODg4OFBEMD
AwMDBERDAwMDAwMEQwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCABHAF4DASIAAhEBA
xEB/90ABAAG/8QBPwAAAQUBAQEBAQEAAAAAAAAAAwABAgQFBgcICQoLAQABBQEBAQEBAQAAAAAAA
AABAAIDBAUGBwgJCgsQAAEEAQMCBAIFBwYIBQMMMwEAAhEDBCESMQVBUWETInGBMgYUkaGxQiMkF
VLBYjM0coLRQwclklPw4fFjczUWorKDJkSTVGRFwqN0NhfSVeJl8rOEw9N14/NGJ5SkhbSVxNTk9
KW1xdXl9VZmdoaWprbG1ub2N0dXZ3eHl6e3x9fn9xEAAgIBAgQEAwQFBgcHBgU1AQACEQMhMRIEQ
VFhcSITBTKBkRShsUIjwVLR8DMkYuFygpJDUxVjczTxJQYWorKDByY1wtJEk1SjF2RFVTZ0ZeLys
4TD03Xj80aUpIW0lcTU5PSltcXV5fVWZnaGlqa2xtbm9ic3R1dnd4eXp7fH/9oADAMBAAIRAxEAP
wDzFJJJWFqkkkklKRcXHtysmvGpE2WuDWjzKCug+qTWUvzepvEjCoJaP5Tg7/vtbmJuSXDAyG42/
vdF+HH7mSMCaBPq/ux9UlvrA3p+FRT0XDrbZkVOD8jJj3l5H820/wBr6CyMvDysK30cqs1WFodtd
zB4Wx9Wa2WZeV1jN/SNwmm4z+dY7c6f63tf/bQsPDyfrHnZOXl3imqtu+64iQwa7K2tlv5rVFGXt
2CbjAXkmd5ZJ/us88fucMoipZDWLHHQRxY+snFRMfHuybmUUMNlthhjByShmASAZHYrVyul3dOxM
Lq+Jkeoy2D6jRBrtHu2cu/dc1SykBQupS0je3E14QMrNXGGs634UvQnYdGXf0vq1AAyYqNjh76ng
nbDvzNznIZ6BkN663pBdBc722xoa4Nnqf8Abbf89W/rF6fUOm4fXK2httn6HJA/fbMO/wCg/wDse
mrGdnWO6V03rbT+s1stxXv/ADtzmWVMf/Zc11n9tQCcgeMDWdwlDoM0Pl/xm0cUSDjJ0x8OSGQDW
XL5D6v8Xj4n/9DzFJJJWFqkkkkVKW90H3dE6xW36Qra75AWf+RWCtb6s5teN1H0rv5nLaaXzxLvo
T/1CZkFxPhR/wAXVm5UgZogmhLihf8AtI8H/dNnon6ToHVqWfzga18d9olx/wCoT9BByeidTwaf6
Q8B7W93Afmj/N2/20DGts+r3Wn1XguoMss0+lW76Fn+v9RVc419P6i53TMiayN1b6zqA7/Blw/dT
OCyf6xEwf7rYEhCMDMa4uPl82O6nU+KpR/xmxjYeO76tZmU6sHIrua1j+4E1Db/AOCOVm0Oxfqgy
m/R+VcLKWHkN0du/wA1v/gihiEn6o55OpN7fy0LMpe/OycejLyC2psVix50YweEp8o3V/oni+xrY
ZiPGKuWSPtw6R9e/E6j/wBF9TWB/N2TNYPgJ/8ASbkrvZ9TaA7/AAmSSz5ep/5BC6vlN6jlY/TOn
CcfHiqkD853DrP6v8r+2tHLZj29QwegMd+jx6rGWP8A+EfU/a7+s36f9tR1tpqZHJTcqJM6kOCMI
coJ/oyyTPql/dj63//R8xSV7pvR83qRP2doFbdH2vMMHz/8ir//ADWI5z8YHuNysWFrhJLd/wCa/
wD5sMb/ADkv+a//AJsMb/OSsKcJLjVbv/Nf/wA2GN/nJf8ANb/zYY3+clYU08/rH2/AppyK92VQY
GROpZH0Xfylmrd/5rf+bDG/zkv+a3/mwxv85AUNl2TJPIeKZuVAX/d7r4f/AIkc7/j2/loWCuvo6
RXX0TI6cc2gvusDxYHDaINZg/8Abazf+a3/AJsMb/OSBGqxp9J6ozpovsbSH5L27abDwz972ql69
3r/AGjefW3b/UnXdO7dK2v+alr9Kc3Hsf2YHGT925Zp6Tntzm4BqIyXfRaSIIjduDvo7NrUhVkr5
ZJmEYE+mF8I/vbv/9LkeuXPwumYHTqDsrsqFl0abif3v7W5c/BW79aNW9OcPonGbB+5Uem9Ws6fV
kVsqZYMlu0l3b6X/kvoqwNlrQSII5TtdtcHaGDMHjRXerdVs6pey6yttRYwMhveDPf4pKaMH7klf
wurWYeFk4jamPblCC93Ikbf7X8lU6bTTdXaAHGtwcA7UHad0OSUwIIOqZXeq9Sf1LKOS9jaztDdr
fLxKn0zrN3TWvbXTVaLCCTY0kiPAgtSQ56S3f8Andl/9xcb/Md/5NL/AJ3Zf/cXG/zHf+TS17KcM
EtIIMEaghdJfm5F3QMbqW6MzGsdSLvzi17XVu1/f2uaufyb3ZORZe5rWOscXFrBDRP7oWydPqeJ0
3ZOnn/rCR6Kf//T5LHvxcrplGD1plmMGCcPLLSAWxxuI/d/sIf/ADf6V26xRHwb/wCllzaSn16LX
pP+b/S//Lij7m/+lkv+b/S//Lij7m/+llzaSXqU9J/zf6X/AOXFH3N/9LJf83+l/wDlxR9zf/Sy5
tJL1Kek/wCb/S//AC4o+5v/AKWTf83+l/8AlzR9zf8A0sucSS9Sno/+b/S//Lmj7m/+lkv+b/S//
Lmj7m/+llziSXqVo9IOhdFrIfd1et9Y+k2sDcR5RZZ/1CNk5+JZZiVHGsZ0OnewPLHQ9zmPr9QO/
kfT/wBJ9N65VJLXqp//2ThCSU0EIQAAAAAAVQAAAAEBAAAADwBBAGQAbwBiAGUAIABQAGgAbwB0A
G8AcwBoAG8AcAAAABMAQQBkAG8AYgBlACAAUABoAG8AdABvAHMAaABvAHAAIABDAFMANgAAAAEAO
EJJTQQGAAAAAAAHAAgAAAABAQD/4Q36aHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLwA8P3hwY
WNrZXQgYmVnaW49Iu+7vyIgaWQ9Ilc1TTBNcENlaGlIenJlU3pOVGN6a2M5ZCI/PiA8eDp4bXBtZ
XRhIHhtbG5zOng9ImFkb2JlOm5zOm1ldGEvIiB4OnhtcHRrPSJBZG9iZSBYTVAgQ29yZSA1LjMtY
zAxMSA2Ni4xNDU2NjEsIDIwMTIvMDIvMDYtMTQ6NTY6MjcgICAgICAgICI+IDxyZGY6UkRGIHhtb
G5zOnJkZj0iaHR0cDovL3d3dy53My5vcmcvMTk5OS8wMi8yMi1yZGYtc3ludGF4LW5zIyI+IDxyZ
GY6RGVzY3JpcHRpb24gcmRmOmFib3V0PSIiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvb
S94YXAvMS4wLyIgeG1sbnM6ZGM9Imh0dHA6Ly9wdXJsLm9yZy9kYy9lbGVtZW50cy8xLjEvIiB4b
WxuczpwaG90b3Nob3A9Imh0dHA6Ly9ucy5hZG9iZS5jb20vcGhvdG9zaG9wLzEuMC8iIHhtbG5zO
nhtcE1NPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvbW0vIiB4bWxuczpzdEV2dD0iaHR0c
DovL25zLmFkb2JlLmNvbS94YXAvMS4wL3NUeXBlL1Jlc291cmNlRXZlbnQjIiB4bXA6Q3JlYXRvc
lRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDUzYgKFdpbmRvd3MpIiB4bXA6Q3JlYXRlRGF0ZT0iMjAyM
S0wNi0wMlQxNTozOSswNTozMCIgeG1wOk1vZGlmeURhdGU9IjIwMjEtMDYtMDJUMTU6NTA6NTcrM
DU6MzAiIHhtcDpNZXRhZGF0YURhdGU9IjIwMjEtMDYtMDJUMTU6NTA6NTcrMDU6MzAiIGRjOmZvc
m1hdD0iaW1hZ2UvanBlZyIgcGhvdG9zaG9wOkNvbG9yTW9kZT0iMyIgeG1wTU06SW5zdGFuY2VJR
D0ieG1wLmlpZDo4MzJDOTEzODhDQzNFQjExOUE2MkYwMkE3OEMwRTkyRCIgeG1wTU06RG9jdW1lb
nRJRD0ieG1wLmRpZDo4MjJDOTEzODhDQzNFQjExOUE2MkYwMkE3OEMwRTkyRCIgeG1wTU06T3JpZ
2luYWxEb2N1bWVudElEPSJ4bXAuZGlkOjgyMkM5MTM4OENDM0VCMTE5QTYyRjAyQTc4QzBFOTJEI
j4gPHhtcE1NOkhpc3Rvcnk+IDxyZGY6U2VxPiA8cmRmOmxpIHN0RXZ0OmFjdGlvbj0iY3JlYXRlZ
CIgc3RFdnQ6aW5zdGFuY2VJRD0ieG1wLmlpZDo4MjJDOTEzODhDQzNFQjExOUE2MkYwMkE3OEMwR
TkyRCIgc3RFdnQ6d2hlbj0iMjAyMS0wNi0wMlQxNTozOSswNTozMCIgc3RFdnQ6c29mdHdhcmVBZ
2VudD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiLz4gPHJkZjpsaSBzdEV2dDphY3Rpb
249ImNvbnZlcnRlZCIgc3RFdnQ6cGFyYW1ldGVycz0iZnJvbSBpbWFnZS9wbmcgdG8gaW1hZ2Uva
nBlZyIvPiA8cmRmOmxpIHN0RXZ0OmFjdGlvbj0ic2F2ZWQiIHN0RXZ0Omluc3RhbmNlSUQ9Inhtc
C5paWQ6ODMyQzkxMzg4Q0MzRUIxMTlBNjJGMDJBNzhDMEU5MkQiIHN0RXZ0OndoZW49IjIwMjEtM
DYtMDJUMTU6NTA6NTcrMDU6MzAiIHN0RXZ0OnNvZnR3YXJlQWdlbnQ9IkFkb2JlIFBob3Rvc2hvc
CBDUzYgKFdpbmRvd3MpIiBzdEV2dDpjaGFuZ2VkPSIvIi8+IDwvcmRmOlNlcT4gPC94bXBNTTpIa
XN0b3J5PiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgI
CAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICAgICA8P3hwYWNrZXQgZ
W5kPSJ3Ij8+/+4ADkFkb2JlAGRAAAAAAf/bAIQAAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBA
QEBAQEBAQEBAQEBAQEBAQICAgICAgICAgICAwMDAwMDAwMDAwEBAQEBAQEBAQEBAgIBAgIDAwMDA
wMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMD/8AAEQgARwBeAwERA
AIRAQMRAf/dAAQADP/EAaIAAAAGAgMBAAAAAAAAAAAAAAcIBgUECQMKAgEACwEAAAYDAQEBAAAAA
AAAAAAABgUEAwcCCAEJAAoLEAACAQMEAQMDAgMDAwIGCXUBAgMEEQUSBiEHEyIACDEUQTIjFQlRQ
hZhJDMXUnGBGGKRJUOhsfAmNHIKGcHRNSfhUzaC8ZKiRFRzRUY3R2MoVVZXGrLC0uLyZIN0k4Rlo
7PD0+MpOGbzdSo5OkhJSlhZWmdoaWp2d3h5eoWGh4iJipSVlpeYmZqkpaanqKmqtLW2t7i5usTFx
sfIycrU1dbX2Nna5OXm5+jp6vT19vf4+foRAAIBAwIEBAMFBAQEBgYFbQECAxEEIRIFMQYAIhNBU
QcyYRRxCEKBI5EVUqFiFjMJsSTB0UNy8BfhgjQlklMYY0TxorImNRlUNkVkJwpzg5NGdMLS4vJVZ
XVWN4SFo7PD0+PzKRqUpLTE1OT0laW1xdXl9ShHV2Y4doaWprbG1ub2Z3eHl6e3x9fn90hYaHiIm
Ki4yNjo+DlJWWl5iZmpucnZ6fkqOkpaanqKmqq6ytrq+v/aAAwDAQACEQMRAD8A0WTID9VB/wB9/
re5p6D1Pn166H+yf9gQPfs9boeuvT/tQ/23uwB63nrogf1P+297z6daPXGy/wCqP+292z6dNHpf9
Ydd7n7e7D2Z1jsqj/iG6d87hxu3MLTnWsK1WRnSE1dbKkbmnxuPhL1FTMQVhp4nkbhT7KN83ey5e
2fct73KTRY2sLSOfOiitAPNmNFUebEAcej3lnl/c+a+YNn5a2aDxN0vrhIYx5anNNTEVoiCrO3BU
BY4HVmHz9ofj70Nsrrv4MdKbPwW7+zNh5zG7p7m7rODpH3nm995LEVFMmy8XkaeOfImiqFzKTT4+
OWSnoxDR0w8lVHUuIO9pG5x5q3befdXmjc5rbZLuJorOx8Q+BHArg+MykhdQ0ELIQGfVK5pGyDrI
r30tuReRtp2L2W5Q2qG75jsZkmv9w8MePJcvGR9OjAFtJ8QM0QYrHphjGqVZCK4e1+nezujd0RbL
7a2bl9ibpnw2M3DFhM2sCVjYfMJI+PrbU89RGqymGRHQsJIZo3jkVJEZRNHLvMuw812Dbpy7ucd3
t4laMuldOtKalyAcVBBpQghlJBBOPnM/KnMfJu4rtHM+0y2W5NCkojkADeHJXS2CRmhBFaqwKsAw
IAZ/wBPp/sD9fZ7ToOHpZ9fdf7y7T3nt/r3r7AVm6N57qrhjcBgaBqdKrI1hhlqHjSWqnp6WGOKn
geSSSWRI440ZnYKCfZbvG87Xy/tl7vO83i2+2W6apJGrRRUDgASakgAAEkkAAk9Gmx7Fu3M272Gw
7FYvc7tcvoijWlWahPFiFAABJJIAAJJAB6sJ+DFf05sPtbtH4ofMTqfG0tD3LJj+tKjde48PTU+9
+nOwMVWZKjxLY7K1lNPVbapcnlMoqz1cBCR1UFHNNrpFlIh73Vg5k3nYeX/AHB9teYnMu2hrkRRu
TBeW7hS+pAQJSqoSqNkq0irSQr1PHspLyjs/NPMftf7s8sxpDu5WzM8sYFxYXSM6x6XYFold3AZ1
wHWJnrEG6QtX8Bt+0HzlxnwurczBS1ua3FI2I3vPSGOjyXXUWEyO8Jd4UdE0wjnrI9p4mqJo1mKj
JU70vlupf2cx+7G1y+1s/uVHalkih74AcrcF1hEJamF8V179NfCYSac06KZfYnf4Peu29nJrpVnm
uD4dwVor2gjec3CrXLCCN/0w1PGVotWCev/0NFT3NPQf65e9gefV/kOu/dutE064+99U64n6+99U
PHq3n+Uvj8RsjM/Jv5VZykp6+D429H5jJ4elnj1P/ePdFDnaummo2A1rUz4XZ1fjyVZfRkSCQDf3
BPvn9Ru9pyjyTbuVO7bkiuR/vuMoCD8g8qPnzj+XWXn3S7K02i79z/dW/hWSHlnY5JEUj/RpllZS
voTHbyxcRiX59e/ln4DDbl7X78+bPeLT7rxnxy2vnO3clLkVgmn3D2huJc/mqLIk1SmnqstTpich
PTKE1R5SaklUqyICx7zyXUPL3LPttyyBBJu8yWo01Ajto9CFcZCHUitnMQkU1BPTf3btqtN45l5/
wDejnbVdWfLtrJfyF6Ey3k3iyK2cM48OVkFMTNEwoQKh70/1D2P/M47w7t7n7h7PpOuNm7RwlVvT
snsjJY05jD7Kw601edq7RwWHkyeHhXHY7F4icjXUxLDRUM00jSTuBMt5i5g2j2P5V5Z5b5Y5fa+3
KeUQ21sraGmeq+LNI4RzqZnXgpLO6qAEB0hflPlLmP7x/OPOfOHNHMC2Gz2kLXF3dumuO2jo/gwR
oXQBESNuLgLHG7sWcjVWdUxwxVFRFTziqgjnljgqhG8IqYUkZY5xDKBJEJkAbS3qW9jz7m5CzIjO
mlyBUVrQ+YqMGnCvWOMiosjrG+pASAaEVFcGhyK8aHI6P8AdofGHdnxj6n+MfzL6a7cXeOA3zHgM
sm6tvYt8Hkuru2KWmkysu1p1bIZRMjBQVuMraPzyLD5KjH1EU9OivGskSbFzvZc879zz7ccz8tG2
uLUyJ4Uj+Il1aE6BKO1NJZWR9IrRZEZHJBInXmT203f245V9uPd/lLmf6vbr3wnE8SeG1neqC5hP
c4YKySR6jpq8UiPGAVDGS/mGx4L5JfHL42/P7AYqkw+8N4xDqXu2kxcMcUM289vw5aHHZiWOE+WH
RVbayEMMsxklbHTY6IuBEgYJ+0MF3yRzVzh7V3M7SbXbt9VZFiSRFIVLICcGokQkLQCQTMB3HqS/
f61sfcH229uPfzbrRYdzvP8Q3FUFB9TEJAkhAyO6GVVZqkxNbqT2iou95d2ZzKfFn4TfPXH1tR/p
Y2ntLuL437j3JHb+Pz7o3P1d2TsPbu6amqK+qoxGbxFZmI2B0LNkydN20+ynlvlW1t+cfcX20liX
9wXFxa38cf+hiOO5t55IgPR0ZIj50jHpXoa88833lx7UeyXv5byN/Wm2tdw2eaYf2pnmsry1hnZv
WOWOS4B4BpjjNOv/9HRW/4n3NQFeiHh137t1759de99VJr11791rro+9jqrca9W4/BFmyvwl/mR7
dxwKZaHrramelaEFqipxVFiOwKyqhCC5eGGmxE4cgekT8/Ue4g59gpzz7aXkmYVuZF+QYtEAf2sP
2dZl/d7iO4ew/3odvthS9j2y3mNOJjWO8Zh9gWJ6+mrPXL4Ts+4/gX/ADC9j4Sx3NBt3au7paeE/
wCWVeAxlJl8lWrHEeZ4oaTblWrBAWBmA+rLffO1ro9wPbzdJh/i6ySJXyDMVA+zLr+z5Hpz2Et23
n7vH3ldl28V3aK1t7kgfE0KLLI9B5gLBIDTPdTiR06fBGOq7N+FPzf+PuyJVHam4KLAbvw+Likji
yO5sJRCkM+IoQ/+fE02DkoWBsqSZWIFl8gItzptapz1yTzNdR6rG21oTxCMa0b8tQb/AGny6M/u5
7TNz37Fe/3t3y5RudpIobqKIECSeJdNY0r8VWiMR4ANcICRrB6A/rbqHr3K/wAtv5G9tZbalBUdl
7S7l2jg8DumdJly2HxjZDrGiqcZTnyKsVPURbsrhNGVtIzqWu0UZWTZJXG4W8Sv+mUJp68f8w6wh
MZVJA6kSK1DXiPkehx3ZDWdRfyitubJ7Ab7fcXdnc1JvnrzA1bIchQbYZcZlUykdPw8NFV4zCNVa
+dK5mENYy6RGUW1ruPu22/2iUitbEwyMODN3ClfMgsB/wA2z6dZu79s9xyJ9yvZbHmkeHu3MO+Ld
WMLU8RbYhJBIF4hGji8SuaC6jrQuAGnLM+1v5Oe26PO2SXsT5JVGS2fBOQJWo6FsrT1VTSxj1+ES
7TrgWIt+79fWl9R2oufeWe9gGLfbAkhHqaEA/72v7Pkeiy9t22f7kO0vuIo+6c0s1sDxKJ4isyjj
SttLUnHd8xXrdrNh/5OHV0GRVpG3X8mMlVYBJVIaGnpF7AjqZ6YWBaESYCqVm5UNKR7raQCX3o3S
5jx4W1hX+ZPhUB+fcv7B1XeomsfuQ8pNdKSb3mpzDXyC/WBivqKwyAnhVj1/9LRWHHuauB6IB/Lr
x9360T11791rr3v3Xuve99eOR1Yd/LN7kwHWXyH/ufvZ4/7g967YyHUe4oqqQJjkrc/PTHb1TXAl
V8VRXxtjmdiFiiyLu3CkgEc/bPJumyrcWo/x2zlEyEce2uoD8u75lQOsovui887Xyp7qLsHMbD+r
XMlo+2TBj2B5ivgM/yZwYCSaKs7McA9KHrTcue/ls/M7c+0t90ddl+uK377aG645KJZk3d1VuKda
nb+7aKikjNHX1NEsUM8sSBwXiq6HUrM5G721g525btp4iFvBR1z8Eq4Za8QDkD7Vb06OuT903H7o
/3g922Lmq2kn5Sk12tyClRc7bOdUF0iEaXZKK7KtalZ7aoJagF95y4D44fInN5b4pdzNX7Zq6CPN
bU3TsTcDtVYHF7rp5ZazZVflKGUx1c+HVvEyOWcReLzKtSrqh3tom3Paoo96sv1waMrDDFeDgHhX
j9taYp1HvuY+2e0Hu3uN97G+4ni7O8QmtrmynOqCO5Ul7N5ENGaL4SCSdOjxAsysFNH1LLJP/KU+
V800jzTTd9bRlllldpJJZJM10a8kkkjks7uxJJJJJPtRKANztQBjQf8DdQSzvKk0kjlpGapJNSST
Ukk5JJySeiNbNy2X777G6j2H3L3BV4TZeOfE7Kpd170zTzYjYGy6WxkgoXyMy0GOjWmpxDC0rRw+
TxCZ1iTUtrhE261vbiwsQ1watpUZdz60yc5NM8aZ6krl6+3D3S5u5C5W9wvcCS25dhMVml1eTExW
NovFUMh0RgKoRCxVNWjxGCLVTcfLjtGg+TPaHTvxY+MmNkyHVvViUXW/WtLRanp907jqPtsbld2S
TxRapcNS0dCgFbMGvDFVVzMEqGsHNg20bDabpvu7vS/uCZJSeKqKkL9pJ4Dz0r5dTj77832Xu9zt
yH7K+zNoZuS9lCWFgEqVubhtMctyWAzCqoKTMD2LNckhZTQ5HbGE2Dunv74q/y6cRnIm2r1Z1J2n
tfdufCwrA/Z29ejN2x4fLPSM/hfM4mvWPKLGpsarKtD+tSAENtS8ttr5j51kh/xy5uYXRfPwo50J
Wv8LDs+xAep1512bljePdL2Z+6nYbip2nZ9j3G3up8UO4XWz3PhSFa0MsThbgLX+0uDH8QI6//T0
Vvr7mwjogGeuvfgajqvXve+vde9+691737r3Xau8bpJGzI6Mro6MVdHUhlZWUgqykXBHIPvfEUPD
rVWRldGIYGoI4gjzHR1O9Pl2PkN0T1nsfsbZv8AFO6OtsqaOn7k+/jWrzexxQVMLYjMUf233NZlK
uqNLJPK8rK0tKZxaSomUkG27Eu1bjd3FnNpsJhUxUwHrxHoBmnyNOAHWRfuZ79r7se2PJvLXN3Lv
je42zz6V3bxBqms9DL4UyadTys3hs7FiC0RkFGlkBJR7EHWOPVrnUH/AG6Q+Vf/AInbZ3/u46M9l
kv/ACVLX/SH/n7p5f7F/t/zdVR+zPpno4fxP+UOI+LcPaO58b17S7m7a3FtukwPWe8shVQmg2E8z
Vy5yqmxUtNKa41wlpZPQ6O4pPAWWKaUki3rZ23n6OGS5K2SvqkUcX4Uz5Uz+2vEDrIH2L96tu9lI
+dt5tOUkvefLuzWDbruRhosSdfjMYip166xtggnwvCJCSPUtH9+95f32/0kf3mzP9/P7xf3t/vb9
7N/HP7y/ffxP+Nff6vN999/+7rv+r/Dj2bfTW/030fgr9Lo06adumlKU9KY6hv+s3MP9Y/63fvi4
/rP9X9V9VrPjfUa/E8bXx16+6vr1//U0+Pjf8Pe7PlHWZA9c4ago9s4SYU+e33uqslw+z8RUmJag
0cldFS1tbkMgtO6yPBR09RLDG6PKI0dWMy3F1DbAeIe48AOPRAqs3Do3J/lbyx/t1Xy2+NdPUp6Z
4P70sfFIvDoTJJDISp/qin/AA9pP3jmotZKfZ1fw6/iFeuv+Gu1/wC8vPjV/wChQP8A6o9+/eX/A
C6yfs694Z/iHXv+Gu1/7y8+NX/oUD/6o9+/eX/LrJ+zr3hn+Ide/wCGu1/7y8+NX/oUD/6o9+/eX
/LrJ+zr3hn+Iddf8NdL/wB5efGr/wBCgf8A1R73+8v+XWT9nWjFX8Q66/4a6X/vL341f+hQP/qj3
795H/lEk/Z1Xwf6Y69/w10v/eXvxq/9Cgf/AFR79+8j/wAokn7OveD/AEx0czZHxM2/tf4Udy/Ga
o+S3Q9Xnux+x8Bu+i3VT7sohgMXT46t2BWGgr43rBWGrqI9k1ATQpU+RfwrWSPdM13DcfTPpVSKU
z5/5+nAlI2XUMnomf8Aw10v/eXvxq/9Cgf/AFR7V/vI/wDKJJ+zpvwf6Y65L/Km3ZmNVFsj5K/HT
eG4XR2osBR7qrFqq5o0LmKA0FHl5vIyqbXi03/UVFyPfvNRl7eQL60/4rr3gnyYV6JVV/FLvqg7w
xnx1rdg11H2vmppFw+DqazHQUWVoIaKuyc2cx2dlq1wtbgYsbi6mdqqOdo1WnkQ/uo0YWC5gMJnE
n6Q8/8AY416b0Nq007uv//V11/nHu3M9HfGb4n/ABn69q5tt7S3V1NQdhdivippaOo3nmszT0U1V
T5SaneOSpxdRm6yuqpqeQvHKzU4ItToPcs2aia4urh8sGoPkP8AVTojYkKqj06qBSKWQSGON5BFG
ZZSiMwjiDKhkkKg6EDuBc2FyB+fZp011wPvQxjy6sfXrnJFLCwSWOSJiiSBZEZGKSoskbgMASkkb
BlP0III4976r15YpXSSRI5HjhCtM6ozJEruI0aRgCqBnYKCbXJt7917rH7917rlLFLBI0U0ckUi2
1Ryo0brcBhqRwGFwQf9Y+9jqhGesfvfWuve/de697917rPS1VTQ1NPW0VRPR1lJPFVUlXSzSU9TS
1MEiywVFPPEySwzwyoGR1IZWAIN/euODw691dZvrujfu8vgL0l8pXy8tP3107v7eHT9J2OC53BW7
Z3ns7dGy8pWPX61qv7wHEZSimStLtMmQpWqVIkkb2UJEiX01tT9B1DU8qgg/s4/ljp8sTGr/iGOv
//W1sP5oKtNQ/DrJxKXoK742baWkq1BMFQ0UOOqJFik/SzJDWxMR+BIv9fct7dxux5+IeiGQ00Hy
p0Vb42fLPcPxs233JtvCbI2hu2HuHa0G266q3LBNJNhxTUubo45IlhOjJY2eHPSmeimtHNJHEdSh
WV1NxarcNEzORoNcfl/m49aVqA449Fax1a+NyFDkI4aaokoKymrY6etgWpo53pZknWGrpn9FRTSs
mmRDw6kg/X2oIqCOtA9GZ+WXymz3yw3xt7e+4NnbZ2bUbe2jRbUho9ufcyisipqytyElVWVlYfuJ
F+4r3WCH9FPCAoLMWdk9rbLbIyK5NTXPVnOog06mdK/LLcPSvS/d/TGM2RtDcGN7sxU+Mr89moJ2
yuEWrxFVhJ5YUjPhya0tJVNNRRy6VpK28w16mQ+mtlmmhmLkFDw68rUBFOPRcdm7lqdl7v2rvGjo
sbk6vae5MHuWkx2YpvvMRkKnA5OlykFFlaPUn3WNq5aURzxah5ImZbi/t9lDqyE0qKft6rwIPQ1f
Kf5G5n5S9qz9pZzauB2hVvgMPt5MXgWnnSSnxAqWSsyNfUrHPksjK9WyeVkTRTxxRAWjBLVtALaP
w1YkVrnrznUSadKr4z/ADE3h8YMdujGbZ666l3rFuqtoK6qq+wdsVuXydDJQQS08dPj8hjsziJko
JFlLNDJ5FEnqTSWfVq4tEuSpaRxT0NP8nVUcpWgHRof+Ha+1/8Anwvxp/8AQJ3J/wDZj7TfuuL/A
H/J+0f5ur+M38I69/w7X2v/AM+F+NP/AKBO5P8A7Mffv3XF/v8Ak/aP83XvGb+EdVtdkb6ruzd+b
r3/AJPEbewOQ3bmqvN1eH2pjP4Pt3HT1j63psTjvNUNTUqn/VySSOxLOzMSSYRoI0VASQB58emia
knqyepVqT+UJQGpUwDKfJN2x5kBUVixpWo7QE/5wK2OnBt+Ym/p7LxndTTyj6d/0H8+v//X14uvt
9dY9ufGjrHoD5y7f7B6chwdI9T8bPkTX7QztDhMpt5qGlNHjoMpWYiSjyWLo8ZV0cTtGHx1XjkpW
eannihnklmRJIriSezZXr8a1Fa/6vzrXohBBQLJUeh6Rv8AsgPxbkvJT/zJOj/A/qiFRiNrQzhDy
oljbuJWSQD6ggH/AAH092+uufPb3r+f/QPXgi/78HXf/Df3xh/72SdE/wDns2r/APbj9++uuP8Ao
3v/AD/6B63oX/fg69/w3/8AGH/vZJ0V/wCezav/ANuP3r664/6N7/z/AOget6Vpl+vf8N/fGH/vZ
J0V/wCezav/ANuP37664/6N7/z/AOgetaF/3517/hv74w/97JOiv/PZtX/7cfv311x/0b3/AJ/9A
9e0L/vzrr/hv74w/wDeyTon/wA9m1f/ALcfvf11x/0b3/n/ANA9eKL/AL8HXX/Df3xg/wC9kvRP/
ns2p/8Abk9++uuf+je/8/8AoHqvhp/v0de/4b++MH/eyXon/wA9m1P/ALcnv311z/0b3/n/ANA9e
8NP9+jr3/Df3xg/72S9E/8Ans2p/wDbk9++uuf+je/8/wDoHr3hp/v0dSaT4M/DTbtTDmN7/wAxD
rTN7aopFnymJ2XiMA24q+ljIaanx0tDv7eNTBUSICFZcZWG54jP09++tu2wlgwb51p/gH+Hr3hp5
yinQhdkd9dT7g3F8eNo1PS/Y+3v5cuwYd77Uod0VmxtyRUPYOf3D1/vnaa7xx2SmpokqazbVbV1d
fE8E0mYWVK6tdDUAQRUjhlVbhxKpv2oaVGACDT8+HpwHWywJUaT4Q6//9k=`;
  constructor(
    public http: Http,
    private spinner: NgxSpinnerService,
    private connectionService: ConnectionService,
    private sanitizer: DomSanitizer,
    public router: Router,
    public LoginflowService: LoginflowService,
    private crypto: CryptoService,
  ) {
    config.update({
      secretAccessKey: this.crypto.dec(environment.ACCESS_KEY),
      accessKeyId: this.crypto.dec(environment.KEY_ID),
      region: 'us-east-2',
      correctClockSkew: true,
    });
    this.srcData = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.baseNonet,
    );
    this.connectionService.updateOptions({
      heartbeatUrl: environment.pingurl,
    });
    this.connectionService.monitor().subscribe((isConnected) => {
      this.isConnected = isConnected.hasNetworkConnection
        ? isConnected.hasNetworkConnection
        : false;
      if (this.isConnected) {
      } else {
        this.status = 'OFFLINE';
        this.spinner.hide();
      }
    });
  }
  async ShowSuccess(success) {}
  async ShowError(error) {
    this.ErrorPopup = true;
    this.ErrorMessage = error;
  }
  async testlogs_add(user_id, test_id, test_type, action, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          test_id: test_id,
          test_type: test_type,
          action: action,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.TEST_LOGS_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async saveDiscussion(user_id, test_type, test_id, q_id, message, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          test_type: test_type,
          test_id: test_id,
          q_id: q_id,
          message: message,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.QUEDISCUSION_ADD;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async addsupport(uid, subject, message) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: uid,
          subject: subject,
          message: message,
          app_type: 'Dashboard',
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    var url = this.baseurl + environment.ADDSUPPORT;
    return new Promise((resolve) => {
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ data: res['_body'], StatusCode: 1, status: res.status }]);
        },
        (err) => {
          resolve([{ data: err, StatusCode: 0, status: err.status }]);
        },
      );
    });
  }
  async setOfflineUser(email) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          email: email,
          is_live: '0',
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(this.baseurl + environment.USER_UPDATE_BY_EMAIL, data, options)
        .subscribe(
          (res) => {
            resolve([{ data: res, StatusCode: 1, status: res.status }]);
          },
          (err) => {
            resolve([{ data: err, StatusCode: 0, status: err.status }]);
          },
        );
    });
  }
  async getMemberhipDetail(sub_id) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          sub_id: sub_id,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(this.baseurl + environment.MEMBERSHIP_DETAIL, data, options)
        .subscribe(
          (res) => {
            resolve([{ status: res.status, json: res['_body'] }]);
          },
          (err) => {
            resolve([{ status: err.status, json: err['_body'] }]);
          },
        );
    });
  }
  async addUsertemp(
    name,
    email,
    roll_id,
    password,
    permission: number,
    mobile,
    allow_access,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          name: name,
          email: email,
          roll_id: roll_id,
          password: password,
          permission: permission,
          email_verified_at: false,
          is_temp: 1,
          mobile: mobile,
          allow_access: allow_access.toString(),
          is_selfpaced: 1,
          show_resources: 1,
        }),
      ),
    };
    return new Promise(async (resolve) => {
      await this.verifyDKIM(email).then(async (result) => {
        if (result[0].StatusCode == 1) {
          let headers = new Headers();
          headers.append('Accept', 'application/json');
          let options = new RequestOptions({ headers: headers });
          var url = environment.baseurl + environment.USER_ADD;
          this.http.post(url, data, options).subscribe(
            (res) => {
              resolve([{ status: res.status, json: res['_body'] }]);
            },
            (err) => {
              resolve([{ status: err.status, json: err['_body'] }]);
            },
          );
        } else {
          this.ShowError(result[0].message);
          resolve([{ message: result[0].message, StatusCode: 0, Data: '' }]);
        }
      });
    });
  }
  async getOneTimeMembershipDetail(sub_id) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          sub_id: sub_id,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(
          this.baseurl + environment.ONETIME_MEMBERSHIP_DETAIL,
          data,
          options,
        )
        .subscribe(
          (res) => {
            resolve([{ status: res.status, json: res['_body'] }]);
          },
          (err) => {
            resolve([{ status: err.status, json: err['_body'] }]);
          },
        );
    });
  }
  async savePaymentTransaction(paymentdata) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(paymentdata)),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(this.baseurl + environment.PAYMENT_TRANSACTION, data, options)
        .subscribe(
          (res) => {
            resolve([{ status: res.status, json: res['_body'] }]);
          },
          (err) => {
            resolve([{ status: err.status, json: err['_body'] }]);
          },
        );
    });
  }
  async addUserAccessDuration(addUserAccess) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(addUserAccess)),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(
          this.baseurl + environment.SAVE_USER_ACCESS_DURATION,
          data,
          options,
        )
        .subscribe(
          (res) => {
            resolve([{ status: res.status, json: res['_body'] }]);
          },
          (err) => {
            resolve([{ status: err.status, json: err['_body'] }]);
          },
        );
    });
  }
  async updateUserSelfAndCustomer(updateUserSelf) {
    var data = {
      data: this.crypto.encrypt(JSON.stringify(updateUserSelf)),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(this.baseurl + environment.USER_UPDATE_BY_EMAIL, data, options)
        .subscribe(
          (res) => {
            resolve([{ status: res.status, json: res['_body'] }]);
          },
          (err) => {
            resolve([{ status: err.status, json: err['_body'] }]);
          },
        );
    });
  }
  async addStudentToSelfCohort(cohort_id, user_id) {
    let data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          cohortIds: cohort_id,
          user_id: user_id,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(this.baseurl + environment.ADD_SELFCOHORT_STD, data, options)
        .subscribe(
          (res) => {
            resolve([{ status: res.status, json: res['_body'] }]);
          },
          (err) => {
            resolve([{ status: err.status, json: err['_body'] }]);
          },
        );
    });
  }
  async getMembershipMaterials(method_id, method_type, material_type, token) {
    let data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          method_id: method_id,
          method_type: method_type,
          material_type: material_type,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);

      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.MEMBER_MATERIAL;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getMeeting(AttendeeId, MeetingId) {
    let data = {
      AttendeeId: AttendeeId,
      MeetingId: MeetingId,
    };
    // var url = environment.BASE_URL + environment.GET_MEETING;
    var url = 'https://teach.dstncert.com/chime/get_meeting.php';

    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async addAttendee(meeting_id: string, attendee_id: string, user_id: number) {
    let data = {
      meeting_id: meeting_id,
      attendee_id: attendee_id,
      user_id: user_id,
    };
    var url = 'https://teach.dstncert.com/chime/create_attendee.php';
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async get_welcome_links(cohort_id, email = '') {
    let data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          cohort_id: cohort_id,
          email: email,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(this.baseurl + environment.GET_WELCOME_LINKS, data, options)
        .subscribe(
          (res) => {
            resolve([{ status: res.status, json: res['_body'] }]);
          },
          (err) => {
            resolve([{ status: err.status, json: err['_body'] }]);
          },
        );
    });
  }
  async addFailedEmail(
    email,
    cohort,
    message,
    email_type,
    mail_success,
    token,
  ) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          email: email,
          cohort: cohort,
          message: message,
          email_type: email_type,
          mail_success: mail_success,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = this.baseurl + environment.ADD_FAILED_EMAIL;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async addActivityTracker(user_id, email, cohort_id, type, description) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          email: email,
          cohort_id: cohort_id,
          type: type,
          description: description,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      this.http
        .post(this.baseurl + environment.ADD_ACTIVITY_TRACKER, data, options)
        .subscribe(
          (res) => {
            resolve([{ status: res.status, json: res['_body'] }]);
          },
          (err) => {
            resolve([{ status: err.status, json: err['_body'] }]);
          },
        );
    });
  }
  verifyDKIM(email) {
    let domain = email.split('@')[1];
    let verify = {
      Domain: domain,
    };
    return new Promise((resolve, reject) => {
      SES.verifyDomainDkim(verify, (err, data) => {
        if (err) {
          resolve([{ message: err.message, StatusCode: 0 }]);
        } else {
          resolve([{ message: 'DKIM verification successful', StatusCode: 1 }]);
        }
      });
    });
  }
  async getCohortByIds(cohortIds, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          cohortIds: cohortIds,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.baseurl + environment.COHORT_BY_IDS;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }

  async getStudentReport(user_id, cohort_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.baseurl + environment.STUDENT_REPORT;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getActivityTracker(user_id, email, cohort_id, token) {
    var data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          user_id: user_id,
          email: email,
          cohort_id: cohort_id,
        }),
      ),
    };
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.baseurl + environment.ACTIVITY_TRACKER;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getNextLiveCall(cohort_id, user_id, token) {
    let data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          cohort_id: cohort_id,
          user_id: user_id,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.baseurl + environment.GET_NEXT_LIVE_CALL;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
  async getPaymentTranction(email, user_id, cohort_id, token) {
    let data = {
      data: this.crypto.encrypt(
        JSON.stringify({
          email: email,
          user_id: user_id,
          cohort_id: cohort_id,
        }),
      ),
    };
    let headers = new Headers();
    headers.append('Accept', 'application/json');
    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve) => {
      let headers = new Headers();
      headers.append('Accept', 'application/json');
      headers.append('Authorization', 'Bearer ' + token);
      let options = new RequestOptions({ headers: headers });
      var url = environment.baseurl + environment.GET_PAYMENT_TRANSACTIONS;
      this.http.post(url, data, options).subscribe(
        (res) => {
          resolve([{ status: res.status, json: res['_body'] }]);
        },
        (err) => {
          resolve([{ status: err.status, json: err['_body'] }]);
        },
      );
    });
  }
}
