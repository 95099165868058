<div *ngIf="IsLinkExpored == 'false'">
  <div class="survey-wrapper">
    <div class="video-wrapper">
      <div class="video-top">
        <img src="../../../assets/images/sidebar-logo.png" alt="Logo" />
        <h4>
          Welcome to <span> {{ CohortName }}</span>
        </h4>
      </div>
      <div class="video-div" (mousemove)="resetTimer()">
        <vg-player
          (onPlayerReady)="onPlayerReady($event)"
          [ngClass]="{ vgControls: !showControls }"
        >
          <vg-controls *ngIf="HideControls">
            <vg-play-pause></vg-play-pause>
            <vg-time-display
              vgProperty="current"
              vgFormat="mm:ss"
            ></vg-time-display>
            <vg-scrub-bar readonly="disabled" disabled="disabled">
              <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
              <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
            </vg-scrub-bar>
            <vg-time-display
              vgProperty="left"
              vgFormat="mm:ss"
              style="justify-content: flex-end !important"
            ></vg-time-display>
            <div class="settings" #videoElement>
              <div
                class="settings-button"
                [ngClass]="{ active_settings: activeSettings }"
                (click)="toggleSettings()"
              >
                <span class="material-icons-outlined">settings</span>
              </div>
              <div class="settings-popup" *ngIf="showSettingsPopup">
                <div class="options" (click)="showoption('PlaybackOptions')">
                  <div class="option-name">
                    <img
                      src="../../../assets/playback-speed.png"
                      alt="playback-speed"
                    />
                    <span>Playback speed</span>
                  </div>
                  <div class="arrow">
                    <span>{{ selectedPlaybackSpeed }}</span>
                    <span class="material-icons">keyboard_arrow_right</span>
                  </div>
                </div>
                <div class="options" (click)="showoption('QualityOptions')">
                  <div class="option-name">
                    <span class="material-icons-outlined"> tune </span>
                    <span>Video quality</span>
                  </div>
                  <div class="arrow">
                    <span>{{ selectedVideoQuality }}</span>
                    <span class="material-icons">keyboard_arrow_right</span>
                  </div>
                </div>
              </div>
              <div class="PlaybackSettings" *ngIf="showPlaybackPopup">
                <div class="playback-speed-options">
                  <label
                    class="back-button"
                    (click)="backShowoption('PlaybackOptions')"
                  >
                    <span class="material-icons">keyboard_arrow_left</span>
                    <span>Playback speed</span>
                  </label>
                  <div class="playbackSpeedList">
                    <div
                      *ngFor="let speed of playbackSpeedOptions"
                      [ngClass]="{
                        activeSpeed: speed == selectedPlaybackSpeed
                      }"
                      (click)="setPlaybackSpeed(speed)"
                    >
                      {{ speed }}
                      <span
                        *ngIf="speed == selectedPlaybackSpeed"
                        class="material-icons-outlined"
                        >check</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="PlaybackSettings" *ngIf="showVideoQuality">
                <div class="playback-speed-options">
                  <label
                    class="back-button"
                    (click)="backShowoption('QualityOptions')"
                  >
                    <span class="material-icons">keyboard_arrow_left</span>
                    <span>Video quality</span>
                  </label>
                  <div class="playbackSpeedList">
                    <div
                      *ngFor="let quality of videoQualityOptions"
                      [ngClass]="{
                        activeSpeed: quality == selectedVideoQuality
                      }"
                      (click)="setVideoQuality(quality)"
                    >
                      {{ quality }}
                      <span
                        *ngIf="quality == selectedVideoQuality"
                        class="material-icons-outlined"
                        >check</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <vg-fullscreen></vg-fullscreen>
          </vg-controls>
          <vg-overlay-play
            vgFor="singleVideo"
            *ngIf="!HideControlsOverlay"
          ></vg-overlay-play>
          <vg-overlay-play
            vgFor="singleVideo"
            *ngIf="!HideControlsOverlay1"
            (click)="PlayAgain()"
            class="PlayAgain"
          >
          </vg-overlay-play>
          <video
            poster="https://d3dou688niv5pt.cloudfront.net/6aa6a2fa-763d-4d96-be30-7eb730ff7139.png"
            [vgMedia]="media"
            #media
            id="singleVideo"
            preload="auto"
            (click)="changePlay()"
          >
            <source [src]="VideoLink" type="video/mp4" />
          </video>
        </vg-player>
        <p class="play_again" *ngIf="!HideControlsOverlay1">Play Again</p>
        <button
          type="button"
          class="frm-next-btn"
          (click)="next()"
          *ngIf="!HideControlsOverlay1"
        >
          Click here to Proceed
        </button>
      </div>
    </div>
  </div>
  <div class="form-button-next">
    <button type="button" class="frm-prev-btn" style="opacity: 0">
      <span class="material-icons">arrow_back</span>
    </button>
    <button type="button" class="frm-next-btn-down" (click)="next()">
      <span class="material-icons"> arrow_forward </span>
    </button>
  </div>
</div>
<div *ngIf="IsLinkExpored == 'true'" class="expiredlink">
  <div class="message">
    <h1>Uhh-oh, your account activation link has expired</h1>
    <p>
      If you have already set up your account,<a
        href="https://www.my.destcert.com/"
        >click here to login</a
      >
    </p>
    <p>
      If you need help with your account, please contact us at
      <a href="mailto:support@destcert.com">support&#64;destcert.com</a>
    </p>
  </div>
</div>
<ngx-spinner
  bdColor="#00041aa6"
  size="default"
  color="##d7779b"
  template="<img src='../../../assets/images/loading.gif' class='loading-indicator'/>"
  [fullScreen]="true"
>
</ngx-spinner>
